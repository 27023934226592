import "@momentum-ui/web-components";
import { Router } from "@uuip/unified-ui-platform";
import { BaseView } from "../_base-view/BaseView";
import style from "./QueueStatsView.scss";
import QueueStatsBlock from "./blocks/QueueStatsBlock";
const QueueStatsView = () => {
    return new Router.RouterView.BlocksMap(BaseView(), {
        blocks: {
            queue_stats: QueueStatsBlock()
        },
        styles: style
    });
};
export default QueueStatsView;
