import { TOGGLE_MAXIMIZED_EVENT } from "@/app/components/constants";
import { attachIMIScript, isEmailOrChat, toggleDisplayOnMaximize } from "@/app/utils/Utils";
import { t } from "@/mixins/i18nMixin";
import { STORE } from "@/store";
import { MediaType } from "@/store/store-agent-contact";
import "@agentx/agentx-mfe-wc-based";
import { Router } from "@uuip/unified-ui-platform";
import "@uuip/unified-ui-platform-common-components";
import { css } from "lit-element";
import { html, nothing } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import style from "./PanelOneBlock.scss";
const PanelOneBlock = () => {
    var _a, _b;
    let isMaximizedEventAttached = false;
    const getMediaViewChatWidget = () => {
        var _a;
        return html `
      <agentx-react-chat
        class="agentx-react-chat"
        taskId="${(_a = STORE.agentContact.taskSelected) === null || _a === void 0 ? void 0 : _a.interactionId}"
        .interaction="${STORE.agentContact.contact /** Please fix these types */}"
        .configs="${STORE.app.chatConfigs}"
        .pcisettings="${STORE.app.pciSettings}"
        pciAllowedAttachmentTypes="${STORE.app.pciAllowedAttachments}"
        @ax-predefined-chat-response-loaded="${(e) => STORE.session.chat.setChatMessages(e.detail)}"
        @ax-retry-init-media-view="${() => {
            STORE.session.chat.setRetryMediaViewInit();
        }}"
      ></agentx-react-chat>
    `;
    };
    const getIMIEmailWidget = () => {
        var _a, _b, _c;
        if (STORE.session.chat.imiServicesInit) {
            return html `
        <imi-email-composer
          taskId="${(_a = STORE.agentContact.taskSelected) === null || _a === void 0 ? void 0 : _a.mediaResourceId}"
          orgId="${(_b = STORE.agentContact.taskSelected) === null || _b === void 0 ? void 0 : _b.orgId}"
          agentName="${STORE.agent.agentName}"
          agentId="${STORE.agent.agentId}"
          interactionId="${(_c = STORE.agentContact.taskSelected) === null || _c === void 0 ? void 0 : _c.interactionId}"
        ></imi-email-composer>
      `;
        }
        else {
            return html `
        <div class="widget-loader">
          <div class="widget-loader-wrapper">
            <md-spinner aria-label=${t("app:commonWebComp.loading")} size="56"></md-spinner>
          </div>
        </div>
      `;
        }
    };
    const returnMediaType = (mediaType) => {
        var _a, _b, _c, _d, _e;
        if (!((_a = STORE.agentContact.taskSelected) === null || _a === void 0 ? void 0 : _a.isWrapUp)) {
            switch (mediaType) {
                case MediaType.Email:
                    if ((_b = STORE.agentContact.getMediaMgrFromTask()) === null || _b === void 0 ? void 0 : _b.includes("digitalmm")) {
                        attachIMIScript();
                        return getIMIEmailWidget();
                    }
                    else {
                        return html `
              <agentx-react-email-composer
                taskId="${(_c = STORE.agentContact.taskSelected) === null || _c === void 0 ? void 0 : _c.interactionId}"
                orgId="${(_d = STORE.agentContact.taskSelected) === null || _d === void 0 ? void 0 : _d.orgId}"
                agentName="${STORE.agent.agentName}"
                .emailprops="${{
                            cachedemail: STORE.session.email.emailCacheSelector,
                            template: STORE.session.email.templateSelector,
                            configs: STORE.app.emailConfigs,
                            pcisettings: STORE.app.pciSettings,
                            pciAllowedAttachmentTypes: STORE.app.pciAllowedAttachments
                        }}"
                @ax-email-template-fetched="${(e) => STORE.session.email.setTemplate(e)}"
                @ax-email-draft-content="${(e) => {
                            STORE.session.email.updateDraftEmailContent(e);
                        }}"
              ></agentx-react-email-composer>
            `;
                    }
                case MediaType.Telephony:
                case MediaType.Midcall:
                    return nothing;
                case MediaType.Social:
                case MediaType.Chat:
                    if ((_e = STORE.agentContact.getMediaMgrFromTask()) === null || _e === void 0 ? void 0 : _e.includes("digitalmm")) {
                        attachIMIScript();
                        if (STORE.app.imiScriptLoaded) {
                            return html `
                <imi-chat-wrapper
                  .isDarkMode="${STORE.app.darkMode}"
                  convId="${STORE.agentContact.taskSelected ? STORE.agentContact.taskSelected.mediaResourceId : ""}"
                  .isLoading="${STORE.session.chat.imiInitState}"
                  @update-imi-init-status="${(e) => {
                                STORE.session.chat.setImiWidgetInitState(e.detail);
                            }}"
                ></imi-chat-wrapper>
              `;
                        }
                        else {
                            return nothing;
                        }
                    }
                    else {
                        return getMediaViewChatWidget();
                    }
                default:
                    return getMediaViewChatWidget();
            }
        }
        else if (mediaType !== MediaType.Telephony) {
            return html `
        <uuip-wc-empty-state illustration-name="Rocket_flying">
          <span><strong>${t("app:wrapUp.wrapUpTitle")}</strong></span>
          <span>${t("app:wrapUp.wrapUpDetails")}</span>
        </uuip-wc-empty-state>
      `;
        }
        else {
            // clearing the dtmf value when call wraps up
            STORE.agent.updateDtmfValue("");
            return nothing;
        }
    };
    const isMaximizedFunction = () => {
        if (!isMaximizedEventAttached) {
            window.addEventListener(TOGGLE_MAXIMIZED_EVENT, (e) => {
                const customEvent = e;
                const value = toggleDisplayOnMaximize(customEvent.detail.isMaximized, "panel-one");
                isMaximizedEventAttached = value;
            });
        }
    };
    if (isEmailOrChat((_a = STORE.agentContact) === null || _a === void 0 ? void 0 : _a.taskSelected)) {
        isMaximizedFunction();
    }
    return new Router.RouterView.Block({
        template: html `
      <!-- PANEL ONE -->
      <div
        id="panel-one"
        class="panel-one ${classMap({
            "non-telephony-task-layout": STORE.agentContact.isNotTelephoneOrMidcall,
            "email-task-layout": ((_b = STORE.agentContact.taskSelected) === null || _b === void 0 ? void 0 : _b.mediaType) === MediaType.Email
        })}"
      >
        ${STORE.agentContact.taskSelected
            ? returnMediaType(STORE.agentContact.taskSelected.mediaType)
            : html ` <uuip-wc-content-loading class="panel-one"></uuip-wc-content-loading> `}
      </div>
    `,
        styles: [
            style,
            css `
        .panel-one {
          grid-area: panel-one; /*grid*/
        }
      `
        ]
    });
};
export default PanelOneBlock;
