import { SERVICE } from "@agentx/agentx-services";
import { STORE } from "@/store";
import { logger } from "./Utils";
import { Notifications } from "@uuip/unified-ui-platform-sdk";
import { t } from "@/mixins/i18nMixin";
import webexImg from "@img/teams-icon-color.svg";
import { ToasterNotificationItemContent } from "@uuip/unified-ui-platform-common-components";
import { html } from "lit-html";
const webexNotificationType = "webex_message";
const clickHandler = () => {
    STORE.app.updateWebexShowModal(true);
    STORE.app.updateWebexMinimized(false);
    localStorage.setItem("webex-show-modal", "true");
    localStorage.setItem("webex-minimize-modal", "false");
};
// allow notification's for Webex
const allowNotification = () => {
    return (STORE.app.webexEnabled &&
        !STORE.app.webexDndEnabled &&
        (!STORE.app.webexShowModal || (STORE.app.webexShowModal && STORE.app.webexMinimized)));
};
export const subscribeToWebexEvents = () => {
    // Subscribe to all the webex events here from SERVICE and show notifications
    SERVICE.webex.onNewMessage.listen((message) => {
        if (message.sentBy !== STORE.agent.agentEmailId && allowNotification()) {
            logger.info("event=webexNewMessageReceived | New Message : ", message);
            const notificationData = {
                type: Notifications.ItemMeta.Type.Chat,
                mode: Notifications.ItemMeta.Mode.AutoDismiss,
                title: t("app:notifications.newChatMessageTitle") + message.displayName,
                data: new ToasterNotificationItemContent.DataController({
                    type: webexNotificationType,
                    text: message.hasAttachments
                        ? t("app:notifications.newChatAttachmentData")
                        : message.text
                            ? message.text
                            : "",
                    iconDetail: {
                        iconUrl: webexImg
                    },
                    clickHandler,
                    taskId: message.id
                })
            };
            STORE.generalNotifications.fireNotificationEvent(notificationData);
            setTimeout(() => {
                STORE.generalNotifications.deactivateReadMessagesFromNotifications(message.id);
            }, 9000);
        }
    });
    SERVICE.webex.onWebexChatActionDone.listen((data) => {
        const bgColor = "var(--alert-default-bg-color)";
        if (data.status) {
            const notificationData = {
                type: Notifications.ItemMeta.Type.Chat,
                mode: Notifications.ItemMeta.Mode.AutoDismiss,
                title: t("app:notifications.webexChatTPWSuccessTitle"),
                data: new ToasterNotificationItemContent.DataController({
                    text: "",
                    iconDetail: {
                        iconName: "icon-info_20",
                        iconColor: "#00A0D1",
                        bgColor
                    }
                })
            };
            STORE.generalNotifications.fireNotificationEvent(notificationData);
        }
        else {
            const notificationData = {
                type: Notifications.ItemMeta.Type.Chat,
                mode: Notifications.ItemMeta.Mode.AutoDismiss,
                title: t("app:notifications.webexChatTPWErrorTitle"),
                data: new ToasterNotificationItemContent.DataController({
                    text: t("app:notifications.webexChatTPWErrorSubTitle"),
                    iconDetail: {
                        iconName: "icon-warning_20",
                        iconColor: "#A12512",
                        bgColor
                    }
                })
            };
            STORE.generalNotifications.fireNotificationEvent(notificationData);
        }
    });
    const webexFloatingNotification = (id, notificationTitle, text) => {
        return {
            type: Notifications.ItemMeta.Type.Chat,
            mode: Notifications.ItemMeta.Mode.AutoDismiss,
            title: notificationTitle,
            data: new ToasterNotificationItemContent.DataController({
                type: webexNotificationType,
                text,
                iconDetail: {
                    iconUrl: webexImg
                },
                clickHandler,
                taskId: id
            })
        };
    };
    const getNotificationTitle = (event) => {
        if (event.isScheduledMeeting) {
            return `${event.participantName}`;
        }
        return event.meetingType === SERVICE.constants.WebexConstants.CALL
            ? `${t("app:notifications.newWebexIncomingCall")}  ${event.participantName}`
            : `${t("app:notifications.newWebexIncomingMeeting")}  ${event.participantName}`;
    };
    const getNotificationText = (event) => {
        if (event.isScheduledMeeting) {
            return event.meetingInMinutes && event.meetingInMinutes > 0
                ? `${t("app:common.in")} ${event.meetingInMinutes} ${t("app:common.minutes")}`
                : `${t("app:common.now")}`;
        }
        return html ` <agentx-wc-task-list-timer startTimestamp=${event.timeStamp}></agentx-wc-task-list-timer> `;
    };
    const handleCallAndMeeting = (event) => {
        var _a;
        const notificationTitle = getNotificationTitle(event);
        const notificationText = getNotificationText(event);
        if (!event.isScheduledMeeting) {
            STORE.app.updateWebexMeetingTimeStamp(event.timeStamp);
            localStorage.setItem(SERVICE.constants.WebexConstants.WEBEX_MEETING_TIME_STAMP, (_a = event.timeStamp) === null || _a === void 0 ? void 0 : _a.toString());
        }
        if (allowNotification() && document.hasFocus()) {
            const notificationData = webexFloatingNotification(event.id, notificationTitle, notificationText);
            STORE.generalNotifications.fireNotificationEvent(notificationData);
            setTimeout(() => {
                STORE.generalNotifications.deactivateReadMessagesFromNotifications(event.id);
            }, 9000);
        }
        if (!document.hasFocus() && !STORE.app.webexDndEnabled) {
            STORE.browserNotifications.firebrowserNotification(notificationTitle);
        }
    };
    // Subsribe to Webex Meeting  events
    SERVICE.webex.onNewMeetingEvent.listen((event) => {
        if (STORE.app.webexEnabled) {
            if (event.eventType === SERVICE.constants.WebexConstants.INCOMING ||
                event.eventType === SERVICE.constants.WebexConstants.JOIN) {
                logger.info("event=webexMeetingsUpdate | Webex Meeting Added : ", event);
                handleCallAndMeeting(event);
            }
            else if (event.eventType === SERVICE.constants.WebexConstants.CALL_INACTIVE ||
                event.eventType === SERVICE.constants.WebexConstants.MEETING_INACTIVE_TERMINATING) {
                logger.info("event=webexMeetingsUpdate | Webex Meeting Removed : ", event);
                STORE.app.updateWebexMeetingTimeStamp(0);
                localStorage.removeItem(SERVICE.constants.WebexConstants.WEBEX_MEETING_TIME_STAMP);
            }
        }
    });
    // Subsribe to Webex Presense events
    SERVICE.webex.onNewPresenceEvent.listen((event) => {
        if (STORE.app.webexEnabled) {
            if (event.status === "dnd") {
                STORE.app.updateWebexDnd(true);
            }
            else {
                STORE.app.updateWebexDnd(false);
            }
        }
    });
    SERVICE.webex.onNotificationCountUpdate.listen((count) => {
        if (STORE.app.webexEnabled) {
            logger.info("event=webexNotificationCountUpdate | Webex notification count updated : ", count);
            STORE.generalNotifications.setWebexNotificationCount(count);
        }
    });
};
