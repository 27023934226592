import { t } from "@/mixins/i18nMixin";
import { STORE } from "@/store";
import "@agentx/agentx-mfe-wc-based";
import { Router } from "@uuip/unified-ui-platform";
import { css } from "lit-element";
import { html, nothing } from "lit-html";
import { ifDefined } from "lit-html/directives/if-defined";
import { checkWebCall } from "./PopoverBlock";
import style from "./TasksAreaBlock.scss";
const AGENT = "agent";
const SUPERVISOR = "supervisor";
const AGENT_AND_SUPERVISOR = "agent_supervisor";
const getLoggedInRole = (userRoleString) => {
    switch (userRoleString) {
        case SUPERVISOR:
            return SUPERVISOR;
        case "agent_supervisor":
            return STORE.agent.userSelectedRole === SUPERVISOR ? SUPERVISOR : AGENT_AND_SUPERVISOR;
        default:
            return AGENT;
    }
};
const displayTaskList = () => {
    const role = STORE.agent.role;
    const userRole = STORE.agent.userRole;
    const loggedInRole = userRole ? getLoggedInRole(userRole.join("_").toLocaleLowerCase()) : role;
    return loggedInRole !== SUPERVISOR || STORE.agent.isMonitoringEnabled;
};
export const handleFireNotificationTaskAreaBlock = (e) => {
    if (!STORE.app.taskAreaCollapsed) {
        STORE.browserNotifications.fireNewOfferNotification(e.detail);
    }
    // STORE.browserNotifications.playSound :
    // 1. if the call is a web call and the feature flag CX-13578-webrtc-ringtone is enabled -> plays (/sounds/ringtone.mp3)
    // 2. if the call is not a web call -> plays default chime sound (/sounds/new_contact_sound.mp3)
    // 3. if its any other media type -> plays default chime sound (/sounds/new_contact_sound.mp3)
    STORE.browserNotifications.playSound(checkWebCall(e.detail.type));
};
const TasksAreaBlock = () => {
    return new Router.RouterView.Block({
        template: html `
      <!-- TASKS AREA -->
      <div
        class=${`
          agentx-task-area
          ${STORE.common.isNavBarOpen ? "left-nav-open" : ""}
          ${STORE.common.isInteractionModelOpen ? "interaction-modal-opened" : ""}
          `}
      >
        ${displayTaskList()
            ? html `
              <agentx-wc-collapse-area
                .direction="${"left"}"
                .collapsed="${STORE.app.taskAreaCollapsed}"
                .forceTogglerVisibilityOnMouseOverSelectors="${`agentx-wc-navigation`}"
                @toggle="${() => STORE.app.toggleTaskAreaCollapse()}"
              >
                <div class="agentx-task-area-width-holder">
                  <div class="task-list-wrapper" role="region" aria-label=${t("app:taskBar:taskRegionLabel")}>
                    <div class="${STORE.app.taskAreaCollapsed ? "hide" : "show"}">
                      <agentx-react-interaction-popover
                        class="popover"
                        agentId="${STORE.agent.agentId}"
                        .taskMap="${STORE.agentContact.taskMapForPopover}"
                        .agentconfig="${{
                isendcallenabled: STORE.agent.isEndCallEnabled,
                isAgentHandlingTask: STORE.agentContact.isAgentHandlingMoreThanOneTask(),
                isProgressive11SpecialModeEnabled: STORE.app.featureFlags.isProgressive11SpecialModeEnabled
            }}"
                        .popoverConfig="${{
                isTaskList: !STORE.app.taskAreaCollapsed,
                isDarkMode: STORE.app.darkMode,
                isDesktopConsultToEntryPointDnEnabled: STORE.app.featureFlags.isDesktopConsultToEntryPointDnEnabled,
                isProgressiveCampaignEnabled: STORE.app.featureFlags.isProgressiveCampaignEnabled,
                isProgressiveCampaign1NEnabled: STORE.app.featureFlags.isProgressiveCampaign1NEnabled,
                isSuppressWebexCallingNotificationEnabled: STORE.app.featureFlags.isSuppressWebexCallingNotificationEnabled &&
                    localStorage.getItem("desktop-hide-webex-calling-notifications-setting") === "true",
                deviceType: STORE.agent.deviceType,
                isDesktopCpdViewEnabled: STORE.app.featureFlags.isDesktopCpdViewEnabled,
                isActivePopover: !STORE.app.taskAreaCollapsed
            }}"
                        @ax-fire-notification="${handleFireNotificationTaskAreaBlock}"
                      ></agentx-react-interaction-popover>
                    </div>
                    <agentx-wc-task-list
                      .selectedId="${STORE.routing.currentRouteValues.params.taskId}"
                      .agentId="${STORE.agent.agentId}"
                      .isProgressiveCampaignEnabled="${STORE.app.featureFlags.isProgressiveCampaignEnabled}"
                      .isProgressiveCampaign1NEnabled="${STORE.app.featureFlags.isProgressiveCampaign1NEnabled}"
                      .taskList="${STORE.agentContact.taskArray}"
                      .unreadMessages="${STORE.session.chat.unReadMessagesObject}"
                      .hasOfferContact="${STORE.agentContact.newPopoverCount > 0}"
                      .isInteractionModelOpen="${STORE.common.isInteractionModelOpen}"
                      .clickEvent="${STORE.agentContact.getcheckReplies}"
                      .newUnreadTaskMessage="${STORE.session.chat.getNewUnreadMessageId}"
                      .isDarkMode="${STORE.app.darkMode}"
                      .monitoringOwnerName="${STORE.agentContact.monitoringOwnerName}"
                      @task-selected=${(e) => {
                const { taskId } = e.detail;
                STORE.routing.navigateTask(taskId);
            }}
                      @handle-new-replies=${(e) => {
                STORE.agentContact.updateShowNewReply(e.detail.show);
                STORE.agentContact.updateShowArrowUp(e.detail.isShowUp);
            }}
                    ></agentx-wc-task-list>

                    <div class="ax-activity-list-wrapper">
                      ${STORE.agentContact.showNewReplyButton
                ? html `
                            <md-button
                              @click="${() => STORE.agentContact.checkReplies()}"
                              variant="primary"
                              color="blue"
                            >
                              ${t("app:taskList.newReplies")} ${"  "}
                              ${STORE.agentContact.showArrowUp
                    ? html ` <md-icon class="arrow-icon arrow-up" name="icon-arrow-up_12"></md-icon> `
                    : html ` <md-icon class="arrow-icon arrow-down" name="icon-arrow-down_12"></md-icon> `}
                            </md-button>
                          `
                : nothing}
                    </div>
                  </div>
                  ${STORE.app.isStationLoginSuccess
                ? html `
                        <agentx-react-contact-handled-by-agent
                          allowOutdial="${STORE.agent.isOutboundEnabledForTenant &&
                    STORE.agent.isOutboundEnabledForAgent &&
                    !STORE.agent.getIsMaskSensitiveDataEnabled}"
                          lastcompletedtask="${STORE.agentContact.lastCompletedTask}"
                          voicecount="${STORE.agent.channels.voiceCount}"
                          chatcount="${STORE.agent.channels.chatCount}"
                          emailcount="${STORE.agent.channels.emailCount}"
                          socialcount="${STORE.agent.channels.socialCount}"
                          agentId="${STORE.agent.agentId}"
                          tahoeEnabled="${STORE.app.featureFlags.isTahoeEnabled}"
                          ?isActiveCall="${STORE.agentContact.isActiveCall}"
                          isDarkMode="${STORE.app.darkMode}"
                          @ax-active-call-error="${(e) => STORE.session.errorModal.setErrorDetails(e)}"
                          webRtcStatus="${STORE.agent.webRtcStatus}"
                          isWebRTCEnabled="${STORE.app.featureFlags.isWebRTCEnabled}"
                          isMaskSensitiveDataEnabled="${STORE.agent.getIsMaskSensitiveDataEnabled}"
                          deviceType="${ifDefined(STORE.agent.deviceType === null ? undefined : STORE.agent.deviceType)}"
                          orgId="${STORE.agent.orgId}"
                        ></agentx-react-contact-handled-by-agent>
                      `
                : nothing}
                </div>
              </agentx-wc-collapse-area>
            `
            : nothing}
      </div>
    `,
        styles: [
            style,
            css `
        .agentx-task-area {
          grid-area: tasks; /*grid*/
        }
      `
        ]
    });
};
export default TasksAreaBlock;
