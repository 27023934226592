function equalUrls(url1, url2) {
    try {
        const parsedUrl1 = new URL(url1);
        const parsedUrl2 = new URL(url2);
        // Normalize pathname by removing trailing slashes
        const normalizePathname = (url) => {
            let pathname = url.pathname;
            while (pathname.endsWith("/")) {
                pathname = pathname.slice(0, -1);
            }
            return pathname;
        };
        return (parsedUrl1.hostname === parsedUrl2.hostname && normalizePathname(parsedUrl1) === normalizePathname(parsedUrl2));
    }
    catch (_a) {
        return false;
    }
}
export { equalUrls };
