var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { STORE } from "@/store";
import { Router } from "@uuip/unified-ui-platform";
import { LitElement, customElement } from "lit-element";
import { html } from "lit-html";
import style from "./QueueStatsBlock.scss";
import "../../../../components/DynamicArea";
import config from "../config-queue-stats.json";
const CARD_VIEW_ACTION_EVENT_KEY = "card-view-action-clicked";
let AgentQueueStats = class AgentQueueStats extends LitElement {
    static get styles() {
        return style;
    }
    connectedCallback() {
        super.connectedCallback();
        this.addBindings();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeBindings();
    }
    addBindings() {
        window.addEventListener(CARD_VIEW_ACTION_EVENT_KEY, this.navigateInteractionTab);
    }
    removeBindings() {
        window.removeEventListener(CARD_VIEW_ACTION_EVENT_KEY, this.navigateInteractionTab);
    }
    navigateInteractionTab(e) {
        const customEvent = e;
        console.log("navigateInteractionTab", customEvent.detail.widget_id);
        STORE.routing.navigateInteractionTab();
    }
    render() {
        return html `
      <dynamic-area .area=${config} .isStationLoginSuccess=${STORE.app.isStationLoginSuccess}></dynamic-area>
    `;
    }
};
AgentQueueStats = __decorate([
    customElement("agentx-queues-stats")
], AgentQueueStats);
export { AgentQueueStats };
const QueueStatsBlock = () => {
    return new Router.RouterView.Block({
        template: html ` <agentx-queues-stats class="queues-stats"></agentx-queues-stats> `,
        styles: [style]
    });
};
export default QueueStatsBlock;
