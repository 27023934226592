var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createLogger } from "@/sdk";
import { SERVICE } from "@agentx/agentx-services";
import { toJS } from "mobx";
import { agent } from "../store-agent";
export var AgentSharedActions;
(function (AgentSharedActions) {
    AgentSharedActions.Init = () => {
        // Bind agent store actions channels
        const logger = createLogger("[AgentSharedActions]");
        // GetIdleCodes
        {
            const d = SERVICE.actionsChannels.createDestination("getIdleCodes/Req");
            const s = SERVICE.actionsChannels.createSource("getIdleCodes/Res");
            const fetchAllIdleCodes = (profile) => __awaiter(this, void 0, void 0, function* () {
                const params = {
                    orgId: profile === null || profile === void 0 ? void 0 : profile.orgId,
                    accessType: profile === null || profile === void 0 ? void 0 : profile.idleCodesAccess
                };
                const response = (yield SERVICE.abs.fetchIdleCodes(params));
                logger.info(`event=fetchAllIdleCodes Successfully fetched ${response.length} idle codes for js sdk`);
                return Array.isArray(response) ? response : [];
            });
            /* istanbul ignore next */
            d.addListener(({ args: [req_uuid] }) => {
                const profile = SERVICE.conf.profile;
                const agentIdleCodes = agent.idleCodes;
                if ((!agentIdleCodes || agentIdleCodes.length === 0) && profile) {
                    fetchAllIdleCodes(profile).then((idleCodesList) => {
                        s.send(idleCodesList, req_uuid);
                        agent.updateIdleCodesList(idleCodesList);
                        profile.idleCodesList = idleCodesList;
                    });
                }
                else {
                    logger.info(`Serve idle codes from store`);
                    s.send(
                    //Copying to prevent mutation
                    toJS(agentIdleCodes), req_uuid);
                }
            });
        }
        // GetWrapUpCodes
        {
            const d = SERVICE.actionsChannels.createDestination("getWrapUpCodes/Req");
            const s = SERVICE.actionsChannels.createSource("getWrapUpCodes/Res");
            const fetchAllAuxCodes = (profile) => __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c, _d;
                const params = {
                    orgId: profile.orgId,
                    accessType: (_b = (_a = profile === null || profile === void 0 ? void 0 : profile.wrapUpData) === null || _a === void 0 ? void 0 : _a.wrapUpProps) === null || _b === void 0 ? void 0 : _b.wrapUpCodeAccess,
                    ids: ((_d = (_c = profile === null || profile === void 0 ? void 0 : profile.wrapUpData) === null || _c === void 0 ? void 0 : _c.wrapUpProps) === null || _d === void 0 ? void 0 : _d.wrapUpCodesList) || []
                };
                const response = (yield SERVICE.abs.fetchWrapUpCodes(params));
                logger.info(`event=fetchAllAuxCodes Successfully fetched ${response.length} wrap up codes for js sdk`);
                return Array.isArray(response) ? response : [];
            });
            /* istanbul ignore next */
            d.addListener(({ args: [req_uuid] }) => {
                const profile = SERVICE.conf.profile;
                const wrapUpReasonList = agent.wrapUpData.wrapUpProps.wrapUpReasonList;
                if ((!wrapUpReasonList || wrapUpReasonList.length === 0) && profile) {
                    fetchAllAuxCodes(profile).then((wrapUpCodes) => {
                        s.send(wrapUpCodes, req_uuid);
                        agent.updateWrapupList(wrapUpCodes);
                        profile.wrapupCodes = wrapUpCodes;
                    });
                }
                else {
                    logger.info(`Serve wrap up codes from store`);
                    s.send(
                    //Copying to prevent mutation
                    toJS(wrapUpReasonList), req_uuid);
                }
            });
        }
        // GetState => in agentx-js-api => agentInfo => latestData
        // GetSubState => in agentx-js-api => agentInfo => latestData
    };
})(AgentSharedActions || (AgentSharedActions = {}));
