import { t } from "@/mixins/i18nMixin";
import { Router } from "@uuip/unified-ui-platform";
import { css } from "lit-element";
import { html } from "lit-html";
import { ROUTE_NOT_FOUND_TYPE } from "../../../../components/constants";
import style from "./RouteNotFoundBlock.scss";
const RouteNotFoundBlock = (homeHref, type) => {
    const TASK_NOT_FOUND = ROUTE_NOT_FOUND_TYPE.TASK_NOT_FOUND;
    const ROUTE_NOT_FOUND = ROUTE_NOT_FOUND_TYPE.ROUTE_NOT_FOUND;
    const ERROR_LINK_TEXT = t("app:errorPage.errorLinkText");
    const getIllustrationName = () => {
        switch (type) {
            case TASK_NOT_FOUND:
                return "Torn-out_page_from_a_book";
            case ROUTE_NOT_FOUND:
                return "Dog_digging_hole";
            default:
                return "";
        }
    };
    return new Router.RouterView.Block({
        template: html `
      <div class="${ROUTE_NOT_FOUND}">
        <uuip-wc-empty-state
          illustration-src="/images/illustrations/plug-service-down-192-red-pink.svg"
          class="${type}"
          illustration-name="${getIllustrationName()}"
        >
          <div class="error-text">
            <p><strong>${t("app:errorPage:errorHeaderNew")}</strong></p>
            <span>${t("app:errorPage:errorForIncorrectRouteNew")}</span>
            <span>
              ${t("app:errorPage:errorClickNew")}
              ${type === ROUTE_NOT_FOUND
            ? html ` <a aria-label="${ERROR_LINK_TEXT}" href="${homeHref}">${ERROR_LINK_TEXT}</a> `
            : html ` <router-link href="${homeHref}">${ERROR_LINK_TEXT}</router-link> `}
            </span>
          </div>
        </uuip-wc-empty-state>
      </div>
    `,
        styles: [
            style,
            css `
        .route-not-found {
          grid-area: route_not_found; /*grid*/
        }
      `
        ]
    });
};
export default RouteNotFoundBlock;
