var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { t } from "@/mixins/i18nMixin";
import { STORE } from "@/store";
import { SERVICE } from "@agentx/agentx-services";
import * as workerTimers from "@uuip/unified-ui-platform-sdk";
import { html } from "lit-html";
import { debounce, logger } from "../Utils";
export class IdleActivity {
    constructor(interval) {
        this.countDownTimerId = undefined;
        /**
         * This clears out the old timeout and start a new setTimeout.
         * STORE is referred to identify if any active task exist.
         */
        this.handleInActivityTimer = () => {
            workerTimers.clearTimeout(this.timeout);
            if (this.countDownTimerId) {
                workerTimers.clearInterval(this.countDownTimerId);
            }
            logger.info("The Inactivity Timeout has been reset to", this.interval / 60000);
            this.timeout = workerTimers.setTimeout(() => {
                if (STORE.agentContact.isTaskPresent()) {
                    this.handleInActivityTimer();
                }
                else {
                    // if no active tasks are present
                    this.showWarningModal();
                    STORE.browserNotifications.firebrowserNotification(t("app:idleInActivity.prolongedBrowserNotification"));
                }
            }, this.interval);
        };
        this.eventListener = (e) => {
            if (e.data.key === "wxcc-desktop-iframe-widget-event") {
                this.handleInActivityTimer();
            }
        };
        this.interval = interval;
        this.timeout = 0;
        this.eventList = ["click", "scroll", "keypress", "ax-auto-wrap-up", "ax-web-call-auto-answer"];
        this.signOutTimer = 60; // sec
    }
    /**
     * Singout handling modal
     */
    handleSignoutClick() {
        return __awaiter(this, void 0, void 0, function* () {
            if (STORE.agentContact.isTaskPresent()) {
                setTimeout(() => {
                    STORE.session.errorModal.setErrorDetails({
                        detail: {
                            header: t("app:common.error"),
                            htmlMessage: html ` <div>${t("app:idleInActivity.errorActiveContacts")}</div> `,
                            backdropClickExit: false,
                            noExitOnEsc: true,
                            buttons: [
                                {
                                    type: "ok",
                                    label: t("app:errorModal.ok"),
                                    onClick: () => {
                                        this.handleInActivityTimer();
                                    }
                                }
                            ]
                        }
                    });
                }, 500);
            }
            else {
                STORE.auth.logout(STORE.auth.refreshToken);
            }
        });
    }
    /**
     * for initiating initial modal.
     */
    showWarningModal() {
        workerTimers.clearTimeout(this.timeout);
        STORE.session.errorModal.setErrorDetails({
            detail: {
                header: t("app:idleInActivity.title"),
                htmlMessage: html `
          <div>
            ${t("app:idleInActivity.signOutInTime")}
            <b
              ><agentx-wc-count-down-timer
                .countdownStartTime=${this.signOutTimer}
                @close-inactivity-modal=${() => {
                    STORE.session.errorModal.updateModalStatus(false);
                    if (STORE.agentContact.isTaskPresent()) {
                        this.handleInActivityTimer();
                    }
                    else {
                        this.inactivityTimeOutAgentLogout();
                    }
                }}
                @countdown-start=${(e) => {
                    if (e.detail && e.detail.intervalId) {
                        this.countDownTimerId = e.detail.intervalId;
                    }
                }}
              ></agentx-wc-count-down-timer
            ></b>
            ${t("app:idleInActivity.signOutOrContinue")}
          </div>
        `,
                backdropClickExit: false,
                noExitOnEsc: true,
                buttons: [
                    {
                        type: "button",
                        variant: "secondary",
                        label: t("app:idleInActivity.signOut"),
                        onClick: () => this.handleSignoutClick()
                    },
                    {
                        type: "button",
                        label: t("app:idleInActivity.staySignedIn"),
                        onClick: () => {
                            this.handleInActivityTimer();
                        }
                    }
                ]
            }
        });
    }
    /**
     * agent signed out and info modal and logout on ok.
     */
    inactivityTimeOutAgentLogout() {
        return __awaiter(this, void 0, void 0, function* () {
            SERVICE.telemetry.timeEvent(SERVICE.telemetry.MIX_EVENT.SIGN_OUT);
            const signOut = yield SERVICE.aqm.agent.logout({ data: { logoutReason: "Inactivity Logout" } });
            if (signOut) {
                if (STORE.app.featureFlags.isWebRTCEnabled &&
                    STORE.agent.deviceType === SERVICE.constants.LoginVoiceOptions.BROWSER) {
                    logger.info("[WebRtc] : deregistering webcalling when agent is logged out due to inactivity timeout");
                    SERVICE.webCalling.deregisterWebCalling();
                }
                logger.info("The Agent has been logged out due to inactivity timeout");
                SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.SIGN_OUT, {
                    [SERVICE.telemetry.MIX_PROPS.SIGN_OUT_METHOD]: "IdleActivity",
                    Status: SERVICE.telemetry.MIX_EVENT.STATUS.SUCCESS
                });
                SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.IDLE_INACTIVITY_SIGN_OUT, {
                    role: STORE.agent.role,
                    agentId: STORE.agent.agentId,
                    orgId: STORE.agent.organizationId
                }, [SERVICE.telemetry.SERVICE_PROVIDERS.mixpanel]);
                STORE.browserNotifications.firebrowserNotification(t("app:idleInActivity.autoSignedOutBrowserNotification"));
                STORE.session.errorModal.setErrorDetails({
                    detail: {
                        header: t("app:idleInActivity.signOut"),
                        message: t("app:idleInActivity.signedOutSignInAgain"),
                        backdropClickExit: false,
                        noExitOnEsc: true,
                        buttons: [
                            {
                                type: "ok",
                                label: t("app:errorModal.ok"),
                                onClick: () => {
                                    STORE.auth.logout(STORE.auth.refreshToken);
                                }
                            }
                        ]
                    }
                }); // signed out modal needs to show after closing warn modal.
            }
        });
    }
    /**
     * Register all UI related events here.
     */
    register() {
        this.eventList.forEach((eventName) => {
            window.addEventListener(eventName, debounce(this.handleInActivityTimer));
        });
        this.postMessageListener();
    }
    /**
     * postMessage listener from Iframe based widgets
     */
    postMessageListener() {
        window.addEventListener("message", debounce(this.eventListener));
    }
    deRegister() {
        workerTimers.clearTimeout(this.timeout);
        this.eventList.forEach((eventName) => {
            window.removeEventListener(eventName, debounce(this.handleInActivityTimer));
        });
    }
}
