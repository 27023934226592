var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import { STORE } from "@/store";
import "@agentx/agentx-mfe-wc-based";
import "@momentum-ui/web-components";
import { customElement, html, internalProperty, LitElement, property } from "lit-element";
import { nothing } from "lit-html";
import { ADD_FOCUS_AGENTX_GOTO_ANALYZER, AGENTX_GOTO_ANALYZER_COMPONENT, AGENTX_MORE_ACTIONS_OPENED } from "./constants";
import "./GotoAnalyzer";
import style from "./GotoAnalyzer.scss";
/**
 * @element agentx-more-actions-wrapper
 */
let MoreActionsWrapper = class MoreActionsWrapper extends LitElement {
    constructor() {
        super(...arguments);
        this.isAnalyzerEnabled = STORE.agent.isAnalyzerEnabled;
    }
    static get styles() {
        return style;
    }
    connectedCallback() {
        super.connectedCallback();
        window.addEventListener("store-profile-update", () => {
            this.isAnalyzerEnabled = STORE.agent.isAnalyzerEnabled;
        });
        this.isAnalyzerEnabled = STORE.agent.isAnalyzerEnabled;
        // If the GotoAnalyzer is the first list element, set focus to the goto Analyzer link
        if (this.isAnalyzerEnabled &&
            this.actionList &&
            this.actionList.length > 0 &&
            this.actionList[0].comp === AGENTX_GOTO_ANALYZER_COMPONENT) {
            document.addEventListener(AGENTX_MORE_ACTIONS_OPENED, this.addFocusToGotoAnalyzer);
        }
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        document.removeEventListener(AGENTX_MORE_ACTIONS_OPENED, this.addFocusToGotoAnalyzer);
    }
    addFocusToGotoAnalyzer() {
        document.dispatchEvent(new CustomEvent(ADD_FOCUS_AGENTX_GOTO_ANALYZER));
    }
    handleGotoAnalyserFocus(event) {
        var _a, _b;
        const ind = (_a = this.actionList) === null || _a === void 0 ? void 0 : _a.findIndex((opt) => {
            return opt.comp === AGENTX_GOTO_ANALYZER_COMPONENT;
        });
        if (ind === ((_b = event.detail) === null || _b === void 0 ? void 0 : _b.selected)) {
            this.addFocusToGotoAnalyzer();
        }
    }
    isActionListValidToRender() {
        if (this.actionList && this.actionList.length > 0) {
            return ((this.actionList.length === 1 &&
                this.actionList[0].comp === AGENTX_GOTO_ANALYZER_COMPONENT &&
                this.isAnalyzerEnabled) ||
                this.actionList.length > 1);
        }
        else {
            return false;
        }
    }
    render() {
        return html `
      ${this.isActionListValidToRender()
            ? html `
            <agentx-wc-more-actions-widget
              slot="settings"
              class="more-actions-wrapper"
              ?isResetTabOrderEnabled=${false}
            >
              <div slot="custom-action">
                <md-list
                  class="overlay-wrapper-list"
                  @list-item-change=${(event) => this.handleGotoAnalyserFocus(event)}
                >
                  ${this.actionList &&
                this.actionList.map((actionComponent) => actionComponent.comp !== AGENTX_GOTO_ANALYZER_COMPONENT || this.isAnalyzerEnabled
                    ? html `
                          <md-list-item class="action-item-li" slot="list-item">
                            <uuip-dynamic-widget
                              .options=${actionComponent}
                              class="action-component"
                            ></uuip-dynamic-widget>
                          </md-list-item>
                        `
                    : html ``)}
                </md-list>
              </div>
            </agentx-wc-more-actions-widget>
          `
            : nothing}
    `;
    }
};
__decorate([
    property()
], MoreActionsWrapper.prototype, "actionList", void 0);
__decorate([
    internalProperty()
], MoreActionsWrapper.prototype, "isAnalyzerEnabled", void 0);
MoreActionsWrapper = __decorate([
    customElement("agentx-more-actions-wrapper")
], MoreActionsWrapper);
export { MoreActionsWrapper };
