var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { t } from "@/mixins/i18nMixin";
import { createLogger } from "@/sdk";
import { STORE } from "@/store";
import { MediaType } from "@/store/store-agent-contact";
import { ScreenPop } from "@agentx/agentx-mfe-wc-based";
import { SERVICE } from "@agentx/agentx-services";
import { ToasterNotificationItemContent } from "@uuip/unified-ui-platform-common-components";
import { Notifications } from "@uuip/unified-ui-platform-sdk";
import { action, computed, observable } from "mobx";
const logger = createLogger("[store-screen-pop] [ScreenPop]");
export const SCREEN_POP_CONFIG = "screenPopConfig";
export default class ModuleScreenPop {
    constructor() {
        /**
         * Actions
         */
        this.setScreenPopUrl = (screenPop) => {
            this.screenPopUrl = screenPop;
        };
        this.updateScreenPopData = (interactionId) => {
            const screenPOPData = sessionStorage.getItem(SCREEN_POP_CONFIG) || "[]";
            const removedInteractionData = JSON.parse(screenPOPData).filter((data) => data.interactionId !== interactionId);
            sessionStorage.setItem(SCREEN_POP_CONFIG, JSON.stringify(removedInteractionData));
            this.setScreenPopUrl(removedInteractionData);
        };
        this.getTaskDetils = (interactionId) => {
            const task = STORE.agentContact.taskMap.get(interactionId);
            if (task) {
                return STORE.agentContact.flattenTaskForTaskList(STORE.agentContact.maskTask(task), STORE.agent.agentId);
            }
            return undefined;
        };
        this.getScreenNotificationConf = (url, interactionId, screenPopDesktopLabel) => {
            const task = this.getTaskDetils(interactionId);
            return {
                type: Notifications.ItemMeta.Type.Default,
                mode: Notifications.ItemMeta.Mode.Silent,
                title: `${t("app:notifications.screenPopNotifTitle")}`,
                data: new ToasterNotificationItemContent.DataController({
                    text: `${STORE.agentContact.isMediaTypeTelePhony
                        ? (task === null || task === void 0 ? void 0 : task.displayAni)
                            ? task === null || task === void 0 ? void 0 : task.displayAni
                            : task === null || task === void 0 ? void 0 : task.dnis
                        : t("app:notifications.screenPopEventTitle")}`,
                    link: `${url}`,
                    linkTooltip: `${screenPopDesktopLabel}`,
                    iconDetail: this.getMediaTypeIcon((task === null || task === void 0 ? void 0 : task.mediaType) || ""),
                    linkName: `${screenPopDesktopLabel}`
                })
            };
        };
        /**   This Method is nowhere used or called
         * @action addSilentNotificationForScreenPop = (finalUrl: string, interactionId: string) => {
         *   STORE.generalNotifications.fireScreenPop(
         *     this.getScreenNotificationConf(finalUrl, interactionId, screenPopDesktopLabel)
         *   );
         * };
         */
        this.addToastNotificationForScreenPop = (finalUrl, interactionId, screenPopDesktopLabel) => {
            const data = this.getScreenNotificationConf(finalUrl, interactionId, screenPopDesktopLabel);
            data.mode = Notifications.ItemMeta.Mode.AutoDismiss;
            STORE.generalNotifications.fireScreenPop(data);
        };
        /** update the screen pop config in the store so that <agentx-wx-screen-pop> should be rendered*/
        this.updateScreenPopStore = (screenPop) => {
            this.setScreenPopUrl(screenPop);
        };
        this.updateScreenPopStoreOnReload = () => {
            const screenPopConfigData = ScreenPop.getAlreadyOpenedScreenPopConfig() || [];
            if (screenPopConfigData) {
                this.updateScreenPopStore(screenPopConfigData);
            }
        };
        this.handleScreenPop = (screenPopDetail) => {
            logger.info("event=handleScreenPop | Screen-pop event data", screenPopDetail);
            const settings = screenPopDetail.data;
            const target = settings.target;
            const task = this.getTaskDetils(screenPopDetail.data.interactionId);
            const saveToSessionStorage = target === ScreenPop.TARGET_INSIDE_DESKTOP;
            const mediaType = (task === null || task === void 0 ? void 0 : task.mediaType) || "";
            const finalUrl = ScreenPop.constructScreenPopUrl(screenPopDetail, mediaType, saveToSessionStorage);
            SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.SCREEN_POP_DELIVERED, {
                [SERVICE.telemetry.MIX_PROPS.SCREEN_POP_TARGET]: screenPopDetail.data.target,
                [SERVICE.telemetry.MIX_PROPS.SCREEN_POP_INTERACTION]: screenPopDetail.data.interactionId,
                [SERVICE.telemetry.MIX_PROPS.MEDIA_TYPE]: task === null || task === void 0 ? void 0 : task.mediaType
            });
            if (target === ScreenPop.TARGET_INSIDE_DESKTOP) {
                logger.info("Opening Screen-pop inside desktop");
                const existingScreenPopData = ScreenPop.getAlreadyOpenedScreenPopConfig() || [];
                this.updateScreenPopStore(existingScreenPopData);
            }
            else {
                logger.info(`Opening Screen-pop as browser tab: ${target}`);
                window.open(finalUrl, target === ScreenPop.TARGET_SAME_BROWSER_TAB ? target : "");
            }
            this.addToastNotificationForScreenPop(finalUrl, screenPopDetail.data.interactionId, screenPopDetail.data.screenPopDesktopLabel &&
                screenPopDetail.data.screenPopDesktopLabel !== "null" &&
                screenPopDetail.data.screenPopDesktopLabel.trim() !== ""
                ? screenPopDetail.data.screenPopDesktopLabel
                : t("app:notifications.screenPopEventTitle"));
        };
        this.screenPopUrl = [];
    }
    /**
     * Computed
     */
    get screenPopSelector() {
        return this.screenPopUrl.filter((data) => data.interactionId === (STORE.agentContact.taskSelected ? STORE.agentContact.taskSelected.interactionId : ""));
    }
    getMediaTypeIcon(mediaType) {
        const iconBgColor = "var(--md-olive-20)";
        const iconColor = "var(--md-olive-70)";
        switch (mediaType) {
            case MediaType.Telephony:
                return {
                    iconName: "icon-handset_16",
                    bgColor: iconBgColor,
                    iconColor
                };
            case MediaType.Chat:
                return {
                    iconName: "icon-chat-active_16",
                    bgColor: iconBgColor,
                    iconColor
                };
            case MediaType.Email:
                return {
                    iconName: "icon-email_16",
                    bgColor: iconBgColor,
                    iconColor
                };
            case MediaType.Social:
                return {
                    iconName: "icon-pop-out_16",
                    bgColor: iconBgColor,
                    iconColor
                };
            default:
                return {
                    iconName: "icon-info_16",
                    bgColor: iconBgColor,
                    iconColor
                };
        }
    }
}
__decorate([
    observable
], ModuleScreenPop.prototype, "screenPopUrl", void 0);
__decorate([
    computed
], ModuleScreenPop.prototype, "screenPopSelector", null);
__decorate([
    action
], ModuleScreenPop.prototype, "updateScreenPopData", void 0);
__decorate([
    action
], ModuleScreenPop.prototype, "addToastNotificationForScreenPop", void 0);
__decorate([
    action
], ModuleScreenPop.prototype, "updateScreenPopStore", void 0);
__decorate([
    action
], ModuleScreenPop.prototype, "updateScreenPopStoreOnReload", void 0);
__decorate([
    action
], ModuleScreenPop.prototype, "handleScreenPop", void 0);
