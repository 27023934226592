var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createLogger } from "@/sdk";
import { STORE } from "@/store";
import { BROWSER } from "@/store/shared-actions/constants";
import { SERVICE } from "@agentx/agentx-services";
import { customElement, html, internalProperty, LitElement, property, query } from "lit-element";
import { debounce } from "../utils/Utils";
import { registerWebCalling } from "../utils/UtilsWebCalling";
import { CANPLAY_THROUGH, DEVICE_CHANGED, INTERACTION_EVENTS, WEB_CALLING_EVENTS } from "./constants";
const logger = createLogger("WebCallingWidget");
let WebCallingWidget = class WebCallingWidget extends LitElement {
    constructor() {
        super(...arguments);
        this.languageResources = "/i18n/{{lng}}/{{ns}}.json";
        this.mediaSettings = {};
        this.micDeviceId = "";
        this.localMicrophoneStream = {};
        this.micLabel = "";
        this.speakerLabel = "";
        this.agentId = "";
        this.handleCallAnswerEvt = () => __awaiter(this, void 0, void 0, function* () {
            if (STORE.agent.deviceType === BROWSER) {
                logger.info("[WebRtc]: answering webCall :: interactionId", STORE.agentContact.selectedTaskId);
                yield this.updateMediaStreams();
                this.updateSpeakerSettings();
                STORE.agentContact.setIsWebCallMuted(false);
                SERVICE.webCalling.answerWebCall(this.localMicrophoneStream);
            }
            else {
                logger.info("[WebRtc]: Can not answer a non WebRtc Call", STORE.agentContact.selectedTaskId);
            }
        });
    }
    connectedCallback() {
        super.connectedCallback();
        this.registerWebCallEvents();
        this.agentId = STORE.agent.agentId;
    }
    updated(changedProperties) {
        super.updated(changedProperties);
        if (changedProperties.has("mediaSettings")) {
            this.updateSpeakerSettings();
        }
    }
    firstUpdated(props) {
        super.firstUpdated(props);
        this.cleanUpMedia();
        this.addPlayIfPausedEvents();
    }
    // handle speaker/mic updates from settings.
    updateSpeakerSettings() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.mediaSettings.speaker.ID) {
                this.remoteAudio.setSinkId(this.mediaSettings.speaker.ID);
            }
            if (this.remoteAudio) {
                this.remoteAudio.volume = this.mediaSettings.speaker.volume;
                this.remoteAudio.muted = this.mediaSettings.speaker.mute;
            }
        });
    }
    updateRemoteTrack(track) {
        this.remoteAudio.srcObject = new MediaStream([track]);
    }
    getMedia() {
        var _a, _b;
        return (_b = (_a = window === null || window === void 0 ? void 0 : window.callingClient) === null || _a === void 0 ? void 0 : _a.mediaEngine) === null || _b === void 0 ? void 0 : _b.Media;
    }
    getMic() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            //CX-15987 WxCC | Intermittent WebRTC calls failure
            //This flow should be improved in general.
            //You shouldnt remain having Desktop selected if you have it selected already and THEN unplug your mic or lose permissions for any reason :p
            //
            registerWebCalling(STORE.app.domainUrl);
            const microphones = yield ((_a = this.getMedia()) === null || _a === void 0 ? void 0 : _a.getMicrophones());
            if (this.mediaSettings.mic.ID) {
                return microphones === null || microphones === void 0 ? void 0 : microphones.find((deviceObject) => deviceObject.ID === this.mediaSettings.mic.ID);
            }
            return microphones[0];
        });
    }
    getSpeaker() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const speakers = yield ((_a = this.getMedia()) === null || _a === void 0 ? void 0 : _a.getSpeakers());
            if (this.mediaSettings.speaker.ID) {
                return speakers.find((deviceObject) => deviceObject.ID === this.mediaSettings.speaker.ID);
            }
            return speakers[0];
        });
    }
    updateSpeaker() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const speaker = yield this.getSpeaker();
                if (this.speakerLabel !== speaker.label) {
                    logger.info(`[WebRtc] : event=updateSpeaker ${speaker}, agentId ${this.agentId}`);
                    this.micLabel = speaker.label;
                    this.remoteAudio.setSinkId(speaker.ID);
                }
            }
            catch (e) {
                logger.error("[WebRtc] : updateMediaStreams Failed!", e);
            }
        });
    }
    // streamMedia with localAudio.
    getMediaStream(audio) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                logger.info("[WebRtc] : audio passing for streaming", audio);
                const microphoneStream = yield SERVICE.webCalling.getMicrophoneStream(audio);
                if (microphoneStream) {
                    logger.info("[WebRtc] : event=getMicrophoneStream Success!", microphoneStream, "agentId:", this.agentId);
                    this.localMicrophoneStream = microphoneStream;
                    this.localAudio.srcObject = microphoneStream.outputStream;
                    //Firefox not respecting 'muted' property on audio element, need to set attribute after getting audio
                    //https://github.com/vuejs/vue/issues/6887
                    this.localAudio.muted = true;
                }
            }
            catch (e) {
                logger.error("[WebRtc] : getMediaStreams Failed!", e);
            }
        });
    }
    // update streamMedia when mic device change.
    updateMediaStreams() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const audio = yield this.getMic();
                this.micLabel = audio.label;
                this.micDeviceId = audio.ID;
                logger.info("[WebRtc] : updateMediaStreams request !");
                yield this.getMediaStream(audio);
            }
            catch (e) {
                logger.error("[WebRtc] : updateMediaStreams Failed!", e);
            }
        });
    }
    getMediaElements() {
        return [this.localAudio, this.remoteAudio];
    }
    // cleanup all media tracks.
    cleanUpMedia() {
        const htmlMediaElements = this.getMediaElements();
        logger.info("[WebRtc]: event=cleanUpMedia, agentId :", this.agentId);
        htmlMediaElements === null || htmlMediaElements === void 0 ? void 0 : htmlMediaElements.forEach((elem) => {
            var _a, _b;
            if (elem === null || elem === void 0 ? void 0 : elem.srcObject) {
                (_b = (_a = elem.srcObject) === null || _a === void 0 ? void 0 : _a.getTracks()) === null || _b === void 0 ? void 0 : _b.forEach((track) => track.stop());
                elem.srcObject = null;
            }
        });
    }
    handleCallIncomingEvt() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.updateMediaStreams();
            this.updateSpeakerSettings();
            STORE.agentContact.setIsWebCallMuted(false);
        });
    }
    handleCallEstablishedEvt() {
        this.stopWebCallRing();
        logger.info(`[WebRtc]: webCall established :: interactionId", ${STORE.agentContact.selectedTaskId}`);
    }
    handleCallDisconnectedEvt() {
        this.stopWebCallRing();
        logger.info(`[WebRtc]: webCall disconnected :: interactionId", ${STORE.agentContact.selectedTaskId}`);
        STORE.agentContact.setIsWebCallMuted(false);
        this.cleanUpMedia();
    }
    handleCallDeclineEvt() {
        if (STORE.agent.deviceType === BROWSER) {
            SERVICE.webCalling.declineWebCall();
            this.cleanUpMedia();
        }
        else {
            logger.info("[WebRtc]: Can not decline a non WebRtc Call", STORE.agentContact.selectedTaskId);
        }
    }
    stopWebCallRing() {
        if (STORE.app.featureFlags.isWebrtcRingingEnabled) {
            STORE.browserNotifications.stopRing();
            logger.info("[WebRtc]: Web call ringing stopped");
        }
    }
    registerWebCallEvents() {
        var _a;
        window.addEventListener(WEB_CALLING_EVENTS.AX_WC_REMOTE_MEDIA_UPDATE, (e) => {
            this.updateRemoteTrack(e.detail.value);
        });
        window.addEventListener(WEB_CALLING_EVENTS.AX_WC_ANSWER, debounce(this.handleCallAnswerEvt, 300));
        window.addEventListener(WEB_CALLING_EVENTS.AX_WC_DECLINE, () => {
            this.handleCallDeclineEvt();
        });
        // mute the call while mic is muted in sound and mic settings.
        window.addEventListener(WEB_CALLING_EVENTS.AX_WC_ESTABLISHED, () => {
            this.handleCallEstablishedEvt();
        });
        // whenever call disconnects set the mute flag to false
        window.addEventListener(WEB_CALLING_EVENTS.AX_WC_DISCONNECTED, () => {
            this.handleCallDisconnectedEvt();
        });
        window.addEventListener(INTERACTION_EVENTS.AX_INTERACTION_DELETE, () => {
            this.handleCallDisconnectedEvt();
        });
        // mute/unmute the call when interaction control button clicked
        window.addEventListener(WEB_CALLING_EVENTS.AX_WC_MUTE_UNMUTE, () => {
            SERVICE.webCalling.muteWebCall(this.localMicrophoneStream);
            // update the store variable so as to detect the changes in view
            STORE.agentContact.setIsWebCallMuted();
        });
        // Handle mediaDevice Update by system.
        (_a = this.getMedia()) === null || _a === void 0 ? void 0 : _a.on(DEVICE_CHANGED, () => {
            logger.info("[WebRtc]: event=mediaDeviceChanged, agentId :", this.agentId);
            // this.updateMediaStreams(); revert once calling-sdk support update track.
            this.updateSpeaker();
        });
    }
    unRegisterWebCallEvents() {
        window.removeEventListener(WEB_CALLING_EVENTS.AX_WC_REMOTE_MEDIA_UPDATE, (e) => {
            this.updateRemoteTrack(e.detail.value);
        });
        window.removeEventListener(WEB_CALLING_EVENTS.AX_WC_ANSWER, debounce(this.handleCallAnswerEvt, 300));
        window.removeEventListener(WEB_CALLING_EVENTS.AX_WC_DECLINE, () => {
            this.handleCallDeclineEvt();
        });
        // mute the call while mic is muted in sound and mic settings.
        window.removeEventListener(WEB_CALLING_EVENTS.AX_WC_ESTABLISHED, () => {
            this.handleCallEstablishedEvt();
        });
        // mute/unmute the call when interaction control button clicked
        window.removeEventListener(WEB_CALLING_EVENTS.AX_WC_MUTE_UNMUTE, () => {
            SERVICE.webCalling.muteWebCall(this.localMicrophoneStream);
        });
        window.removeEventListener(WEB_CALLING_EVENTS.AX_WC_DISCONNECTED, () => {
            this.handleCallDisconnectedEvt();
        });
        window.removeEventListener(INTERACTION_EVENTS.AX_INTERACTION_DELETE, () => {
            this.handleCallDisconnectedEvt();
        });
    }
    // play default paused media.
    addPlayIfPausedEvents() {
        const htmlMediaElements = this.getMediaElements();
        logger.info("[WebRtc]: event=canplaythrough play mediaElements, agentId:", this.agentId);
        htmlMediaElements.forEach((elem) => {
            elem === null || elem === void 0 ? void 0 : elem.addEventListener(CANPLAY_THROUGH, () => {
                if (elem.paused) {
                    elem.play();
                }
            });
        });
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.cleanUpMedia();
        this.unRegisterWebCallEvents();
        SERVICE.webCalling.deregisterWebCalling();
    }
    render() {
        return html `
      <div class="web-call-audio-container" hidden>
        <audio id="local-audio" autoplay></audio>
        <audio id="remote-audio" autoplay></audio>
      </div>
    `;
    }
};
__decorate([
    property({ type: Object })
], WebCallingWidget.prototype, "mediaSettings", void 0);
__decorate([
    property({ type: String })
], WebCallingWidget.prototype, "micDeviceId", void 0);
__decorate([
    internalProperty()
], WebCallingWidget.prototype, "localMicrophoneStream", void 0);
__decorate([
    internalProperty()
], WebCallingWidget.prototype, "micLabel", void 0);
__decorate([
    internalProperty()
], WebCallingWidget.prototype, "speakerLabel", void 0);
__decorate([
    internalProperty()
], WebCallingWidget.prototype, "agentId", void 0);
__decorate([
    query("#local-audio")
], WebCallingWidget.prototype, "localAudio", void 0);
__decorate([
    query("#remote-audio")
], WebCallingWidget.prototype, "remoteAudio", void 0);
WebCallingWidget = __decorate([
    customElement("web-calling-widget")
], WebCallingWidget);
export { WebCallingWidget };
