import { STORE } from "@/store";
import { fetchManagedQueues, fetchManagedTeams, fetchQueuesManagedTeams, fetchQueuesManagedQueues } from "./Utils";
const updateSelectedChannelList = (e) => {
    STORE.agent.updateSelectedChannelList(e.detail);
};
const updateSelectedTeamList = (e) => {
    STORE.agent.updateSelectedTeamList(e.detail);
};
const updateSelectedQueueList = (e) => {
    STORE.agent.updateSelectedQueueList(e.detail);
};
const fetchManagedTeamsUtil = () => {
    fetchManagedTeams();
};
const fetchManagedQueuesUtil = () => {
    fetchManagedQueues();
};
const updateOrgIdleCodesUtil = (e) => {
    STORE.agent.updateOrganizationIdleCodes(e.detail.isFetchingSuccess, e.detail.response);
};
const updateQueuesSelectedChannelList = (e) => {
    STORE.agent.updateQueuesSelectedChannelList(e.detail);
};
const updateQueuesSelectedTeamList = (e) => {
    STORE.agent.updateQueuesSelectedTeamList(e.detail);
};
const updateQueuesSelectedQueueList = (e) => {
    STORE.agent.updateQueuesSelectedQueueList(e.detail);
};
const fetchQueuesManagedTeamsUtil = () => {
    fetchQueuesManagedTeams();
};
const fetchQueuesManagedQueuesUtil = () => {
    fetchQueuesManagedQueues();
};
export const attachDynamicLayoutListeners = () => {
    window.addEventListener("channel-filter-selected", updateSelectedChannelList);
    window.addEventListener("team-filter-selected", updateSelectedTeamList);
    window.addEventListener("queue-filter-selected", updateSelectedQueueList);
    window.addEventListener("fetch-managed-team", fetchManagedTeamsUtil);
    window.addEventListener("fetch-managed-queue", fetchManagedQueuesUtil);
    window.addEventListener("update-org-idle-codes", updateOrgIdleCodesUtil);
    window.addEventListener("queues-channel-filter-selected", updateQueuesSelectedChannelList);
    window.addEventListener("queues-team-filter-selected", updateQueuesSelectedTeamList);
    window.addEventListener("queues-queue-filter-selected", updateQueuesSelectedQueueList);
    window.addEventListener("fetch-queues-managed-team", fetchQueuesManagedTeamsUtil);
    window.addEventListener("fetch-queues-managed-queue", fetchQueuesManagedQueuesUtil);
};
export const removeDynamicLayoutListeners = () => {
    window.removeEventListener("channel-filter-selected", updateSelectedChannelList);
    window.removeEventListener("team-filter-selected", updateSelectedTeamList);
    window.removeEventListener("queue-filter-selected", updateSelectedQueueList);
    window.removeEventListener("fetch-managed-team", fetchManagedTeamsUtil);
    window.removeEventListener("fetch-managed-queue", fetchManagedQueuesUtil);
    window.removeEventListener("update-org-idle-codes", updateOrgIdleCodesUtil);
    window.removeEventListener("queues-channel-filter-selected", updateQueuesSelectedChannelList);
    window.removeEventListener("queues-team-filter-selected", updateQueuesSelectedTeamList);
    window.removeEventListener("queues-queue-filter-selected", updateQueuesSelectedQueueList);
    window.removeEventListener("fetch-queues-managed-team", fetchQueuesManagedTeamsUtil);
    window.removeEventListener("fetch-queues-managed-queue", fetchQueuesManagedQueuesUtil);
};
