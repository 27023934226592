var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as workerTimers from "@uuip/unified-ui-platform-sdk";
import { customElement, html, LitElement, query } from "lit-element";
import { AX_ANNOUNCEMENT_UPDATE, FIVE_HUNDRED_MS, POLITENESS_ASSERTIVE, POLITENESS_POLITE } from "./constants";
import style from "./GlobalLiveAnnouncer.scss";
let GlobalLiveAnnouncer = class GlobalLiveAnnouncer extends LitElement {
    constructor() {
        super(...arguments);
        this.assertiveTimeout = 0;
        this.politeTimeout = 0;
        this.resetAnnouncementWithDelay = (liveRegionEl, politeness) => {
            const timeoutVar = politeness === POLITENESS_ASSERTIVE ? this.assertiveTimeout : this.politeTimeout;
            if (timeoutVar) {
                workerTimers.clearTimeout(timeoutVar);
            }
            const newTimeout = workerTimers.setTimeout(() => {
                liveRegionEl.innerHTML = "&nbsp;";
            }, FIVE_HUNDRED_MS);
            if (politeness === POLITENESS_ASSERTIVE) {
                this.assertiveTimeout = newTimeout;
            }
            else {
                this.politeTimeout = newTimeout;
            }
        };
        this.updateAnnouncement = (e) => {
            var _a, _b, _c, _d, _e;
            const newAnnouncement = ((_b = (_a = e === null || e === void 0 ? void 0 : e.detail) === null || _a === void 0 ? void 0 : _a.thisEvent) === null || _b === void 0 ? void 0 : _b.message) || ((_c = e === null || e === void 0 ? void 0 : e.detail) === null || _c === void 0 ? void 0 : _c.message) || "";
            if (((_e = (_d = e === null || e === void 0 ? void 0 : e.detail) === null || _d === void 0 ? void 0 : _d.thisEvent) === null || _e === void 0 ? void 0 : _e.politeness) === POLITENESS_ASSERTIVE) {
                this.globalAriaLiveAssertive.innerHTML = newAnnouncement;
                this.resetAnnouncementWithDelay(this.globalAriaLiveAssertive, POLITENESS_ASSERTIVE);
            }
            else {
                this.globalAriaLivePolite.innerHTML = newAnnouncement;
                this.resetAnnouncementWithDelay(this.globalAriaLivePolite, POLITENESS_POLITE);
            }
        };
        this.registerGlobalLiveAnnouncer = () => {
            window.addEventListener(AX_ANNOUNCEMENT_UPDATE, this.updateAnnouncement);
        };
        this.unRegisterGlobalLiveAnnouncer = () => {
            window.removeEventListener(AX_ANNOUNCEMENT_UPDATE, this.updateAnnouncement);
        };
    }
    static get styles() {
        return style;
    }
    connectedCallback() {
        super.connectedCallback();
        this.registerGlobalLiveAnnouncer();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.unRegisterGlobalLiveAnnouncer();
    }
    render() {
        return html `
      <div role="region" id="globalAriaLivePolite" class="sr-only" aria-live="polite"></div>
      <div role="alert" id="globalAriaLiveAssertive" class="sr-only" aria-live="assertive"></div>
    `;
    }
};
__decorate([
    query("#globalAriaLivePolite")
], GlobalLiveAnnouncer.prototype, "globalAriaLivePolite", void 0);
__decorate([
    query("#globalAriaLiveAssertive")
], GlobalLiveAnnouncer.prototype, "globalAriaLiveAssertive", void 0);
GlobalLiveAnnouncer = __decorate([
    customElement("global-live-announcer")
], GlobalLiveAnnouncer);
export { GlobalLiveAnnouncer };
