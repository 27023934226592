import { createLogger } from "@/sdk";
import { STORE } from "@/store";
import { SERVICE } from "@agentx/agentx-services";
import { I18N } from "@uuip/unified-ui-platform-sdk";
const logger = createLogger("[AgentX i18n]");
const init = () => {
    // Bind events
    SERVICE.i18n.on("failedLoading", (lng, ns, msg) => logger.info("i18n resource bundle loading failed:", lng, ns, msg));
    SERVICE.i18n.on("initialized", () => logger.info("i18n resource bundle initialized"));
    SERVICE.i18n.on("languageChanged", (lng) => {
        logger.info("i18n resource bundle changed browsers language:", lng);
        STORE.app.updateLanguage(lng);
    });
    // Init Info
    logger.info("i18n resource bundle initializing with options:", SERVICE.i18n.DEFAULT_INIT_OPTIONS);
    //Init
    SERVICE.i18n
        .init(SERVICE.i18n.DEFAULT_INIT_OPTIONS)
        .catch((err) => logger.error("event=I18NLoadFailure | loading of i18n resource bundle failed", err));
};
init();
export const i18nMixin = I18N.createMixin({ i18n: SERVICE.i18n });
export function t(...args) {
    // Testing
    if (process.env.NODE_ENV === "test") {
        return (args && args.length ? (Array.isArray(args[0]) ? args[0].join("") : args[0]) : "");
    }
    return SERVICE.i18n.t(...args);
}
