import { BaseView } from "@/app/routing/views/_base-view/BaseView";
import { STORE } from "@/store";
import { MediaType } from "@/store/store-agent-contact";
import { Router } from "@uuip/unified-ui-platform";
import { createLogger } from "@/sdk";
import { css } from "lit-element";
import RouteNotFoundBlock from "../_not-found-view/blocks/RouteNotFoundBlock";
import style from "./TaskSelectedView.scss";
import PanelOneBlock from "./blocks/PanelOneBlock";
import PanelTwoBlock from "./blocks/PanelTwoBlock";
const logger = createLogger("AgentXBaseView");
const taskArrayHasLength = () => {
    STORE.agentContact.taskArray.length
        ? logger.info(`agentContact taskArray has length ${STORE.agentContact.taskArray.length}`)
        : logger.info(`agentContact taskArray has no length ${STORE.agentContact.taskArray.length}`);
    return STORE.agentContact.taskArray.length;
};
export const TaskSelectedView = (homeHref) => {
    var _a, _b;
    return new Router.RouterView.BlocksMap(BaseView(), {
        blocks: STORE.agentContact.interactionIds.indexOf(STORE.routing.currentRouteValues.params.taskId) !== -1
            ? Object.assign({ panel_one: PanelOneBlock() }, (taskArrayHasLength() && { aux_pane: PanelTwoBlock() })) : { route_not_found: RouteNotFoundBlock(homeHref, "task-not-found") },
        styles: [
            style,
            STORE.agentContact.taskSelected
                ? ((_a = STORE.agentContact.taskSelected) === null || _a === void 0 ? void 0 : _a.mediaType) === MediaType.Telephony ||
                    ((_b = STORE.agentContact.taskSelected) === null || _b === void 0 ? void 0 : _b.mediaType) === MediaType.Midcall
                    ? css `
              :host {
                grid-template-areas:
                  "header header header header"
                  "nav banner banner banner"
                  "nav tasks common-control common-control"
                  "nav tasks panel-two panel-two";
                grid-template-columns: var(--nav-bar-width) auto 1fr 1fr;
                grid-template-rows: auto auto auto 1fr;
              }
            `
                    : css `
              :host {
                grid-template-areas:
                  "header header header header"
                  "nav banner banner banner"
                  "nav tasks common-control panel-two"
                  "nav tasks panel-one panel-two";
                grid-template-columns: var(--nav-bar-width) auto minmax(auto, 2fr) 1.5fr;
                grid-template-rows: auto auto auto 1fr;
              }
            `
                : css `
            :host {
              grid-template-areas:
                "header header header header"
                "nav banner banner banner"
                "nav tasks common-control common-control"
                "nav tasks route_not_found route_not_found";
              grid-template-columns: var(--nav-bar-width) auto 1fr 1fr;
              grid-template-rows: auto auto auto 1fr;
            }
          `
        ]
    });
};
