export const OUTDIAL_ERROR_REASONS = {
    DC_CALLS_LIMIT_EXCEEDED: "app:outbound.errorDcCallsLimitExceeded",
    ORG_CALLS_LIMIT_EXCEEDED: "app:outbound.errorOrgCallsLimitExceeded",
    NO_ANSWER_FROM_CUSTOMER: "app:outbound.errorOutDialNoAnswerFromCustomer",
    CUSTOMER_UNAVAILABLE: "app:outbound.errorOutDialCustomerUnavailable",
    AGENT_ENDS: "app:outbound.errorOutDialAgentEnds",
    CUSTOMER_BUSY: "app:outbound.errorOutDialCustomerBusy"
};
export const OUTBOUND_REASONS_MAPPING = {
    NO_MATCHING_AGENT_CHANNEL_FOUND: "app:outbound.errorNoMatchingAgentChannelFound"
};
export const STATE_CONSULTING = "consulting";
export const STATE_CONSULT = "consult";
export const STATE_CONFERENCE = "conference";
export const STATE_WRAPUP = "wrapUp";
export const STATE_CONFERENCING = "conferencing";
export const STATE_CONSULT_INITIATED = "consultInitiated";
export const STATE_CONSULT_COMPLETED = "consultCompleted";
export const STATE_CONNECTED = "connected";
export const SUPERVISOR = "supervisor";
export const BROWSER = "BROWSER";
export const DESKTOP_NOTIFICATION_SOUND_SETTINGS = "desktop-notification-sound-settings";
export const SOUND_DEFAULT_SETTINGS = '{"default":{"sound":true,"volume":0.8,"mute":false}}';
export const EP_DN = "EpDn";
export const MACHINE = "MACHINE";
export const SELF = "SELF";
export const EPDN = "EP-DN";
export const DESKTOP_HIDE_WEBEX_CALLING_NOTIFICATIONS_SETTING = "desktop-hide-webex-calling-notifications-setting";
