import { CONTACT_HISTORY_TAB_TITLE, MEDIA_TYPES, TOGGLE_MAXIMIZED_EVENT } from "@/app/components/constants";
import { RouteName } from "@/app/routing/routing";
import { auxiliaryPaneMixPanelTracker, isEmailOrChat, toggleDisplayOnMaximize, widgetElement } from "@/app/utils/Utils";
import { UTILS_DYNAMIC } from "@/app/utils/UtilsDynamic";
import { t } from "@/mixins/i18nMixin";
import { STORE } from "@/store";
import "@agentx/agentx-mfe-wc-based";
import { SERVICE } from "@agentx/agentx-services";
import wxmLogo from "@img/wxm.svg";
import { Router } from "@uuip/unified-ui-platform";
import "@uuip/unified-ui-platform-common-components";
import { unsafeCSS } from "lit-element";
import { html, nothing } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { ifDefined } from "lit-html/directives/if-defined";
import { reaction } from "mobx";
import style from "./PanelTwoBlock.scss";
const SCREEN_POP_TITLE = "app:panelTwo.screenPopTitle";
const CONTACT_HISTORY_TITLE = "app:panelTwo.contactHistoryTitle";
const IVR_TRANSCRIPT_TITLE = "app:panelTwo.ivrTranscriptTitle";
const WXM_TITLE = "app:panelTwo.wxmTitle";
const getMaximizeBlockLocalization = (title = "") => {
    const titleString = title ? ` ${title}` : "";
    return {
        maximizeText: `${t("app:maximizeAreaBlock:maximize")}${titleString}`,
        minimizeText: `${t("app:maximizeAreaBlock:minimize")}${titleString}`
    };
};
const mixpanelTracker = (tabName) => {
    switch (tabName) {
        case widgetElement.CONTACT_HISTORY:
            auxiliaryPaneMixPanelTracker(widgetElement.CONTACT_HISTORY);
            SERVICE.telemetry.setValueInCache(SERVICE.telemetry.MIX_CACHE_KEYS.AUXILIARY_PANEL_TAB_NAME, widgetElement.CONTACT_HISTORY);
            SERVICE.telemetry.setValueInCache(SERVICE.telemetry.MIX_CACHE_KEYS.CONTACT_HISTORY_START_TIME, new Date().getTime());
            break;
        case widgetElement.WXM_JOURNEY:
            auxiliaryPaneMixPanelTracker(widgetElement.WXM_JOURNEY);
            SERVICE.telemetry.setValueInCache(SERVICE.telemetry.MIX_CACHE_KEYS.AUXILIARY_PANEL_TAB_NAME, widgetElement.WXM_JOURNEY);
            SERVICE.telemetry.setValueInCache(SERVICE.telemetry.MIX_CACHE_KEYS.WXM_JOURNEY_START_TIME, new Date().getTime());
            break;
    }
};
const ContactHistoryBlock = (isDefinedDynamicPanel, taskSelected) => {
    var _a;
    //mixpanel tracking for contact history
    mixpanelTracker(widgetElement.CONTACT_HISTORY);
    const urlEncodedANI = taskSelected.mediaChannel === MEDIA_TYPES.APPLE_BUSINESS_CHAT && !STORE.app.featureFlags.isTahoeEnabled
        ? encodeURIComponent(taskSelected.ani)
        : taskSelected.ani;
    const interactionId = STORE.agentContact.getInteractionId();
    const dnis = taskSelected.dnis;
    return html `
    <uuip-wc-widget-wrapper
      slot=${ifDefined(isDefinedDynamicPanel ? "CONTACT_HISTORY" : undefined)}
      title="${t(CONTACT_HISTORY_TITLE)}"
      .maximizeToAreaName="${STORE.app.defaultMaximizeAreaName}"
      .maximizeBlockLocalization="${getMaximizeBlockLocalization(t(CONTACT_HISTORY_TITLE))}"
    >
      <agentx-react-contact-history
        .details="${{
        agentId: STORE.agent.agentId,
        interactionId,
        ani: urlEncodedANI,
        dnis,
        contactDirection: taskSelected.contactDirection,
        tahoeEnabled: STORE.app.featureFlags.isTahoeEnabled
    }}"
        orgId="${taskSelected.orgId}"
        ?disabletranscripts=${(_a = STORE.agentContact.getMediaMgrFromTask()) === null || _a === void 0 ? void 0 : _a.includes("digitalmm")}
        isDarkMode="${STORE.app.darkMode}"
        isProgressiveCampaign1NEnabled="${STORE.app.featureFlags.isProgressiveCampaign1NEnabled}"
      ></agentx-react-contact-history>
    </uuip-wc-widget-wrapper>
  `;
};
const ScreenPopBlock = (isDefinedDynamicPanel) => {
    return STORE.agentContact.taskSelected
        ? html `
        <uuip-wc-widget-wrapper
          slot=${ifDefined(isDefinedDynamicPanel ? "SCREEN_POP" : undefined)}
          title="${t(SCREEN_POP_TITLE)}"
          .maximizeToAreaName="${STORE.app.defaultMaximizeAreaName}"
          .maximizeBlockLocalization="${getMaximizeBlockLocalization()}"
        >
          <agentx-wc-screen-pop .screenPopUrl="${STORE.session.screenpop.screenPopSelector}"></agentx-wc-screen-pop>
        </uuip-wc-widget-wrapper>
      `
        : nothing;
};
const IvrTranscriptBlock = (isDefinedDynamicPanel) => {
    return STORE.agentContact.taskSelected && STORE.agentContact.isDefinedIvrTranscript
        ? html `
        <uuip-wc-widget-wrapper
          slot=${ifDefined(isDefinedDynamicPanel ? "IVR_TRANSCRIPT" : undefined)}
          title="${t(IVR_TRANSCRIPT_TITLE)}"
          .maximizeToAreaName="${STORE.app.defaultMaximizeAreaName}"
          .maximizeBlockLocalization="${getMaximizeBlockLocalization()}"
        >
          <agentx-wc-ivr-transcripts
            orgId=${STORE.agentContact.taskSelected.orgId}
            interactionId=${STORE.agentContact.getInteractionId()}
            .ivrConversations=${STORE.session.ivrTransctipt.ivrTranscriptMap.get(STORE.agentContact.getInteractionId() || "")}
            @save-ivr-conversation="${(e) => STORE.session.ivrTransctipt.saveTranscript(e.detail.interactionId, e.detail.ivrConversation)}"
          ></agentx-wc-ivr-transcripts>
        </uuip-wc-widget-wrapper>
      `
        : nothing;
};
export const isNotHome = () => {
    return STORE.routing.currentRouteName !== RouteName.TASK;
};
/** Below code is exported to cover it from unit tests as it is being called internally from dynamic widget */
export const visibilityCheck = (tag, upd) => {
    var _a, _b, _c, _d, _e;
    switch (tag) {
        case "SCREEN_POP":
            return {
                visible: STORE.session.screenpop.screenPopSelector.length > 0,
                dispose: reaction(() => STORE.session.screenpop.screenPopSelector, (x) => upd(x.length > 0))
            };
        case "WXM_JOURNEY":
            mixpanelTracker(widgetElement.WXM_JOURNEY);
            return {
                visible: STORE.app.wxmEnabled,
                dispose: reaction(() => STORE.app.wxmEnabled, (x) => upd(x))
            };
        case "IVR_TRANSCRIPT":
            return {
                visible: !!STORE.agentContact.isDefinedIvrTranscript,
                dispose: reaction(() => STORE.agentContact.isDefinedIvrTranscript, (x) => upd(!!x))
            };
        // CallGuide is a third-part widget which requires conditional visibility.
        // In future when dynamic visibility is implemented below condition needs to be updated.
        case "CALL_GUIDE":
            return {
                visible: (((_a = STORE.agentContact.getCallGuideProps) === null || _a === void 0 ? void 0 : _a.outboundType) === "CAMPAIGN" &&
                    STORE.agent.agentId === ((_b = STORE.agentContact.getCallGuideProps) === null || _b === void 0 ? void 0 : _b.owner)) ||
                    (STORE.app.featureFlags.isProgressiveCampaignEnabled &&
                        (((_c = STORE.agentContact.getCallGuideProps) === null || _c === void 0 ? void 0 : _c.outboundType) === "PROGRESSIVE_CAMPAIGN" ||
                            ((_d = STORE.agentContact.getCallGuideProps) === null || _d === void 0 ? void 0 : _d.outboundType) === "CAMPAIGN_PREDICTIVE_OUTDIAL_CUSTOMER" ||
                            ((_e = STORE.agentContact.getCallGuideProps) === null || _e === void 0 ? void 0 : _e.outboundType) === "CAMPAIGN_OUTDIAL_CUSTOMER")),
                dispose: reaction(() => { var _a; return (_a = STORE.agentContact.getCallGuideProps) === null || _a === void 0 ? void 0 : _a.outboundType; }, (x) => upd(x === "CAMPAIGN"))
            };
        default: {
            return { visible: true };
        }
    }
};
export const updatePanelAttribute = (panel) => {
    if ((panel === null || panel === void 0 ? void 0 : panel.attributes) && !(panel === null || panel === void 0 ? void 0 : panel.attributes["persist-selection"])) {
        STORE.dynamic.updatePanelPersistentAttributes();
        return STORE.dynamic.panel;
    }
    return panel;
};
const getTabHeader = (label, icon) => {
    return html `
    <span>
      <style>
        .icon-label {
          display: flex;
        }
        .icon-label .text-tab {
          align-self: center;
        }
        .tab-tooltip {
          position: unset;
        }
      </style>
      <md-tooltip placement="top" class="tab-tooltip" message=${label}>
        <div class="icon-label">
          <md-icon name="${icon}" iconSet="momentumDesign" size="16"></md-icon>
          <span class="text-tab">${label}</span>
        </div>
      </md-tooltip>
    </span>
  `;
};
const renderPanelTwoBlock = (isDefinedDynamicPanel, selectedTask) => {
    if (isDefinedDynamicPanel) {
        return html `
      <uuip-dynamic-widget
        .options="${updatePanelAttribute(STORE.dynamic.panel)}"
        .visibilityCheck="${visibilityCheck}"
        .dataProvider=${UTILS_DYNAMIC.storeDataProvider}
      >
        <!-- IVR_TRANSCRIPT_TAB -->
        <span class="tab-header-content" aria-label=${t(IVR_TRANSCRIPT_TITLE)} slot="IVR_TRANSCRIPT_TAB">
          ${getTabHeader(t(IVR_TRANSCRIPT_TITLE), "transcript-bold")}
        </span>
        <!-- End -->

        ${IvrTranscriptBlock(isDefinedDynamicPanel)}

        <!-- CONTACT_HISTORY_TAB -->
        <span
          id=${CONTACT_HISTORY_TAB_TITLE}
          class="tab-header-content"
          aria-label=${t(CONTACT_HISTORY_TITLE)}
          slot="CONTACT_HISTORY_TAB"
        >
          ${getTabHeader(t(CONTACT_HISTORY_TITLE), "recents-bold")}
        </span>
        <!-- End -->

        ${ContactHistoryBlock(isDefinedDynamicPanel, selectedTask)}

        <!-- SCREEN_POP_TAB -->
        <span class="tab-header-content" aria-label=${t(SCREEN_POP_TITLE)} slot="SCREEN_POP_TAB">
          ${getTabHeader(t(SCREEN_POP_TITLE), "pop-out-bold")}
        </span>
        <!-- End -->

        <!-- SCREEN_POP_TAB -->
        <span class="tab-header-content" aria-label=${t(WXM_TITLE)} slot="WXM_JOURNEY_TAB">
          <md-tooltip placement="top" message=${t(WXM_TITLE)}>
            <img src=${wxmLogo} alt=${t(WXM_TITLE)} />
            <span class="text-tab"> ${t(WXM_TITLE)} </span>
          </md-tooltip>
        </span>
        <!-- End -->

        ${ScreenPopBlock(isDefinedDynamicPanel)}
      </uuip-dynamic-widget>
    `;
    }
    else {
        return html `
      <md-tabs class="widget-tabs" show-more-actions="${true}" more-actions-label="${t("app:panelTwo.moreActions")}">
        ${STORE.agentContact.isDefinedIvrTranscript
            ? html `
              <md-tab slot="tab" class="widget-pane-tab">
                ${getTabHeader(t(IVR_TRANSCRIPT_TITLE), "transcript-bold")}
              </md-tab>
              <md-tab-panel slot="panel" class="widget-pane">
                ${IvrTranscriptBlock(isDefinedDynamicPanel)}
              </md-tab-panel>
            `
            : nothing}
        <md-tab slot="tab" class="widget-pane-tab"> ${getTabHeader(t(CONTACT_HISTORY_TITLE), "recents-bold")} </md-tab>
        <md-tab-panel slot="panel" class="widget-pane">
          ${ContactHistoryBlock(isDefinedDynamicPanel, selectedTask)}
        </md-tab-panel>
        ${STORE.session.screenpop.screenPopSelector
            ? html `
              <md-tab slot="tab" class="widget-pane-tab"> ${getTabHeader(t(SCREEN_POP_TITLE), "pop-out-bold")} </md-tab>
              <md-tab-panel slot="panel"> ${ScreenPopBlock(isDefinedDynamicPanel)} </md-tab-panel>
            `
            : nothing}
        <agentx-wc-more-actions-widget slot="settings" ?isResetTabOrderEnabled=${true}></agentx-wc-more-actions-widget>
      </md-tabs>
    `;
    }
};
const shouldHidePanel = () => {
    // Hide panel in all pages except task and on page not found
    return STORE.routing.currentRouteName !== RouteName.TASKPANEL || !STORE.agentContact.taskSelected;
};
const PanelTwoBlock = () => {
    var _a;
    const isDefinedDynamicPanel = STORE.dynamic.panel && Object.keys(STORE.dynamic.panel).length > 0 ? true : false;
    let isMaximizedEventAttached = false;
    const isMaximizedFunction = () => {
        if (!isMaximizedEventAttached) {
            window.addEventListener(TOGGLE_MAXIMIZED_EVENT, (e) => {
                const customEvent = e;
                toggleDisplayOnMaximize(customEvent.detail.isMaximized, CONTACT_HISTORY_TAB_TITLE, true, true);
                toggleDisplayOnMaximize(customEvent.detail.isMaximized, CONTACT_HISTORY_TAB_TITLE, true, false);
                isMaximizedEventAttached = true;
            });
        }
    };
    if (isEmailOrChat((_a = STORE.agentContact) === null || _a === void 0 ? void 0 : _a.taskSelected)) {
        isMaximizedFunction();
    }
    return new Router.RouterView.Block({
        template: html `
      <div
        id="panel-two"
        class="panel-two ${classMap({
            "non-telephony-task-layout": STORE.agentContact.isNotTelephoneOrMidcall && isNotHome(),
            "hide-panel-two": shouldHidePanel(),
            "panel-two-home": !isNotHome()
        })}"
      >
        ${STORE.agentContact.isNotTelephoneOrMidcall && STORE.app.showAuxPanelAsOverlay && isNotHome()
            ? html `
              <agentx-wc-collapse-area
                .direction="${"right"}"
                .collapsed="${STORE.app.auxilliaryPanelCollapsed}"
                .forceTogglerVisibilityOnMouseOverSelectors="${`agentx-wc-navigation`}"
                @toggle="${() => STORE.app.toggleAuxilliaryPanelCollapse()}"
              >
                <div class="agentx-aux-panel-area-width-holder">
                  ${STORE.agentContact.taskSelected &&
                renderPanelTwoBlock(isDefinedDynamicPanel, STORE.agentContact.taskSelected)}
                </div>
              </agentx-wc-collapse-area>
            `
            : STORE.agentContact.taskSelected &&
                renderPanelTwoBlock(isDefinedDynamicPanel, STORE.agentContact.taskSelected)}
      </div>
    `,
        styles: [
            style,
            unsafeCSS `
        .panel-two {
          grid-area: panel-two; /*grid*/
        }
      `
        ]
    });
};
export default PanelTwoBlock;
