import EmptyBlock from "@/app/routing/shared-blocks/EmptyBlock";
import { BaseView } from "@/app/routing/views/_base-view/BaseView";
import { Router } from "@uuip/unified-ui-platform";
import style from "./HomeView.scss";
const displayRespectiveBlock = () => {
    return { empty_view: EmptyBlock() };
};
export const HomeView = () => {
    return new Router.RouterView.BlocksMap(BaseView(), {
        blocks: Object.assign({}, displayRespectiveBlock()),
        styles: style
    });
};
