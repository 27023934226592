import { isWebCall } from "@/app/utils/Utils";
import { STORE } from "@/store";
import { Router } from "@uuip/unified-ui-platform";
import { css } from "lit-element";
import { html } from "lit-html";
export const checkWebCall = (mediaType) => {
    const interaction = {
        mediaType
    };
    return STORE.app.featureFlags.isWebrtcRingingEnabled && isWebCall(interaction);
};
export const handleFireNotificationPopoverBlock = (e) => {
    if (STORE.app.taskAreaCollapsed) {
        STORE.browserNotifications.fireNewOfferNotification(e.detail);
    }
    // STORE.browserNotifications.playSound :
    // 1. if the call is a web call and the feature flag CX-13578-webrtc-ringtone is enabled -> plays (/sounds/ringtone.mp3)
    // 2. if the call is not a web call -> plays default chime sound (/sounds/new_contact_sound.mp3)
    // 3. if its any other media type -> plays default chime sound (/sounds/new_contact_sound.mp3)
    STORE.browserNotifications.playSound(checkWebCall(e.detail.type));
};
const PopoverBlock = () => {
    return new Router.RouterView.Block({
        template: html `
      <!-- EMPTY -->
      <agentx-react-interaction-popover
        class="popover ${STORE.app.taskAreaCollapsed ? "show" : "hide"}"
        agentId="${STORE.agent.agentId}"
        .taskMap="${STORE.agentContact.taskMapForPopover}"
        .agentconfig="${{
            isendcallenabled: STORE.agent.isEndCallEnabled,
            isAgentHandlingTask: STORE.agentContact.isAgentHandlingMoreThanOneTask(),
            isProgressive11SpecialModeEnabled: STORE.app.featureFlags.isProgressive11SpecialModeEnabled
        }}"
        .popoverConfig="${{
            isTaskList: !STORE.app.taskAreaCollapsed,
            isDarkMode: STORE.app.darkMode,
            isDesktopConsultToEntryPointDnEnabled: STORE.app.featureFlags.isDesktopConsultToEntryPointDnEnabled,
            isProgressiveCampaignEnabled: STORE.app.featureFlags.isProgressiveCampaignEnabled,
            isProgressiveCampaign1NEnabled: STORE.app.featureFlags.isProgressiveCampaign1NEnabled,
            isSuppressWebexCallingNotificationEnabled: STORE.app.featureFlags.isSuppressWebexCallingNotificationEnabled &&
                localStorage.getItem("desktop-hide-webex-calling-notifications-setting") === "true",
            deviceType: STORE.agent.deviceType,
            isDesktopCpdViewEnabled: STORE.app.featureFlags.isDesktopCpdViewEnabled,
            isActivePopover: STORE.app.taskAreaCollapsed
        }}"
        @ax-fire-notification="${handleFireNotificationPopoverBlock}"
        @ax-new-popover-count="${(e) => {
            STORE.agentContact.updatePopoverCount(e.detail);
        }}"
      ></agentx-react-interaction-popover>
    `,
        styles: css `
      .popover {
        position: absolute;
      }
    `
    });
};
export default PopoverBlock;
