import { SERVICE } from "@agentx/agentx-services";
import { toJS } from "mobx";
import { agentContact } from "../store-agent-contact";
export var AgentContactSharedActions;
(function (AgentContactSharedActions) {
    AgentContactSharedActions.Init = () => {
        // Bind agent contact store actions channels
        // AddCustomContact
        {
            const d = SERVICE.actionsChannels.createDestination("addCustomTask");
            /* istanbul ignore next */
            d.addListener((p) => agentContact.updateTask(...p.args));
        }
        // GetTaskMap
        {
            const d = SERVICE.actionsChannels.createDestination("getTaskMap/Req");
            const s = SERVICE.actionsChannels.createSource("getTaskMap/Res");
            /* istanbul ignore next */
            d.addListener(({ args: [req_uuid] }) => s.send(
            // Copying to prevent mutate in other place
            Array.from(agentContact.taskMap).reduce((acc, [taskId, task]) => {
                acc.set(taskId, toJS(task));
                return acc;
            }, new Map()), req_uuid));
        }
        // GetMediaTypeQueue
        {
            const d = SERVICE.actionsChannels.createDestination("getMediaTypeQueue/Req");
            const s = SERVICE.actionsChannels.createSource("getMediaTypeQueue/Res");
            /* istanbul ignore next */
            d.addListener(({ args: [media_type, req_uuid] }) => s.send(
            // Copying to prevent mutate in other place
            Array.from(agentContact.taskMap).reduce((acc, [taskId, task]) => {
                if (task.interaction.mediaType === media_type) {
                    acc.set(taskId, toJS(task));
                }
                return acc;
            }, new Map()), req_uuid));
        }
    };
})(AgentContactSharedActions || (AgentContactSharedActions = {}));
