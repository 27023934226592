var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ALERT_BANNER_DETAILS, PRESENCE_MEETING_TYPE, PRESENCE_REQUEST_EVENTS, PRESENCE_STAT, PRESENCE_STATUS, PRESENCE_STATUS_CALL_EVENTS, REVOKE_PRESENCE_TOKEN_FAILURE } from "@/app/components/constants";
import { handlePresenceSync } from "@/app/integration/microsoft";
import { handleWebexPresenceSync } from "@/app/integration/webex";
import { t } from "@/mixins/i18nMixin";
import { STORE } from "@/store";
import { MediaType } from "@/store/store-agent-contact";
import { SERVICE, getTrackingIdFromErrorObject } from "@agentx/agentx-services";
import { showBanner } from "../AlertBannerUtils";
import { dispatchCustomEvent, logger } from "../Utils";
import { StateSyncManager } from "./StateSyncManager";
// return idleCode from msft config map.
export const getIdleCodeForMsft = (syncStatus) => {
    var _a;
    const idleCodeMap = (_a = STORE.app.microsoftOrgConfig) === null || _a === void 0 ? void 0 : _a.idleCodes;
    if (idleCodeMap && Object.keys(idleCodeMap).length > 0) {
        switch (syncStatus) {
            case PRESENCE_STATUS.call:
            case PRESENCE_STATUS.meeting:
                return idleCodeMap.onACallId;
            case PRESENCE_STATUS.dnd:
                return idleCodeMap.doNotDisturbId;
            case PRESENCE_STATUS.presenting:
                return idleCodeMap.presentingId;
            case PRESENCE_STATUS.active:
                return SERVICE.constants.AgentState.AvailableAuxCodeId; // idlecode for available state
            default:
                return "";
        }
    }
    return undefined;
};
// return idleCode from webex config map.
export const getIdleCodeForWebex = (syncStatus, meetingType) => {
    var _a;
    const idleCodeMap = (_a = STORE.app.webexOrgConfig) === null || _a === void 0 ? void 0 : _a.idleCodes;
    if (idleCodeMap && Object.keys(idleCodeMap).length > 0) {
        const status = {
            [`${PRESENCE_STATUS.call}`]: idleCodeMap.onACallId,
            [`${PRESENCE_STATUS.meeting}`]: idleCodeMap.inAMeetingId,
            [`${PRESENCE_STATUS.dnd}`]: idleCodeMap.doNotDisturbId,
            [`${PRESENCE_STATUS.presenting}`]: idleCodeMap.presentingId,
            [`${PRESENCE_STATUS.busy}`]: idleCodeMap.busyId,
            [`${PRESENCE_STATUS.outOfOffice}`]: idleCodeMap.outOfOfficeId,
            [`${PRESENCE_STATUS.quietHours}`]: idleCodeMap.quietHoursId,
            [`${PRESENCE_STATUS.active}`]: SERVICE.constants.AgentState.AvailableAuxCodeId // idleCode for available state
        };
        return meetingType === PRESENCE_MEETING_TYPE.calendarItem && syncStatus === PRESENCE_STATUS.meeting
            ? idleCodeMap.inACalendarMeetingId
            : status[syncStatus];
    }
    return undefined;
};
// return idleCode from msft/webex config map.
export const getIdleCode = (syncStatus, meetingType) => {
    var _a, _b;
    if (((_a = STORE.app.microsoftOrgConfig) === null || _a === void 0 ? void 0 : _a.active) && STORE.app.microsoftOrgConfig.stateSynchronization) {
        return getIdleCodeForMsft(syncStatus);
    }
    else if ((_b = STORE.app.webexOrgConfig) === null || _b === void 0 ? void 0 : _b.stateSynchronization) {
        return getIdleCodeForWebex(syncStatus, meetingType);
    }
    return undefined;
};
export const displayBanner = (newState) => {
    sessionStorage.setItem(ALERT_BANNER_DETAILS, JSON.stringify({ reason: SERVICE.constants.WebexConstants.AX_PRESENCE_STATE_UPDATE }));
    showBanner({
        detail: {
            bannerText: `${t("app:presenceSyncStateManagement.axAgentStateChanged")} <b>${newState}</b> ${t("app:presenceSyncStateManagement.axAgentSync")}`,
            bannerType: SERVICE.constants.WebexConstants.AX_PRESENCE_STATE_UPDATE
        }
    });
};
export const registerStateSyncManger = () => {
    SERVICE.webex.registerPresenceSync(STORE.agent.agentId);
    const stateSyncManager = new StateSyncManager();
    stateSyncManager.register();
};
export const handlePresenceCallState = (e) => {
    if (STORE.agent.collaboration.stateSynchronization && e.interaction.mediaType === MediaType.Telephony) {
        dispatchCustomEvent(window, PRESENCE_STATUS_CALL_EVENTS.CALL_CREATE, e.interactionId);
    }
};
export const handlePresenceStateReset = (interactionId) => {
    if (STORE.agent.collaboration.stateSynchronization) {
        dispatchCustomEvent(window, PRESENCE_STATUS_CALL_EVENTS.CALL_DELETE, interactionId);
    }
};
export const formServiceStatus = (status, service) => {
    return `${service}_${status}`;
};
export const setPresenceServiceStatus = (status, service, trackingID) => {
    if (STORE.agent.presenceServiceStatus.serviceStatus === "mercury_failure" && service !== "mercury") {
        return;
    }
    else {
        const trackingId = trackingID !== null && trackingID !== void 0 ? trackingID : "";
        STORE.agent.updatePresenceServiceStatus(formServiceStatus(status, service), trackingId);
    }
};
export const retrySync = () => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    setPresenceServiceStatus(PRESENCE_STAT.RETRYING, PRESENCE_STAT.SYNC);
    if (((_a = STORE.app.microsoftOrgConfig) === null || _a === void 0 ? void 0 : _a.active) && STORE.app.microsoftOrgConfig.stateSynchronization) {
        logger.error(`[Microsoft PresenceStateSync]: Retrying registration to presence sync`);
        yield handlePresenceSync();
    }
    else if ((_b = STORE.app.webexOrgConfig) === null || _b === void 0 ? void 0 : _b.stateSynchronization) {
        logger.error(`[Webex PresenceStateSync]: Retrying registration to presence sync`);
        handleWebexPresenceSync();
    }
});
export const handlePresenceFailCatch = (error) => {
    setPresenceServiceStatus(PRESENCE_STAT.FAILURE, PRESENCE_STAT.SYNC, getTrackingIdFromErrorObject(error));
    STORE.generalNotifications.triggerPresenceNotification(formServiceStatus(PRESENCE_STAT.FAILURE, PRESENCE_STAT.SYNC), retrySync);
};
export const mercuryRetryUpdate = () => {
    setPresenceServiceStatus(PRESENCE_STAT.RETRYING, PRESENCE_STAT.MERCURY);
    STORE.generalNotifications.triggerPresenceNotification(formServiceStatus(PRESENCE_STAT.RETRYING, PRESENCE_STAT.MERCURY));
};
export const mercurySuccessUpdate = () => {
    setPresenceServiceStatus(PRESENCE_STAT.SUCCESSFUL, PRESENCE_STAT.MERCURY);
    STORE.generalNotifications.triggerPresenceNotification(formServiceStatus(PRESENCE_STAT.SUCCESSFUL, PRESENCE_STAT.MERCURY));
};
export const mercuryFailureUpdate = (e) => {
    setPresenceServiceStatus(PRESENCE_STAT.FAILURE, PRESENCE_STAT.MERCURY, e.detail.trackingId);
    STORE.generalNotifications.triggerPresenceNotification(formServiceStatus(PRESENCE_STAT.FAILURE, PRESENCE_STAT.MERCURY));
    const errorObj = {
        mode: "mercury",
        errorDetails: JSON.stringify(e)
    };
    window.dispatchEvent(new CustomEvent("web-calling-device-disconnected", {
        detail: { value: { errorObj } },
        bubbles: true,
        composed: true
    }));
};
export const presenceRequestListeners = () => {
    window.addEventListener(REVOKE_PRESENCE_TOKEN_FAILURE, handlePresenceFailCatch);
    window.addEventListener(PRESENCE_REQUEST_EVENTS.AX_PRESENCE_REQUEST_RETRY, mercuryRetryUpdate);
    window.addEventListener(PRESENCE_REQUEST_EVENTS.AX_PRESENCE_REQUEST_SUCCESS, mercurySuccessUpdate);
    window.addEventListener(PRESENCE_REQUEST_EVENTS.AX_PRESENCE_REQUEST_FAILURE, (e) => {
        mercuryFailureUpdate(e);
    });
};
export const removeMercuryListeners = () => {
    window.removeEventListener(PRESENCE_REQUEST_EVENTS.AX_PRESENCE_REQUEST_RETRY, mercuryRetryUpdate);
    window.removeEventListener(PRESENCE_REQUEST_EVENTS.AX_PRESENCE_REQUEST_SUCCESS, mercurySuccessUpdate);
    window.removeEventListener(PRESENCE_REQUEST_EVENTS.AX_PRESENCE_REQUEST_FAILURE, (e) => {
        mercuryFailureUpdate(e);
    });
    window.removeEventListener(REVOKE_PRESENCE_TOKEN_FAILURE, handlePresenceFailCatch);
};
