import { AGENT, BARGE_IN_MONITORING_STATE, CUSTOMER, SUPERVISOR } from "@/app/components/constants";
import { isSecondaryEpDnAgent, logger } from "@/app/utils/Utils";
import { STORE } from "@/store";
import { EPDN, STATE_CONFERENCE, STATE_CONFERENCING, STATE_CONNECTED, STATE_CONSULT, STATE_CONSULTING, STATE_CONSULT_COMPLETED, STATE_CONSULT_INITIATED, STATE_WRAPUP } from "./shared-actions/constants";
import { MediaType } from "./store-agent-contact";
const MAX_AGENT_IN_MPC = 7;
const DN = "dn";
const filterParticipants = (participants) => {
    const result = {};
    participants.forEach((participantDetails) => {
        var _a, _b, _c, _d;
        if (participantDetails.pType !== CUSTOMER &&
            (participantDetails.pType !== SUPERVISOR ||
                ((_a = participantDetails.monitoringState) === null || _a === void 0 ? void 0 : _a.type) === BARGE_IN_MONITORING_STATE) &&
            !participantDetails.hasLeft) {
            result[participantDetails.id] = {
                agentId: participantDetails.id,
                agentName: (_b = participantDetails.name) !== null && _b !== void 0 ? _b : "",
                pType: participantDetails.pType,
                joinTimestamp: (_c = participantDetails.joinTimestamp) !== null && _c !== void 0 ? _c : null,
                agentPhoneNumber: (_d = participantDetails.dn) !== null && _d !== void 0 ? _d : ""
            };
        }
    });
    return result;
};
const getConsultCallParticipants = (mediaObject) => {
    for (const key in mediaObject) {
        const mediaItem = mediaObject[key];
        if ((mediaItem === null || mediaItem === void 0 ? void 0 : mediaItem.mType) === STATE_CONSULT) {
            return mediaItem.participants;
        }
    }
    return [];
};
export const getIsConsultedAgentEPDN = (data) => {
    var _a, _b, _c;
    if (data) {
        const mediaObject = (_a = data === null || data === void 0 ? void 0 : data.interaction) === null || _a === void 0 ? void 0 : _a.media;
        const participantsInConsultCall = getConsultCallParticipants(mediaObject);
        const allParticipantsInInteraction = (_b = data === null || data === void 0 ? void 0 : data.interaction) === null || _b === void 0 ? void 0 : _b.participants;
        for (const consultParticipant of participantsInConsultCall) {
            if (((_c = allParticipantsInInteraction[consultParticipant]) === null || _c === void 0 ? void 0 : _c.pType) === EPDN) {
                return true;
            }
        }
    }
    return false;
};
export const getPrimaryMediaParticipants = (data) => {
    var _a, _b, _c, _d;
    // To get the participants in the call with customer.
    // Does not contain any participant who is in consult call or monitoring call.
    const mediaObject = (_a = data === null || data === void 0 ? void 0 : data.interaction) === null || _a === void 0 ? void 0 : _a.media;
    const mediaMainCall = mediaObject[data.interactionId];
    const participantsInMainCall = mediaMainCall === null || mediaMainCall === void 0 ? void 0 : mediaMainCall.participants;
    const participantsInConsultCall = getConsultCallParticipants(mediaObject);
    const allParticipantsInInteraction = (_b = data === null || data === void 0 ? void 0 : data.interaction) === null || _b === void 0 ? void 0 : _b.participants;
    let consultingAgent = "";
    let isConsultedAgentEPDN = false;
    try {
        if (STORE.app.featureFlags.isAllowConfTransferToEpDnEnabled) {
            for (const consultParticipant of participantsInConsultCall) {
                if (participantsInMainCall === null || participantsInMainCall === void 0 ? void 0 : participantsInMainCall.includes(consultParticipant)) {
                    consultingAgent = consultParticipant;
                }
                else if (((_c = allParticipantsInInteraction[consultParticipant]) === null || _c === void 0 ? void 0 : _c.pType) === EPDN) {
                    isConsultedAgentEPDN = true;
                }
            }
            if (((_d = allParticipantsInInteraction[consultingAgent]) === null || _d === void 0 ? void 0 : _d.consultState) === STATE_CONFERENCING && isConsultedAgentEPDN) {
                return filterParticipants(Object.values(allParticipantsInInteraction));
            }
            return filterParticipants(participantsInMainCall === null || participantsInMainCall === void 0 ? void 0 : participantsInMainCall.map((key) => allParticipantsInInteraction[key]));
        }
        else {
            return participantsInMainCall.reduce((result, key) => {
                var _a, _b, _c, _d;
                const participantDetails = allParticipantsInInteraction[key];
                if (participantDetails &&
                    participantDetails.pType !== CUSTOMER &&
                    (participantDetails.pType !== SUPERVISOR ||
                        ((_a = participantDetails.monitoringState) === null || _a === void 0 ? void 0 : _a.type) === BARGE_IN_MONITORING_STATE) &&
                    !participantDetails.hasLeft) {
                    result[key] = {
                        agentId: key,
                        agentName: (_b = participantDetails.name) !== null && _b !== void 0 ? _b : "",
                        pType: participantDetails.pType,
                        joinTimestamp: (_c = participantDetails.joinTimestamp) !== null && _c !== void 0 ? _c : null,
                        agentPhoneNumber: (_d = participantDetails.dn) !== null && _d !== void 0 ? _d : ""
                    };
                }
                return result;
            }, {});
        }
    }
    catch (error) {
        // this is to handle the case when the participantsInMainCall is not getting updated correctly.
        // In such cases, we will return the {}
        logger.error("Error while getting the call participants", error);
        return {};
    }
};
export const getIsConsultInProgress = (data) => {
    const mediaObject = data.interaction.media;
    return Object.values(mediaObject).some((media) => media.mType === "consult");
};
export const getIsConferenceInProgress = (data) => {
    const mediaMainCall = data.interaction.media[data.interactionId];
    const participantsInMainCall = new Set(mediaMainCall === null || mediaMainCall === void 0 ? void 0 : mediaMainCall.participants);
    const participants = data.interaction.participants;
    const agentParticipants = new Set();
    if (participantsInMainCall.size > 0) {
        participantsInMainCall.forEach((participantId) => {
            const participant = participants[participantId];
            if (participant && participant.pType !== CUSTOMER && participant.pType !== SUPERVISOR && !participant.hasLeft) {
                agentParticipants.add(participantId);
            }
        });
    }
    return agentParticipants.size >= 2;
};
export const getIsConsultEnabledForMPC = (data, isMPCEnabled, agentId) => {
    const mainMediaParticipants = getPrimaryMediaParticipants(data);
    const participants = data.interaction.participants;
    if (isMPCEnabled) {
        const agentParticipant = Object.keys(mainMediaParticipants).filter((participantId) => participants[participantId] &&
            participants[participantId].pType !== CUSTOMER &&
            participants[participantId].pType !== SUPERVISOR);
        const isConsultEnabledForSelf = Object.values(participants).some((participantDet) => participantDet.consultState === STATE_CONSULT_COMPLETED)
            ? participants[agentId].consultState === STATE_CONSULT_COMPLETED
            : !getIsConsultInProgress(data);
        return agentParticipant.length < MAX_AGENT_IN_MPC && isConsultEnabledForSelf;
    }
    return false;
};
export const setmTypeForEPDN = (data, mType) => {
    if (isSecondaryEpDnAgent(data)) {
        return "mainCall";
    }
    return mType;
};
export const findMediaResourceId = (data, mType) => {
    for (const key in data) {
        if (data[key].mType === mType) {
            return data[key].mediaResourceId;
        }
    }
    return "";
};
export const isParticipantInMainInteraction = (interactionObj, agentId) => {
    if (!(interactionObj === null || interactionObj === void 0 ? void 0 : interactionObj.media)) {
        return false;
    }
    return Object.values(interactionObj.media).some((mediaObj) => { var _a; return mediaObj.mType === "mainCall" && ((_a = mediaObj.participants) === null || _a === void 0 ? void 0 : _a.includes(agentId)); });
};
export const getConsultMPCState = (data, agentId) => {
    var _a, _b;
    const interaction = data.interaction;
    if (!!data.consultMediaResourceId &&
        !!((_a = interaction.participants[agentId]) === null || _a === void 0 ? void 0 : _a.consultState) &&
        data.interaction.state !== STATE_WRAPUP) {
        // interaction state for all agents when consult is going on
        switch ((_b = interaction.participants[agentId]) === null || _b === void 0 ? void 0 : _b.consultState) {
            case STATE_CONSULT_INITIATED:
                return STATE_CONSULT;
            case STATE_CONSULT_COMPLETED:
                return interaction.state === STATE_CONNECTED ? STATE_CONNECTED : STATE_CONSULT_COMPLETED;
            case STATE_CONFERENCING:
                return STATE_CONFERENCE;
            default:
                return STATE_CONSULTING;
        }
    }
    return interaction === null || interaction === void 0 ? void 0 : interaction.state;
};
const isConsultOnHoldMPC = (data, agentId) => {
    var _a;
    const isInConsultState = [STATE_CONSULT, STATE_CONSULTING].includes(getConsultMPCState(data, agentId));
    const consultMediaResourceId = data.consultMediaResourceId;
    const isConsultHold = consultMediaResourceId && ((_a = data.interaction.media[consultMediaResourceId]) === null || _a === void 0 ? void 0 : _a.isHold);
    return isInConsultState && !isConsultHold;
};
export const findHoldStatus = (data, mType) => {
    var _a;
    const interaction = data.interaction;
    if (!interaction) {
        return false;
    }
    mType = setmTypeForEPDN(interaction, mType); //set mType if agent is secondary EPDN agent
    const mediaId = findMediaResourceId(interaction.media, mType);
    const agentId = STORE.agent.agentId;
    //custom mainCall hold status for agent who initiated the consult.
    if (STORE.app.featureFlags.isWxccMultiPartyConfEnabled &&
        mType === "mainCall" &&
        ((_a = interaction.media[mediaId]) === null || _a === void 0 ? void 0 : _a.participants.includes(agentId)) &&
        (isConsultOnHoldMPC(data, agentId) || [STATE_CONSULT_COMPLETED].includes(getConsultMPCState(data, agentId)))) {
        return true;
    }
    //hold status for agents who are in consulting call(consulting agent | consulted agent)
    return mType === STATE_CONSULT && interaction.media[mediaId]
        ? interaction.media[mediaId].participants.includes(agentId)
            ? interaction.media[mediaId].isHold
            : false
        : (interaction.media[mediaId] && interaction.media[mediaId].isHold) || false; // For all the other agent for main whatever is the status of main call hold
};
export const findHoldTimestamp = (data, mType) => {
    var _a;
    mType = setmTypeForEPDN(data, mType); //set mType if agent is secondary EPDN agent
    const mediaId = findMediaResourceId(data.media, mType);
    if (mediaId && ((_a = data.media[mediaId]) === null || _a === void 0 ? void 0 : _a.holdTimestamp)) {
        return data.media[mediaId].holdTimestamp;
    }
    return null;
};
export const isOwner = (interaction, agentId) => {
    return isSecondaryEpDnAgent(interaction) ? false : interaction.owner === agentId;
};
export const getIsConsultedAgent = (data) => {
    var _a, _b, _c;
    if (STORE.app.featureFlags.isWxccMultiPartyConfEnabled) {
        const agentId = STORE.agent.agentId;
        //filtering consulted interaction for agent who started consult and is consultig
        const consultMedia = data.consultMediaResourceId && ((_a = data.interaction) === null || _a === void 0 ? void 0 : _a.media[data.consultMediaResourceId]);
        const mainMedia = data.interactionId && ((_b = data.interaction) === null || _b === void 0 ? void 0 : _b.media[data.interactionId]);
        if (consultMedia) {
            return consultMedia.participants.includes(agentId) && !(mainMedia === null || mainMedia === void 0 ? void 0 : mainMedia.participants.includes(agentId));
        }
        return false;
    }
    return data.type === "AgentContact"
        ? data.isConsulted
        : data.type === "AgentContactAssigned" ||
            data.type === "AgentConsultConferenced" ||
            data.type === "AgentConsultCreated"
            ? (_c = data.interaction.participants[data.destAgentId]) === null || _c === void 0 ? void 0 : _c.isConsulted
            : false;
};
/* Returns true if customer is a Participant in the Task and not left */
export const isCustomerInParticipants = (participants) => {
    return Object.values(participants).some((participant) => participant.pType === CUSTOMER && !participant.hasLeft);
};
/* Returns true for wrapup assist state which means customer is dropped from on-going conference telephony Task */
export const getIsWrapupAssist = (interaction) => {
    return (STORE.app.featureFlags.isWxccPersistCallEnabled &&
        interaction.mediaType === MediaType.Telephony &&
        interaction.state === STATE_WRAPUP &&
        !isCustomerInParticipants(interaction.participants));
};
export const getIsSecondaryAgentCBT = (interaction, destAgentId) => {
    const participant = interaction === null || interaction === void 0 ? void 0 : interaction.participants[destAgentId];
    return (participant === null || participant === void 0 ? void 0 : participant.pType) === "DN" && (participant === null || participant === void 0 ? void 0 : participant.type) === "Agent";
};
export const getConsultedAgentId = (media, agentId) => {
    let consultParticipants = [];
    let consultedParticipantId = "";
    for (const key in media) {
        if (media[key].mType === STATE_CONSULT) {
            consultParticipants = media[key].participants;
        }
    }
    if (consultParticipants.includes(agentId)) {
        const id = consultParticipants.find((participant) => participant !== agentId);
        consultedParticipantId = id ? id : consultedParticipantId;
    }
    return consultedParticipantId;
};
export const getDestAgentIdForCBT = (interaction, consultingAgent) => {
    const participants = interaction.participants;
    let destAgentIdForCBT = "";
    // first it will check it is not a nonCBT with the below condition
    if (consultingAgent && !participants[consultingAgent]) {
        for (const key in participants) {
            const participant = participants[key];
            if (participant.pType.toLowerCase() === DN && participant.type === AGENT && participant.dn === consultingAgent) {
                destAgentIdForCBT = key;
                break;
            }
        }
    }
    return destAgentIdForCBT;
};
export const isAgentContactInOfferConferenceState = (data, agentId) => {
    var _a, _b;
    if (STORE.app.featureFlags.isWxccMultiPartyConfEnabled && data.interaction.state === "conference") {
        return !((_a = data.interaction.participants[agentId]) === null || _a === void 0 ? void 0 : _a.isWrapUp) && !((_b = data.interaction.participants[agentId]) === null || _b === void 0 ? void 0 : _b.hasJoined);
    }
    return false;
};
