import RouteNotFoundBlock from "@/app/routing/views/_not-found-view/blocks/RouteNotFoundBlock";
import { Router } from "@uuip/unified-ui-platform";
import style from "./NotFoundView.scss";
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const NotFoundView = (_notFoundHref) => {
    return new Router.RouterView.BlocksMap({
        blocks: {
            route_not_found: RouteNotFoundBlock("/", "route-not-found") //redirecting to default route
        },
        styles: style
    });
};
