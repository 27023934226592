import { logger } from "./Utils";
const NOT_RESPONSIVE = "NOT_RESPONSIVE";
const TAB_PANEL = "md-tab-panel";
const EMPTY_STATE_COMP = "empty-responsive-widget";
const EMPTY_LAYOUT = {
    id: "dw-empty-navigation",
    widgets: {
        emptyComp: {
            comp: "empty-responsive-widget"
        }
    },
    layout: {
        areas: [["emptyComp"]],
        size: {
            cols: [1],
            rows: [1]
        }
    }
};
const DEFAULT_PANEL_COMP = {
    comp: "md-tabs",
    attributes: { class: "widget-tabs" }
};
export const isPanelEmpty = (configObj) => {
    return configObj.comp === EMPTY_STATE_COMP;
};
export const getDefaultPanelComp = () => {
    return DEFAULT_PANEL_COMP;
};
const getEmptyLayoutComp = () => {
    return EMPTY_LAYOUT.widgets.emptyComp;
};
const makeWidgetsLayoutAreaInSingleColumn = (componentArea) => {
    const respLayoutArea = [], respRows = [];
    for (const widget of Object.keys(componentArea.widgets)) {
        respLayoutArea.push([widget]);
        respRows.push(1);
    }
    if (respLayoutArea.length > 0) {
        if (componentArea.layout.areas) {
            componentArea.layout.areas = respLayoutArea;
            componentArea.layout.size.rows = respRows;
            componentArea.layout.size.cols.length = 1;
        }
        else if (componentArea.layout.ROOT) {
            componentArea.layout = {};
            componentArea.layout.areas = respLayoutArea;
            componentArea.layout.size = { rows: respRows, cols: [1] };
        }
    }
    return componentArea;
};
const checkForResponsiveness = (component) => {
    return !((component.visibility !== undefined && component.visibility === NOT_RESPONSIVE) ||
        (component.responsive !== undefined && component.responsive === false));
};
const filterResponsiveChildrenComponents = (componentList) => {
    return componentList === null || componentList === void 0 ? void 0 : componentList.filter((component) => {
        return checkForResponsiveness(component);
    });
};
const filterResponsiveWidgetComponents = (widgetsObj) => {
    for (const widget in widgetsObj.widgets) {
        if (checkForResponsiveness(widgetsObj.widgets[widget]) === false) {
            delete widgetsObj.widgets[widget];
        }
    }
};
const getEmptyComponentInTabPanel = (component) => {
    var _a;
    if (((_a = component.children) === null || _a === void 0 ? void 0 : _a.length) === 0 && component.comp === TAB_PANEL) {
        component.children.push(getEmptyLayoutComp());
    }
};
const addComponentToUnresponsiveList = (component, unresponsiveList) => {
    var _a, _b, _c, _d, _e;
    if (((_a = component.children) === null || _a === void 0 ? void 0 : _a.length) === 0 ||
        (((_c = (_b = component.properties) === null || _b === void 0 ? void 0 : _b.area) === null || _c === void 0 ? void 0 : _c.widgets) && Object.keys((_e = (_d = component.properties) === null || _d === void 0 ? void 0 : _d.area) === null || _e === void 0 ? void 0 : _e.widgets).length === 0)) {
        unresponsiveList.push(component);
    }
};
const removeUnresponsiveChildrenComponent = (widgetChildren) => {
    var _a;
    let responsiveWidgetChildren = filterResponsiveChildrenComponents(widgetChildren);
    if ((responsiveWidgetChildren === null || responsiveWidgetChildren === void 0 ? void 0 : responsiveWidgetChildren.length) > 0) {
        const unresponsiveComponentList = [];
        for (const component of responsiveWidgetChildren) {
            if (component.children && component.children.length > 0) {
                component.children = removeUnresponsiveChildrenComponent(component.children);
                getEmptyComponentInTabPanel(component);
                addComponentToUnresponsiveList(component, unresponsiveComponentList);
            }
            if ((_a = component === null || component === void 0 ? void 0 : component.properties) === null || _a === void 0 ? void 0 : _a.area) {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                removeUnresponsiveWidgetComponent(component.properties.area);
                addComponentToUnresponsiveList(component, unresponsiveComponentList);
            }
        }
        responsiveWidgetChildren = responsiveWidgetChildren.filter((component) => {
            return unresponsiveComponentList.indexOf(component) === -1;
        });
        return responsiveWidgetChildren;
    }
    else {
        return responsiveWidgetChildren;
    }
};
const removeUnresponsiveWidgetComponent = (navPageObj) => {
    var _a, _b, _c, _d, _e, _f, _g;
    filterResponsiveWidgetComponents(navPageObj);
    if (navPageObj.widgets) {
        for (const widget in navPageObj.widgets) {
            const widgetChildren = (_a = navPageObj.widgets[widget]) === null || _a === void 0 ? void 0 : _a.children;
            if (widgetChildren && widgetChildren.length > 0) {
                navPageObj.widgets[widget].children = removeUnresponsiveChildrenComponent(navPageObj.widgets[widget].children);
                if (!navPageObj.widgets[widget].children.length) {
                    delete navPageObj.widgets[widget];
                }
            }
            const componentAreaWidget = (_d = (_c = (_b = navPageObj.widgets[widget]) === null || _b === void 0 ? void 0 : _b.properties) === null || _c === void 0 ? void 0 : _c.area) === null || _d === void 0 ? void 0 : _d.widgets;
            if (componentAreaWidget) {
                removeUnresponsiveWidgetComponent(navPageObj.widgets[widget].properties.area);
                if (Object.keys((_g = (_f = (_e = navPageObj.widgets[widget]) === null || _e === void 0 ? void 0 : _e.properties) === null || _f === void 0 ? void 0 : _f.area) === null || _g === void 0 ? void 0 : _g.widgets).length === 0) {
                    delete navPageObj.widgets[widget];
                }
            }
        }
    }
    return makeWidgetsLayoutAreaInSingleColumn(navPageObj);
};
export const updatePanelResponsiveLayout = (configObj) => {
    var _a;
    const responsivePanelObj = JSON.parse(JSON.stringify(configObj));
    responsivePanelObj.children = removeUnresponsiveChildrenComponent(responsivePanelObj.children);
    if (((_a = responsivePanelObj.children) === null || _a === void 0 ? void 0 : _a.length) === 0) {
        return getEmptyLayoutComp();
    }
    return responsivePanelObj;
};
export const updatePersistentResponsiveLayout = (configObjList) => {
    const responsivePersistentObjList = JSON.parse(JSON.stringify(configObjList));
    return removeUnresponsiveChildrenComponent(responsivePersistentObjList);
};
export const updateNavigationResponsiveLayout = (configObj) => {
    const responsiveNavObj = JSON.parse(JSON.stringify(configObj));
    for (const navItem in responsiveNavObj) {
        if (responsiveNavObj[navItem].nav.isResponsive === false) {
            responsiveNavObj[navItem].page = EMPTY_LAYOUT;
            continue;
        }
        try {
            removeUnresponsiveWidgetComponent(responsiveNavObj[navItem].page);
        }
        catch (_a) {
            logger.info("Update nav for responsive layout failed for nav index : ", navItem, "nav page : ", responsiveNavObj[navItem].page);
        }
        if (Object.keys(responsiveNavObj[navItem].page.widgets).length === 0) {
            responsiveNavObj[navItem].page = EMPTY_LAYOUT;
        }
    }
    return responsiveNavObj;
};
export const updateHomepageResponsiveLayout = (homapageObj) => {
    const responsiveNavObj = JSON.parse(JSON.stringify(homapageObj));
    if (responsiveNavObj.nav.isResponsive === false) {
        responsiveNavObj.page = EMPTY_LAYOUT;
    }
    else {
        try {
            removeUnresponsiveWidgetComponent(responsiveNavObj.page);
        }
        catch (_a) {
            logger.info("Update nav for responsive layout failed for homepage : ", responsiveNavObj.nav, "nav page : ", responsiveNavObj.page);
        }
        if (Object.keys(responsiveNavObj.page.widgets).length === 0) {
            responsiveNavObj.page = EMPTY_LAYOUT;
        }
    }
    return responsiveNavObj;
};
