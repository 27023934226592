var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/*************************************************************************
 * This file holds logic for pulling global application configuration only
 * This block should not contain any business logic for your MFE
 * All MFE business logic must live within MFE itself
 *
 * Types of configuration we pull globally for AgentX:
 * - Appllication congif object (TODO: @rost)
 * - PCI Settings (they are shared between Email and Chat)
 * - Feature Flags (they enable/disable functionality throught out the app)
 *************************************************************************/
import { RouteName } from "@/app/routing/routing";
import { t } from "@/mixins/i18nMixin";
import { createLogger, logMiner } from "@/sdk";
import { STORE } from "@/store";
import { MACHINE, SELF, SUPERVISOR } from "@/store/shared-actions/constants";
import { MediaType } from "@/store/store-agent-contact";
import { SERVICE, formRoleNameForTelemetry, getTrackingIdFromErrorObject } from "@agentx/agentx-services";
import { ToasterNotificationItemContent } from "@uuip/unified-ui-platform-common-components";
import { Notifications } from "@uuip/unified-ui-platform-sdk";
import { html } from "lit-element";
import { AGENTX_DEFAULT_FOCUS, ALERT_BANNER_DETAILS, BARGE_IN_MONITORING_STATE, CALLBACK_RETRY_REASON_CODES, COLLAB_TOOL, DIGITAL_OUTBOUND_EVENTS, FC_DESKTOP_VIEW, MEDIA_TYPES, MONITORING_TYPE, OUTBOUND, OUTBOUND_TYPE, SUPERVISOR_AUTO_LOGIN_EVENT, SYSTEM_ROLES, USER_ROLES, WEBEX_MINIMIZE_MODAL, WEBEX_SHOW_MODAL, WEBRTC_SHORTCUT_KEYS, WEB_CALLING_EVENTS, fcDesktopView, reasonCodesList } from "../components/constants";
export var widgetElement;
(function (widgetElement) {
    widgetElement["CONTACT_HISTORY"] = "contactHistory";
    widgetElement["WXM_JOURNEY"] = "wxm_journey";
})(widgetElement || (widgetElement = {}));
export const logger = createLogger("[Util]");
const uuipAuthToken = "uuip-auth-token";
const errorModalOk = "app:errorModal.ok";
const CLUSTER_ID_KEY = "clusterId";
function fetchPciSettings(orgId) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const pciComplianceSettings = yield SERVICE.pci.fetchPciSettings({ orgId });
            logger.info("event=fetchPciSettings | PCI Compliance settings", pciComplianceSettings);
            return pciComplianceSettings;
        }
        catch (error) {
            logger.error(`event=fetchPciSettings | [Tracking ID: ${getTrackingIdFromErrorObject ? getTrackingIdFromErrorObject(error) : error}] PCI Compliance settings fetch error: ${error}`, error);
        }
        return undefined;
    });
}
function fetchPciAllowedAttachmentTypes() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const allowedAttachmentTypes = yield SERVICE.pci.fetchPciAllowedAttachmentTypes({});
            logger.info("event=fetchPciAllowedAttachmentTypes", allowedAttachmentTypes);
            return allowedAttachmentTypes.data.toString();
        }
        catch (error) {
            logger.error(`event=fetchPciAllowedAttachmentTypes | [Tracking ID: ${getTrackingIdFromErrorObject ? getTrackingIdFromErrorObject(error) : error}] fetchPciAllowedAttachmentTypes fetch error: ${error}`, error);
        }
        return "";
    });
}
export function triggerWebRTCErrorNotification() {
    const title = "app:notifications.webRTCErrorNotificationTitle";
    const notificationDataError = {
        type: Notifications.ItemMeta.Type.Warn,
        mode: Notifications.ItemMeta.Mode.AutoDismiss,
        title: t(title),
        data: t("app:notifications.webRTCErrorNotificationMessage")
    };
    STORE.generalNotifications.fireNotificationEvent(notificationDataError);
    STORE.browserNotifications.firebrowserNotification(t(title));
}
function getErrorNotification(title, message, trackingId) {
    return {
        type: Notifications.ItemMeta.Type.Error,
        mode: Notifications.ItemMeta.Mode.AutoDismiss,
        title: t(title),
        data: new ToasterNotificationItemContent.DataController({
            text: message,
            errorDetail: {
                message: "",
                trackingId,
                localization: {
                    errorLessButtonText: t("app:errorDetail.errorLessDetails"),
                    errorMoreButtonText: t("app:errorDetail.errorMoreDetails"),
                    trackingIdTitleText: t("app:errorDetail.ErrorCode"),
                    trackingIdLabelText: t("app:errorModal.trackingIdLabel"),
                    copyText: t("app:errorModal.copy"),
                    copiedText: t("app:errorModal.copied")
                }
            }
        })
    };
}
export function triggerAgentStateErrorNotification(e) {
    const title = "app:notifications.agentStateErrorNotificationTitle";
    const notificationDataError = getErrorNotification(title, t("app:notifications.agentStateErrorNotificationMessage"), e.detail.trackingId);
    STORE.generalNotifications.fireNotificationEvent(notificationDataError);
}
export function triggerAgentSignoutResultNotification(e) {
    let notification;
    const agentName = e.detail.agentName;
    if (e.detail.result) {
        const title = t("app:notifications.agentSignoutSuccessTitle");
        notification = {
            type: Notifications.ItemMeta.Type.Info,
            mode: Notifications.ItemMeta.Mode.AutoDismiss,
            title: t(title),
            data: new ToasterNotificationItemContent.DataController({
                text: agentName + t("app:notifications.agentSignoutSuccessSubtitle")
            })
        };
    }
    else {
        const title = t("app:notifications.agentSignoutErrorTitle");
        const errorMessage = t("app:notifications.agentSignoutErrorSubtitlePrepend") +
            agentName +
            t("app:notifications.agentSignoutErrorSubtitleAppend");
        notification = getErrorNotification(title, errorMessage, e.detail.trackingId);
    }
    STORE.generalNotifications.fireNotificationEvent(notification);
}
export function triggerWebRTCSuccessNotification() {
    const notificationDataSuccess = {
        type: Notifications.ItemMeta.Type.Success,
        mode: Notifications.ItemMeta.Mode.AutoDismiss,
        title: t("app:notifications.webRTCSuccessNotificationTitle"),
        data: ""
    };
    STORE.generalNotifications.fireNotificationEvent(notificationDataSuccess);
    STORE.browserNotifications.firebrowserNotification(t("app:notifications.webRTCSuccessNotificationTitle"));
}
export const triggerBargeInNotification = (supervisorName) => {
    const notificationData = {
        type: Notifications.ItemMeta.Type.Info,
        mode: Notifications.ItemMeta.Mode.AutoDismiss,
        title: "",
        data: `${supervisorName} ${t("app:common.supervisorBargedIn")}`
    };
    STORE.generalNotifications.fireNotificationEvent(notificationData);
    STORE.browserNotifications.firebrowserNotification(`${supervisorName} ${t("app:common.supervisorBargedIn")}`);
};
export const triggerLeaveInteractionNotification = (supervisorName) => {
    const notificationData = {
        type: Notifications.ItemMeta.Type.Info,
        mode: Notifications.ItemMeta.Mode.AutoDismiss,
        title: ``,
        data: `${supervisorName} ${t("app:common.leftInteraction")}`
    };
    STORE.generalNotifications.fireNotificationEvent(notificationData);
    STORE.browserNotifications.firebrowserNotification(`${supervisorName} ${t("app:common.leftInteraction")}`);
};
export const isWebCall = (interaction) => {
    return (STORE.app.featureFlags.isWebRTCEnabled &&
        STORE.agent.deviceType === SERVICE.constants.LoginVoiceOptions.BROWSER &&
        interaction.mediaType === MediaType.Telephony);
};
/**
 * Takes interaction object and checks if it is a digital outbound of out-dial type
 * @param interaction - interaction object
 * Returns a boolean.
 */
export const isDigitalOutbound = (interaction) => {
    return (interaction.contactDirection.type === OUTBOUND &&
        interaction.outboundType &&
        interaction.outboundType === OUTBOUND_TYPE.OUTDIAL &&
        (interaction.mediaChannel === MEDIA_TYPES.EMAIL || interaction.mediaChannel === MEDIA_TYPES.SMS));
};
export const isDigitalOutboundCheck = (direction) => {
    return direction === OUTBOUND;
};
export const isMediaTypeDigitalOutbound = (derivedMediaType) => {
    return (derivedMediaType === MEDIA_TYPES.OUTBOUND_AGENT_INITIATED_EMAIL ||
        derivedMediaType === MEDIA_TYPES.OUTBOUND_AGENT_INITIATED_SMS);
};
export const getMediaChannelForNotification = (mediaChannel, direction) => {
    if (isDigitalOutboundCheck(direction)) {
        if (mediaChannel && mediaChannel === MEDIA_TYPES.EMAIL) {
            return MEDIA_TYPES.OUTBOUND_AGENT_INITIATED_EMAIL;
        }
        if (mediaChannel && mediaChannel === MEDIA_TYPES.SMS) {
            return MEDIA_TYPES.OUTBOUND_AGENT_INITIATED_SMS;
        }
        return mediaChannel;
    }
    return mediaChannel;
};
const displayRonaMessage = (interaction) => {
    if (isWebCall(interaction)) {
        return t("app:rona:webrtcDeviceFailure");
    }
    else {
        return t("app:rona:deviceFailure");
    }
};
const checkRonaReason = (interaction) => {
    if (isWebCall(interaction)) {
        return t("app:rona:networkFailure");
    }
    else {
        return t("app:rona:incorrectDialNumber");
    }
};
export const getErrorMsgForReasonCode = (reasonCode) => {
    if (STORE.app.featureFlags.isDesktopReasonCodeEnabled && reasonCodesList.indexOf(reasonCode) !== -1) {
        const reasonCodeInString = `${reasonCode}`;
        return t(`app:errorCodes.${reasonCodeInString}`);
    }
    return null;
};
export function fetchFeatureFlags() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            return {
                isVideoEnabled: SERVICE.featureflag.isVideoEnabled(),
                isCareAttachmentEnabled: SERVICE.featureflag.isCareAttachmentEnabled(),
                isCloudCherryEnabled: SERVICE.featureflag.isCloudCherryEnabled(),
                isDigitalFFEnabled: SERVICE.featureflag.isDigitalFFEnabled(),
                isPCIComplianceEnabled: SERVICE.featureflag.isPCIComplianceEnabled(),
                isDesktopReasonCodeEnabled: SERVICE.featureflag.isDesktopReasonCodeEnabled(),
                isImiChatWidgetEnabled: SERVICE.featureflag.isIMIChatEnabled(),
                isSteeringDigitsAppendEnabled: SERVICE.featureflag.isSteeringDigitsAppendEnabled(),
                isTahoeEnabled: SERVICE.featureflag.isTahoeEnabled(),
                isSecureCADVariablesEnabled: SERVICE.featureflag.isSecureCADVariablesEnabled(),
                isDesktopNotesWidgetEnabled: SERVICE.featureflag.isDesktopNotesWidgetEnabled(),
                isLogRocketEnabled: SERVICE.featureflag.isLogRocketEnabled(),
                isLogRocketMaskingDisabled: SERVICE.featureflag.isLogRocketMaskingDisabled(),
                isInternationalDiallingEnabled: SERVICE.featureflag.isInternationalDiallingEnabled(),
                isIMIGlobalVariablesEnabled: SERVICE.featureflag.isIMIGlobalVariablesEnabled(),
                isGlobalVariablesEnabled: SERVICE.featureflag.isGlobalVariablesEnabled(),
                isDesktopConsultToEntryPointDnEnabled: SERVICE.featureflag.isDesktopConsultToEntryPointDnEnabled(),
                isDesktopMaxSessionLimitsEnabled: SERVICE.featureflag.isDesktopMaxSessionLimitsEnabled(),
                isSignoutBySupervisorEnabled: SERVICE.featureflag.isSignoutBySupervisorEnabled(),
                isCallbackRetryEnabled: SERVICE.featureflag.isCallbackRetryEnabled(),
                isWebRTCEnabled: SERVICE.featureflag.isWebRTCEnabled(),
                isWebRTCSupervisorEnabled: SERVICE.featureflag.isWebRTCSupervisorEnabled(),
                isProgressiveCampaignEnabled: SERVICE.featureflag.isProgressiveCampaignEnabled(),
                isProgressiveCampaign1NEnabled: SERVICE.featureflag.isProgressiveCampaign1NEnabled(),
                isDesktopMaerskEnhancementEnabled: SERVICE.featureflag.isDesktopMaerskEnhancementEnabled(),
                isESDPostInteractionInsightsEnabled: SERVICE.featureflag.isESDPostInteractionInsightsEnabled(),
                isDesktopMaerskAcqueonTeamId: SERVICE.featureflag.isDesktopMaerskAcqueonTeamId(),
                isNativeChat: SERVICE.featureflag.isNativeChat(),
                isDesktopCpdViewEnabled: SERVICE.featureflag.isDesktopCpdViewEnabled(),
                isAddressBookInfiniteScrollEnabled: SERVICE.featureflag.isAddressBookInfiniteScrollEnabled(),
                isProjectionOutdialANIEnabled: SERVICE.featureflag.isProjectionOutdialANIEnabled(),
                isDesktopMSStateSyncEnabled: SERVICE.featureflag.isDesktopMSStateSyncEnabled(),
                isDesktopAuxCodesInfiniteScrollEnabled: SERVICE.featureflag.isDesktopAuxCodesInfiniteScrollEnabled(),
                isDesktopWebexStateSyncEnabled: SERVICE.featureflag.isDesktopWebexStateSyncEnabled(),
                isDesktopEmergencyNotificationEnabled: SERVICE.featureflag.isDesktopEmergencyNotificationEnabled(),
                isWebrtcRingingEnabled: SERVICE.featureflag.isWebrtcRingingEnabled(),
                isWebrtcEndCallEnabled: SERVICE.featureflag.isWebrtcEndCallEnabled(),
                isWxccMultiPartyConfEnabled: SERVICE.featureflag.isWxccMultiPartyConfEnabled(),
                isAiAssistantEnabled: SERVICE.featureflag.isAiAssistantEnabled(),
                isQMWShowConsultRecordingsEnabled: SERVICE.featureflag.isQMWShowConsultRecordingsEnabled(),
                isRecordingsRicherMetadataEnabled: SERVICE.featureflag.isRecordingsRicherMetadataEnabled(),
                isDesktopConferenceRecordingEnabled: SERVICE.featureflag.isDesktopConferenceRecordingEnabled(),
                isWxccPersistCallEnabled: SERVICE.featureflag.isWxccPersistCallEnabled(),
                isShowSignedOutAgentsEnabled: SERVICE.featureflag.showSignedOutAgents(),
                isAutoCsatEnabled: SERVICE.featureflag.isAutoCSATEnabled(),
                isWxccAgentInteractionEnabled: SERVICE.featureflag.isWxccAgentInteractionEnabled(),
                isMomentumV2Enabled: SERVICE.featureflag.isMomentumV2Enabled(),
                isWebexDirectoryViewEnabled: SERVICE.featureflag.isWebexDirectoryViewEnabled(),
                isWxccAllowOutdialCallAlwaysEnabled: SERVICE.featureflag.isWxccAllowOutdialCallAlwaysEnabled(),
                isQueueStatsTabEnabled: SERVICE.featureflag.isQueueStatsTabEnabled(),
                isMaskSensitiveDataFFEnabled: SERVICE.featureflag.isMaskSensitiveDataFFEnabled(),
                isAllowConfTransferToEpDnEnabled: SERVICE.featureflag.isAllowConfTransferToEpDnEnabled(),
                isOutdialOrgSearchEnabled: SERVICE.featureflag.isOutdialOrgSearchEnabled(),
                isConfirmDialogEnabled: SERVICE.featureflag.isConfirmDialogEnabled(),
                isSuppressWebexCallingNotificationEnabled: SERVICE.featureflag.isSuppressWebexCallingNotificationEnabled(),
                isProgressive11SpecialModeEnabled: SERVICE.featureflag.isProgressive11SpecialModeEnabled(),
                isConsultTransferInfiniteScrollEnabled: SERVICE.featureflag.isConsultTransferInfiniteScrollEnabled(),
                isDesktopEnhancedSignInEnabled: SERVICE.featureflag.isDesktopEnhancedSignInEnabled()
            };
        }
        catch (error) {
            logger.error(`event=fetchFeatureFlags | [Tracking ID: ${getTrackingIdFromErrorObject ? getTrackingIdFromErrorObject(error) : error}] Error fetching Feature Flags: ${error}`, error);
        }
        return undefined;
    });
}
/**
 * Summary of configuration helpers
 */
const configs = {
    fetchPciSettings,
    fetchFeatureFlags,
    fetchPciAllowedAttachmentTypes
};
export function updateFeatureFlags() {
    return __awaiter(this, void 0, void 0, function* () {
        const featureFlags = yield configs.fetchFeatureFlags();
        STORE.app.updateFeatureFlags(featureFlags);
    });
}
function updateOrgConfigs(orgId) {
    return __awaiter(this, void 0, void 0, function* () {
        if (STORE.app.featureFlags && STORE.app.featureFlags.isPCIComplianceEnabled && STORE.app.featureFlags.isNativeChat) {
            const pciSettings = yield configs.fetchPciSettings(orgId);
            const pciAllowedAttachmentTypes = yield configs.fetchPciAllowedAttachmentTypes();
            STORE.app.updatePciAllowedAttachmentTypes(pciAllowedAttachmentTypes);
            STORE.app.updatePciCompliance(pciSettings);
        }
    });
}
const publishAuthDetailAfterSuccessfulIframeLogin = (config) => {
    const message = { type: "agentx-login-from-iframe", config };
    window.opener.postMessage(message, "*");
    logger.info("event=IFrameLogin | Iframe login success, publishing the token to parent window");
};
export const findAuthTokenForIframeApp = () => {
    logger.info("event=IFrameLogin | Child window opened for iframe authentication");
    return (function () {
        const interval = setInterval(() => {
            const authToken = JSON.parse(localStorage.getItem(uuipAuthToken) || "{}");
            const ciClusterIdValue = localStorage.getItem(CLUSTER_ID_KEY) || null;
            if (authToken && authToken.token) {
                clearInterval(interval);
                const ciConfig = JSON.parse(localStorage.getItem("ci-config") || "{}");
                const config = {
                    ciClusterId: ciClusterIdValue,
                    uuipAuthTokens: authToken,
                    token: authToken.token,
                    ci_config: ciConfig
                };
                publishAuthDetailAfterSuccessfulIframeLogin(config);
            }
            else {
                logger.warn("event=IFrameLogin | Auth token not found in iframe login, retrying...");
            }
        }, 1000);
    })();
};
export function listenScreenPopEvent() {
    return __awaiter(this, void 0, void 0, function* () {
        SERVICE.aqm.screenpop.eScreenPop.listen((screenPopDetail) => {
            STORE.session.screenpop.handleScreenPop(screenPopDetail);
        });
    });
}
export { configs, updateOrgConfigs };
export function fetchMeDetails(ci) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const meData = yield ci.getUserMe();
            logger.info("event=identityMeSuccess | fetching user identity me details success : ", meData);
            return meData;
        }
        catch (error) {
            logger.error("event=identityMeFailed | fetching user identity me details Error : ", error);
        }
        return undefined;
    });
}
const teamDataFormat = (team) => {
    var _a;
    return {
        teamId: (_a = team.dbId) !== null && _a !== void 0 ? _a : team.id,
        teamName: team.name
    };
};
const teamQueueFormat = (queue) => {
    return {
        queueId: queue.id,
        queueName: queue.name
    };
};
export const getFilteredTeamsBySiteIds = (teamData, siteIds) => {
    const filteredTeams = [];
    teamData.forEach((team) => {
        if (siteIds.includes(team.siteId)) {
            filteredTeams.push(teamDataFormat(team));
        }
    });
    return filteredTeams;
};
export const getAllFormattedTeams = (teamData) => {
    const filteredTeams = [];
    teamData.forEach((team) => {
        filteredTeams.push(teamDataFormat(team));
    });
    return filteredTeams;
};
export const getFilteredQueuesByQueueIds = (queueData, queueIds) => {
    const filteredQueues = [];
    queueData.forEach((queue) => {
        if (queueIds.includes(queue.id)) {
            filteredQueues.push(teamQueueFormat(queue));
        }
    });
    return filteredQueues;
};
export const getAllFormattedQueues = (queueData) => {
    const filteredQueues = [];
    queueData.forEach((queue) => {
        filteredQueues.push(teamQueueFormat(queue));
    });
    return filteredQueues;
};
const updateManagedTeams = (data) => {
    logger.info("Managed Teams List Success");
    STORE.agent.updateManagedTeams(data);
    STORE.agent.updateSelectedTeamList(JSON.parse(sessionStorage.getItem("selected-team-list") || "[]"));
    STORE.agent.updateIsManagedTeamLoading(false);
};
export function fetchManagedTeams() {
    return __awaiter(this, void 0, void 0, function* () {
        STORE.agent.updateIsManagedTeamLoading(true);
        try {
            // Load data from agentx-backend.
            const response = yield SERVICE.abs.fetchManagedTeams({ userId: STORE.agent.meUserId, orgId: STORE.agent.orgId });
            updateManagedTeams(response);
        }
        catch (error) {
            const trackingId = getTrackingIdFromErrorObject ? getTrackingIdFromErrorObject(error) : "";
            logger.error(`event=fetchManagedTeamsError | Tracking ID: ${trackingId} Fetching managed teams failed :`, error);
            STORE.agent.updateIsManagedTeamLoading(false);
            STORE.agent.updateIsManagedTeamError(trackingId);
        }
    });
}
const updateManagedQueues = (data) => {
    logger.info("Managed Queue List Success");
    STORE.agent.updateManagedQueues(data);
    STORE.agent.updateSelectedQueueList(JSON.parse(sessionStorage.getItem("selected-queue-list") || "[]"));
    STORE.agent.updateIsManagedQueueLoading(false);
};
export function fetchManagedQueues() {
    return __awaiter(this, void 0, void 0, function* () {
        STORE.agent.updateIsManagedQueueLoading(true);
        try {
            // Load data from agentx-backend.
            const response = yield SERVICE.abs.fetchManagedQueues({ userId: STORE.agent.meUserId, orgId: STORE.agent.orgId });
            updateManagedQueues(response);
        }
        catch (error) {
            const trackingId = getTrackingIdFromErrorObject ? getTrackingIdFromErrorObject(error) : "";
            logger.error("event=fetchManagedQueuesError | Fetching managed queues failed :", error);
            STORE.agent.updateIsManagedQueueError(trackingId);
            STORE.agent.updateIsManagedQueueLoading(false);
        }
    });
}
const updateQueuesManagedQueues = (data) => {
    logger.info("Queues Managed Queue List Success");
    STORE.agent.updateQueuesManagedQueues(data);
    STORE.agent.updateQueuesSelectedQueueList(JSON.parse(sessionStorage.getItem("queues-selected-queue-list") || "[]"));
    STORE.agent.updateIsQueuesManagedQueueLoading(false);
};
export function fetchQueuesManagedQueues() {
    return __awaiter(this, void 0, void 0, function* () {
        STORE.agent.updateIsQueuesManagedQueueLoading(true);
        try {
            // Load data from agentx-backend.
            const response = yield SERVICE.abs.fetchManagedQueues({ userId: STORE.agent.meUserId, orgId: STORE.agent.orgId });
            updateQueuesManagedQueues(response);
        }
        catch (error) {
            const trackingId = getTrackingIdFromErrorObject ? getTrackingIdFromErrorObject(error) : "";
            logger.error("event=fetchQueuesManagedQueuesError | Fetching queues managed queues failed :", error);
            STORE.agent.updateIsQueuesManagedQueueError(trackingId);
            STORE.agent.updateIsQueuesManagedQueueLoading(false);
        }
    });
}
const updateQueuesManagedTeams = (data) => {
    logger.info("Queues Managed Teams List Success");
    STORE.agent.updateQueuesManagedTeams(data);
    STORE.agent.updateQueuesSelectedTeamList(JSON.parse(sessionStorage.getItem("queues-selected-team-list") || "[]"));
    STORE.agent.updateIsQueuesManagedTeamLoading(false);
};
export function fetchQueuesManagedTeams() {
    return __awaiter(this, void 0, void 0, function* () {
        STORE.agent.updateIsQueuesManagedTeamLoading(true);
        try {
            // Load data from agentx-backend.
            const response = yield SERVICE.abs.fetchManagedTeams({ userId: STORE.agent.meUserId, orgId: STORE.agent.orgId });
            updateQueuesManagedTeams(response);
        }
        catch (error) {
            const trackingId = getTrackingIdFromErrorObject ? getTrackingIdFromErrorObject(error) : "";
            logger.error(`event=fetchQueuesManagedTeamsError | Tracking ID: ${trackingId} Fetching queues managed teams failed :`, error);
            STORE.agent.updateIsQueuesManagedTeamLoading(false);
            STORE.agent.updateIsQueuesManagedTeamError(trackingId);
        }
    });
}
export const getSpecificIdleCode = (auxCodeId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield SERVICE.abs.fetchIdleCodes({
            orgId: STORE.agent.organizationId,
            accessType: "SPECIFIC",
            ids: [auxCodeId]
        });
        logger.info("event=fetchIdleCodes | Successfully fetched idle code for subStatus");
        return response;
    }
    catch (error) {
        logger.error("event=fetchIdleCodes | Error on fetching idle code for subStatus", error);
        throw error;
    }
});
export function handleIframeLogout() {
    const logoutWindow = window.open(`${window.location.origin}?logout='true'`);
    if (logoutWindow) {
        const interval = setInterval(() => {
            const authToken = JSON.parse(localStorage.getItem(uuipAuthToken) || "{}");
            try {
                if (logoutWindow.location && logoutWindow.location.origin) {
                    logger.info("event=IFrameLogout | Iframe logout in progress");
                }
            }
            catch (_a) {
                if (!authToken || !authToken.token) {
                    logger.error("event=IFrameLogout | Iframe logout success reloading");
                }
                else {
                    logger.info("event=IFrameLogout | Iframe logout failed reloading");
                }
                localStorage.removeItem("uuip-auth-token");
                logger.info("uuip-auth-token removed from localStorage while handleIframeLogout");
                clearInterval(interval);
                logoutWindow.close();
                window.location.reload();
            }
        }, 1000);
    }
}
export function unRegisterWebexSDK() {
    return __awaiter(this, void 0, void 0, function* () {
        logger.info("unregistering webex and spark instance's");
        STORE.session.chat.updateRemoveMediaListeners(true);
        yield SERVICE.webex.unregisterWebex();
    });
}
// Function calls when tab/browser window closes
export function tabClosePrompt(e) {
    logger.info("Prompt displayed before navigation.");
    logger.info("event=DesktopReload | Desktop has been reload");
    SERVICE.telemetry.track("DESKTOP_RELOAD", {
        browserOnline: navigator.onLine
    });
    if (localStorage.getItem(uuipAuthToken)) {
        e.preventDefault();
        logMiner();
        e.returnValue = "";
        return "";
    }
    else {
        return false;
    }
}
export function removeUnloadEvent() {
    logger.info("Prompt removed.");
    window.removeEventListener("beforeunload", tabClosePrompt);
    window.onbeforeunload = null;
}
/**
 * Order/Sort list of objects based on provided field
 * @param list
 * @param field
 */
export const orderBy = (list, field) => {
    return list.sort((a, b) => {
        const nameA = a[field].toLowerCase();
        const nameB = b[field].toLowerCase();
        if (nameA > nameB) {
            return -1;
        }
        if (nameA < nameB) {
            return 1;
        }
        return 0;
    });
};
export const invokeLoginErrorModal = (errorMsg, trackingId) => {
    STORE.session.errorModal.setErrorDetails({
        detail: {
            message: errorMsg,
            buttons: [
                {
                    type: "ok",
                    label: t(errorModalOk),
                    onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        logger.error(`event=handleLoginError | Triggering CI logout : ${errorMsg}`, errorMsg);
                        yield STORE.auth.logout(STORE.auth.refreshToken);
                    })
                }
            ],
            noExitOnEsc: true,
            trackingId
        }
    });
};
const dispatchAutoLogin = () => {
    window.dispatchEvent(new CustomEvent(SUPERVISOR_AUTO_LOGIN_EVENT, { bubbles: true, composed: true }));
};
export const invokeMaximumSessionErrorModal = (errorMsg, trackingId) => {
    STORE.session.errorModal.setErrorDetails({
        detail: {
            message: errorMsg,
            buttons: [
                {
                    type: "button",
                    color: "var(--md-background-color--gray-05)",
                    label: t("app:common.signOutButton"),
                    onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        logger.error(`event=handleLoginError | Triggering CI logout : ${errorMsg}`, errorMsg);
                        yield STORE.auth.logout(STORE.auth.refreshToken);
                    })
                },
                {
                    type: "button",
                    label: t("app:common.retry"),
                    onClick: () => {
                        logger.info(`dispatching ${SUPERVISOR_AUTO_LOGIN_EVENT} on retry`);
                        dispatchAutoLogin();
                    }
                }
            ],
            noExitOnEsc: true,
            trackingId
        }
    });
};
export function handleLoginError(errorDetail) {
    const isConfigsError = errorDetail.id === "Service.aqm.configs.application" || errorDetail.id === "Service.abs.fetchCmsInitConfig";
    const isTeamsError = errorDetail.id === "Service.aqm.configs.teams";
    const isSupervisorLoginError = errorDetail.id === "Service.aqm.supervisor.stationLogin" ||
        errorDetail.id === "Service.aqm.supervisor.stationLoginFailed";
    if (isConfigsError || isTeamsError || isSupervisorLoginError) {
        let errorMsg = isConfigsError
            ? t("app:stationLogin.initialConfigsFailed")
            : isTeamsError
                ? t("app:stationLogin.initialProfileTeamFailed")
                : t("app:stationLogin.supervisorStationLoginFailed");
        const trackingId = errorDetail.trackingId;
        if ((errorDetail === null || errorDetail === void 0 ? void 0 : errorDetail.reasonCode) === 1019 && STORE.app.featureFlags.isDesktopMaxSessionLimitsEnabled) {
            errorMsg = t("app:stationLogin:maximumSessionsReachedError");
            invokeMaximumSessionErrorModal(errorMsg, trackingId);
            return;
        }
        invokeLoginErrorModal(errorMsg, trackingId);
    }
}
export const invokeTeamDeletedErrorModal = (errorMsg, trackingId) => {
    STORE.session.errorModal.setErrorDetails({
        detail: {
            message: errorMsg,
            buttons: [
                {
                    type: "ok",
                    label: t("app:common.signOutButton"),
                    onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        logger.error(`event=handleLoginError | Triggering CI logout : ${errorMsg}`, errorMsg);
                        yield STORE.auth.logout(STORE.auth.refreshToken);
                    })
                }
            ],
            noExitOnEsc: true,
            trackingId
        }
    });
};
export const showContactCleanUpModal = (trackingId) => {
    STORE.session.errorModal.setErrorDetails({
        detail: {
            modalType: "dialog",
            header: t("app:contactTerminationLabels.header"),
            message: t("app:contactTerminationLabels.message"),
            trackingId
        }
    });
};
export function isIframedLogoutRequested(windowOpener) {
    const urlParams = new URLSearchParams(window.location.search);
    const isLogoutRequested = urlParams.get("logout");
    return windowOpener != null && isLogoutRequested != null ? true : false;
}
export function setQueryparams() {
    const urlParams = new URLSearchParams(window.location.search);
    const sfdcIframeOrigin = urlParams.get("sfdcIframeOrigin");
    const isCanaryOrg = urlParams.get("canary");
    const orgId = urlParams.get("orgId");
    if (sfdcIframeOrigin) {
        logger.info("event=setQueryParams | Setting sfdcIframeOrigin value to ", sfdcIframeOrigin);
        localStorage.setItem("sfdcIframeOrigin", sfdcIframeOrigin);
    }
    if (isCanaryOrg) {
        logger.info("event=setQueryParams | Setting isCanaryOrg value to ", isCanaryOrg);
        sessionStorage.setItem("canary", isCanaryOrg);
    }
    if (orgId) {
        logger.info("event=setQueryParams | Setting queryOrgId value to ", orgId);
        sessionStorage.setItem("queryOrgId", orgId);
    }
}
export function getCanaryParam() {
    const flag = sessionStorage.getItem("canary");
    return flag === "true" ? true : false;
}
export function getOrgIdParam() {
    return sessionStorage.getItem("queryOrgId");
}
export function isCanaryFlagInQueryParam() {
    const urlParams = new URLSearchParams(window.location.search);
    const isCanaryOrg = urlParams.get("canary");
    return isCanaryOrg === "true" ? true : false;
}
export function handleLogout(token) {
    return new Promise((resolve) => {
        const urlParams = new URLSearchParams(window.location.search);
        const logout = urlParams.get("logout");
        if (logout) {
            STORE.auth.authService.logout(token);
        }
        else {
            resolve();
        }
    });
}
export function updateIMISettings() {
    const script = document.createElement("script");
    script.setAttribute("id", "imi-controller-bundle");
    script.setAttribute("type", "module");
    script.setAttribute("src", process.env.IMI_GADGET_URL);
    script.setAttribute("dc", process.env.IMI_DC);
    document.body.appendChild(script);
    script.onerror = () => {
        logger.error(`[App: IMI] failed to load Controller IMI JS with src`, script.src);
    };
    script.onload = () => {
        logger.info(`[App: IMI] Success loading Controller IMI JS with src`, script.src);
        document.addEventListener("imi-engage-bundle-load-success", function () {
            STORE.app.setImiScriptLoad();
            logger.info(`[App: IMI] Success loading IMI JS with ${process.env.IMI_DC} DC`);
        });
        document.addEventListener("imi-engage-bundle-load-error", function () {
            logger.error(`[App: IMI] failed to load IMI JS with ${process.env.IMI_DC} DC`);
        });
    };
}
export function updateAnalyzerSettings() {
    const script = document.createElement("script");
    script.setAttribute("id", "wxcc-analyzer-bundle");
    script.setAttribute("type", "module");
    script.setAttribute("src", process.env.ANALYZER_BUNDLE);
    script.setAttribute("dc", process.env.CC_DC);
    document.body.appendChild(script);
    script.onerror = () => {
        logger.error(`[App: AnalyzerUI] failed to load Controller AnalyzerUI JS with src`, script.src);
    };
    script.onload = () => {
        logger.info(`[App: AnalyzerUI] Success loading Controller AnalyzerUI JS with src`, script.src);
        document.addEventListener("wxccanalyzer-desktop-bundle-load-success", function () {
            logger.info(`[App: AnalyzerUI] Success loading AnalyzerUI JS with ${process.env.CC_DC} DC`);
        });
        document.addEventListener("wxccanalyzer-desktop-bundle-load-error", function () {
            logger.error(`[App: AnalyzerUI] failed to load AnalyzerUI JS with ${process.env.CC_DC} DC`);
        });
    };
}
export function attachIMIScript() {
    if (!document.getElementById("imi-controller-bundle")) {
        updateIMISettings();
        STORE.app.updateIMIFeatureFlag(true);
    }
}
export function updatePageTitle(routeName) {
    if (routeName === RouteName.TASKPANEL || routeName === RouteName.TASK) {
        document.title = `${STORE.app.title} : ${t("app:navigation.task")}`;
        return;
    }
    STORE.layout.navigation.forEach((nav) => {
        if (nav.navigateTo === routeName && nav.label) {
            document.title = `${STORE.app.title}: ${t(nav.label)}`;
        }
    });
}
export function registerDefaultShortcutKeys() {
    SERVICE.shortcut.event.listenKeyConflict(() => {
        if (!STORE.shortcutKeys.isShortcutKeyConflict) {
            STORE.shortcutKeys.updateConflictForKey(true);
            STORE.generalNotifications.fireShortcutKeyConflict(t("app:keyboardShortcutKeys.conflictTitle"), t("app:keyboardShortcutKeys.conflictData"));
        }
    });
    SERVICE.shortcut.event.listenConflictResolved(() => {
        STORE.shortcutKeys.updateConflictForKey(false);
    });
    Object.values(SERVICE.shortcut.DEFAULT_SHORTCUT_KEYS).forEach((keys) => {
        keys.forEach((key) => {
            key.group = t(key.group);
            key.action = t(key.action);
        });
        SERVICE.shortcut.register(keys);
    });
}
export const registerWebRTCShortcuts = () => {
    SERVICE.shortcut.register(WEBRTC_SHORTCUT_KEYS);
};
const showSystemLogoutErrorModal = () => {
    STORE.session.errorModal.setErrorDetails({
        detail: {
            header: t("app:idleInActivity.signOut"),
            message: t("app:systemSignOutMessage"),
            backdropClickExit: false,
            noExitOnEsc: true,
            buttons: [
                {
                    type: "ok",
                    label: t(errorModalOk),
                    onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        yield STORE.auth.logout(STORE.auth.refreshToken);
                    })
                }
            ]
        }
    });
};
const handleLogoutBySelf = () => {
    var _a, _b, _c, _d;
    if (STORE.app.featureFlags.isWebRTCEnabled &&
        STORE.agent.deviceType === SERVICE.constants.LoginVoiceOptions.BROWSER) {
        logger.info(`[WebRtc] : deregistering webcalling on agent logout , agentId ${STORE.agent.agentId}`);
        SERVICE.webCalling.deregisterWebCalling();
    }
    SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.SIGN_OUT, {
        [SERVICE.telemetry.MIX_PROPS.SIGN_OUT_METHOD]: "Agent",
        Status: SERVICE.telemetry.MIX_EVENT.STATUS.SUCCESS
    }, [(_b = (_a = SERVICE.telemetry) === null || _a === void 0 ? void 0 : _a.SERVICE_PROVIDERS) === null || _b === void 0 ? void 0 : _b.mixpanel, (_d = (_c = SERVICE.telemetry) === null || _c === void 0 ? void 0 : _c.SERVICE_PROVIDERS) === null || _d === void 0 ? void 0 : _d.prometheus]);
    sessionStorage.removeItem(ALERT_BANNER_DETAILS);
    const isSignOutByUi = STORE.agent.signOutByUi;
    const isSignOutByProfileChange = STORE.agent.signOutByProfileChange;
    const hasAccessToken = STORE.auth.accessToken;
    // if the agent is signed out by profile change then we should not go with this flow.
    if (isSignOutByProfileChange) {
        STORE.agent.updateSignOutByChangeProfile(false);
        return;
    }
    // is the agent is signed out by UI then we should not show the modal
    if (!isSignOutByUi) {
        showSystemLogoutErrorModal();
        return;
    }
    // General signout flow.
    if (hasAccessToken) {
        logMiner();
        localStorage.removeItem("desktop-notification-settings");
        sessionStorage.removeItem("desktop-silent-notifications");
        STORE.auth.logout(STORE.auth.refreshToken);
        logger.info("[Agent Self Logout] Aqm logout success - Posted an event for CILogout");
    }
};
/**
 * Below event will be received on notifs when agent is logged out.
 * @param event
 */
export function checkAgentLogout(event) {
    // if agent is logged out by SUPERVISOR , ADMIN , HELPDESK then only we should show the below modal.
    if (event.loggedOutBy === MACHINE) {
        SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.SIGN_OUT, {
            [SERVICE.telemetry.MIX_PROPS.SIGN_OUT_METHOD]: "Machine",
            Status: SERVICE.telemetry.MIX_EVENT.STATUS.SUCCESS
        });
        STORE.session.errorModal.setErrorDetails({
            detail: {
                header: t("app:idleInActivity.signOut"),
                htmlMessage: html `
          <div>
            ${t("app:agentLogoutByMachine")}
            <ul style="padding-left:14px;margin-top:0px;">
              <li>${t("app:poorNetworkConnection")}</li>
              <li>${t("app:systemSignOut")}</li>
            </ul>
            ${t("app:signInAgain")}
          </div>
        `,
                backdropClickExit: false,
                noExitOnEsc: true,
                buttons: [
                    {
                        type: "ok",
                        label: t(errorModalOk),
                        onClick: () => __awaiter(this, void 0, void 0, function* () {
                            yield STORE.auth.logout(STORE.auth.refreshToken);
                        })
                    }
                ]
            }
        });
        return;
    }
    if (event.loggedOutBy !== SELF) {
        SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.SIGN_OUT, {
            [SERVICE.telemetry.MIX_PROPS.SIGN_OUT_METHOD]: "Supervisor",
            Status: SERVICE.telemetry.MIX_EVENT.STATUS.SUCCESS
        });
        STORE.session.errorModal.setErrorDetails({
            detail: {
                header: t("app:agentLogoutBySupervisorTitle"),
                message: t("app:agentLogoutBySupervisor"),
                backdropClickExit: false,
                noExitOnEsc: true,
                buttons: [
                    {
                        type: "ok",
                        label: t(errorModalOk),
                        onClick: () => __awaiter(this, void 0, void 0, function* () {
                            yield STORE.auth.logout(STORE.auth.refreshToken);
                        })
                    }
                ]
            }
        });
    }
    if (event.loggedOutBy === SELF) {
        handleLogoutBySelf();
    }
}
export const getRonareason = (reasonCode, interaction) => {
    if (getErrorMsgForReasonCode(reasonCode)) {
        return html ` <div>${getErrorMsgForReasonCode(reasonCode)}</div> `;
    }
    else {
        return html `
      <div>
        ${t("app:rona.deliverRequestFailure")}
        <br />
        <ul>
          <li>${t("app:rona.requestNotAnswered")}</li>
          <li>${checkRonaReason(interaction)}</li>
        </ul>
      </div>
    `;
    }
};
export function ronaToOtherState(interaction, reasonCode) {
    STORE.browserNotifications.firebrowserNotification(t("app:rona.browserNotification"));
    STORE.session.errorModal.setErrorDetails({
        detail: {
            header: t("app:rona.title"),
            htmlHeadingElement: true,
            htmlMessage: SERVICE.featureflag.isDesktopReasonCodeEnabled()
                ? getRonareason(reasonCode, interaction)
                : html `
            <div>
              ${t("app:rona.deliverRequestFailure")}
              <br />
              <ul>
                <li>${t("app:rona.requestNotAnswered")}</li>
                <li>${checkRonaReason(interaction)}</li>
              </ul>
            </div>
          `,
            backdropClickExit: false,
            noExitOnEsc: true,
            buttons: [
                {
                    type: "button",
                    variant: "secondary",
                    label: t("app:rona.idleState"),
                    onClick: () => {
                        SERVICE.telemetry.timeEvent(SERVICE.telemetry.MIX_EVENT.AGENT_STATE_CHANGE);
                        STORE.agent.updateStatusAfterRONA(SERVICE.constants.AgentState.Idle);
                        document.dispatchEvent(new CustomEvent(AGENTX_DEFAULT_FOCUS));
                    }
                },
                {
                    type: "button",
                    label: t("app:rona.availableState"),
                    onClick: () => {
                        SERVICE.telemetry.timeEvent(SERVICE.telemetry.MIX_EVENT.AGENT_STATE_CHANGE);
                        STORE.agent.updateStatusAfterRONA(SERVICE.constants.AgentState.Available);
                        document.dispatchEvent(new CustomEvent(AGENTX_DEFAULT_FOCUS));
                    }
                }
            ]
        }
    });
}
export function midcallMonitoringStarted(agentId, interactionId) {
    var _a;
    (_a = SERVICE.telemetry) === null || _a === void 0 ? void 0 : _a.track(SERVICE.telemetry.MIX_EVENT.MIDCALL_MONITORING_STARTED, {
        SupervisorId: STORE.agent.agentId,
        Role: STORE.agent.userSelectedRole,
        MonitoredAgentId: agentId,
        MonitoringType: MONITORING_TYPE.MIDCALL,
        InteractionId: interactionId
    });
}
export function midcallMonitoringOffered(agentId, interactionId) {
    var _a;
    (_a = SERVICE.telemetry) === null || _a === void 0 ? void 0 : _a.track(SERVICE.telemetry.MIX_EVENT.MIDCALL_MONITORING_OFFERED, {
        SupervisorId: STORE.agent.agentId,
        Role: STORE.agent.userSelectedRole,
        AgentId: agentId,
        MonitoringType: MONITORING_TYPE.MIDCALL,
        InteractionId: interactionId
    });
}
export function midcallMonitoringEnded(interactionId) {
    var _a;
    (_a = SERVICE.telemetry) === null || _a === void 0 ? void 0 : _a.track(SERVICE.telemetry.MIX_EVENT.MIDCALL_MONITORING_ENDED, {
        SupervisorId: STORE.agent.agentId,
        Role: STORE.agent.userSelectedRole,
        MonitoringType: MONITORING_TYPE.MIDCALL,
        InteractionId: interactionId
    });
}
export function midcallMonitoringFailed(interactionId) {
    var _a;
    (_a = SERVICE.telemetry) === null || _a === void 0 ? void 0 : _a.track(SERVICE.telemetry.MIX_EVENT.MIDCALL_MONITORING_FAILED, {
        SupervisorId: STORE.agent.agentId,
        Role: STORE.agent.userSelectedRole,
        MonitoringType: MONITORING_TYPE.MIDCALL,
        InteractionId: interactionId
    });
}
export function bargeInStarted(interactionId, agentId) {
    var _a, _b, _c, _d, _e;
    (_a = SERVICE.telemetry) === null || _a === void 0 ? void 0 : _a.track(SERVICE.telemetry.MIX_EVENT.BARGE_IN_ACTIVATED, {
        InteractionId: interactionId,
        MonitoredAgentId: agentId,
        Role: STORE.agent.userSelectedRole,
        MonitoringType: BARGE_IN_MONITORING_STATE,
        Status: SERVICE.telemetry.MIX_EVENT.STATUS.SUCCESS
    }, [(_c = (_b = SERVICE.telemetry) === null || _b === void 0 ? void 0 : _b.SERVICE_PROVIDERS) === null || _c === void 0 ? void 0 : _c.mixpanel, (_e = (_d = SERVICE.telemetry) === null || _d === void 0 ? void 0 : _d.SERVICE_PROVIDERS) === null || _e === void 0 ? void 0 : _e.webex_behavioral]);
}
export function inviteFailedToIdle(interaction, trackingId) {
    const ronaModalMessage = displayRonaMessage(interaction);
    STORE.browserNotifications.firebrowserNotification(ronaModalMessage);
    STORE.session.errorModal.setErrorDetails({
        detail: {
            message: ronaModalMessage,
            backdropClickExit: false,
            noExitOnEsc: true,
            buttons: [
                {
                    type: "button",
                    label: t("app:rona.idleState"),
                    onClick: () => {
                        SERVICE.telemetry.timeEvent(SERVICE.telemetry.MIX_EVENT.AGENT_STATE_CHANGE);
                        STORE.agent.updateStatusAfterRONA(SERVICE.constants.AgentState.Idle);
                    }
                }
            ],
            trackingId
        }
    });
}
const getTabName = () => {
    var _a;
    return (_a = SERVICE.telemetry) === null || _a === void 0 ? void 0 : _a.getValueFromCache(SERVICE.telemetry.MIX_CACHE_KEYS.AUXILIARY_PANEL_TAB_NAME);
};
//mix Panel utility functions;
export const auxiliaryPaneMixPanelTracker = (updatedTabName) => {
    const tabName = getTabName();
    const auxiliaryPanelTabName = tabName !== null ? tabName : null;
    if (auxiliaryPanelTabName == null ||
        auxiliaryPanelTabName === undefined ||
        auxiliaryPanelTabName === updatedTabName) {
        return;
    }
    let timeSpent = 0;
    switch (auxiliaryPanelTabName) {
        case widgetElement.CONTACT_HISTORY:
            timeSpent = SERVICE.telemetry.getValueFromCache(SERVICE.telemetry.MIX_CACHE_KEYS.CONTACT_HISTORY_START_TIME);
            SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.CONTACT_HISTORY_VIEWED, {
                [SERVICE.telemetry.MIX_PROPS.TOTAL_TIME_SPENT]: Number(((new Date().getTime() - Number(timeSpent)) / 1000).toFixed(2))
            });
            SERVICE.telemetry.removeValueFromCache(SERVICE.telemetry.MIX_CACHE_KEYS.CONTACT_HISTORY_START_TIME);
            break;
        case widgetElement.WXM_JOURNEY:
            timeSpent = SERVICE.telemetry.getValueFromCache(SERVICE.telemetry.MIX_CACHE_KEYS.WXM_JOURNEY_START_TIME);
            SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.CONTACT_HISTORY_VIEWED, {
                [SERVICE.telemetry.MIX_PROPS.TOTAL_TIME_SPENT]: Number(((new Date().getTime() - Number(timeSpent)) / 1000).toFixed(2))
            });
            SERVICE.telemetry.removeValueFromCache(SERVICE.telemetry.MIX_CACHE_KEYS.WXM_JOURNEY_START_TIME);
            break;
    }
};
export const isSupervisor = () => {
    var _a;
    return ((_a = STORE.agent.userRole) === null || _a === void 0 ? void 0 : _a.join("_")) === "supervisor" || STORE.agent.userSelectedRole === "supervisor";
};
export const isOnlySupervisor = () => {
    var _a;
    if (((_a = STORE.agent.userRole) === null || _a === void 0 ? void 0 : _a.join("_")) === "supervisor") {
        return !STORE.agent.isMonitoringEnabled;
    }
    return false;
};
export const updateLSLayout = (data, key) => {
    const previousLayouts = localStorage.getItem(key);
    const agentId = STORE.agent.meUserId.toString();
    if (!previousLayouts) {
        const newLayout = {};
        newLayout[agentId] = {
            layout: JSON.stringify(data),
            userSelectedRole: STORE.agent.getUserRole
        };
        localStorage.setItem(key, JSON.stringify(newLayout));
    }
    else {
        const newLayouts = JSON.parse(previousLayouts);
        newLayouts[agentId] = {
            layout: JSON.stringify(data),
            userSelectedRole: STORE.agent.getUserRole
        };
        localStorage.setItem(key, JSON.stringify(newLayouts));
    }
};
export const getLayoutFromLS = (key) => {
    const configStr = localStorage.getItem(key);
    const layouts = configStr ? JSON.parse(configStr) : undefined;
    const agentId = STORE.agent.meUserId.toString();
    if (layouts && layouts[agentId]) {
        layouts[agentId].layout = JSON.parse(layouts[agentId].layout);
        return layouts[agentId];
    }
    return undefined;
};
export const stripSpecialChar = (str) => {
    /*eslint no-useless-escape: "off"*/
    return str.replace(/[^\+?\d]/gi, "");
};
export function multipleExist(arr, values) {
    if (arr.length === 0 || values.length === 0) {
        return false;
    }
    return arr.some((val) => values.includes(val));
}
export const getManagedOrgDetails = (managedOrgs, orgId) => {
    return managedOrgs.find((org) => org.orgId === orgId) || null;
};
export const getUserSystemRole = (orgDetails) => {
    if (multipleExist([USER_ROLES.FULL_ADMIN, USER_ROLES.PARTNER_ADMIN, USER_ROLES.PROVISION_ADMIN], [orgDetails.role])) {
        return SYSTEM_ROLES.EXTERNAL_FULL_ADMIN;
    }
    else if (multipleExist([USER_ROLES.READ_ONLY_ADMIN], [orgDetails.role])) {
        return SYSTEM_ROLES.EXTERNAL_READONLY_ADMIN;
    }
    else {
        return null;
    }
};
// return's true for ep-dn second agent as can't relay on onwer field.
const isSecondaryAgent = (interaction) => {
    return (interaction.callProcessingDetails.relationshipType === "consult" &&
        interaction.callProcessingDetails.parentInteractionId &&
        interaction.callProcessingDetails.parentInteractionId !== interaction.interactionId);
};
export const isSecondaryEpDnAgent = (interaction) => {
    return (STORE.app.featureFlags.isDesktopConsultToEntryPointDnEnabled &&
        interaction.mediaType === MediaType.Telephony &&
        isSecondaryAgent(interaction));
};
export const dispatchUpdatedAgentProfileEvent = (comp) => {
    comp.dispatchEvent(new CustomEvent("store-profile-update", { bubbles: true, composed: true }));
};
/**
 * Debounce method to avoid repetitive  calls
 */
export const debounce = (fn, _timeout = 1000) => {
    let timer;
    return function (...args) {
        const func = () => fn.call({}, args[0]);
        clearTimeout(timer);
        timer = window.setTimeout(func, _timeout);
    };
};
export const showErrorModal = (message, trackingId) => {
    STORE.session.errorModal.setErrorDetails({
        detail: {
            modalType: "dialog",
            message,
            trackingId
        }
    });
};
export const contactCallbackRetryModal = (reasonCode) => {
    switch (reasonCode) {
        case CALLBACK_RETRY_REASON_CODES.NO_MATCHING_AGENT_CHANNEL_FOUND:
            return t("app:callbackRetry.noMatchingAgentChannelFound");
        case CALLBACK_RETRY_REASON_CODES.CUSTOMER_BUSY:
            return t("app:callbackRetry.customerBusy");
        case CALLBACK_RETRY_REASON_CODES.CUSTOMER_UNAVAILABLE:
            return t("app:callbackRetry.customerUnavailable");
        case CALLBACK_RETRY_REASON_CODES.AGENT_ENDS:
            return t("app:callbackRetry.agentEnds");
        case CALLBACK_RETRY_REASON_CODES.NO_ANSWER_FROM_CUSTOMER:
            return t("app:callbackRetry.noAnswerFromCustomer");
        case CALLBACK_RETRY_REASON_CODES.INVALID_VTEAM:
            return t("app:callbackRetry.invalidVTeam");
        case CALLBACK_RETRY_REASON_CODES.AGENT_CHANNEL_NOT_FOUND:
            return t("app:callbackRetry.agentChannelNotFound");
        case CALLBACK_RETRY_REASON_CODES.REASON_CODE_NOT_AVAILABLE:
            return t("app:callbackRetry.reasonCodeNotAvailable");
        case CALLBACK_RETRY_REASON_CODES.AGENT_LEFT:
            return t("app:callbackRetry.agentLeft");
        case CALLBACK_RETRY_REASON_CODES.CUSTOMER_LEFT:
            return t("app:callbackRetry.customerLeft");
        case CALLBACK_RETRY_REASON_CODES.QUEUE_TIMEOUT:
            return t("app:callbackRetry.queueTimeout");
        default:
            return t("app:callbackRetry:unknownError");
    }
};
export const handleWebexModal = () => {
    if (STORE.app.webexEnabled) {
        STORE.app.updateWebexShowModal(true);
        localStorage.setItem(WEBEX_SHOW_MODAL, "true");
    }
};
const handleWebexMinimizeRestore = () => {
    handleWebexModal();
    localStorage.setItem(WEBEX_MINIMIZE_MODAL, STORE.app.webexMinimized.toString());
    STORE.app.updateWebexMinimized(!STORE.app.webexMinimized);
};
export const handleShortcutKeyEvent = () => {
    var _a, _b;
    const listenKeyPress = (_b = (_a = SERVICE === null || SERVICE === void 0 ? void 0 : SERVICE.shortcut) === null || _a === void 0 ? void 0 : _a.event) === null || _b === void 0 ? void 0 : _b.listenKeyPress;
    if (!listenKeyPress) {
        return () => logger.info("No event listener to stop.");
    }
    const { stopListen } = listenKeyPress((event) => {
        var _a, _b;
        const { data } = event;
        if (!data) {
            return;
        }
        const { key, modifierKeys } = data;
        if (key === SERVICE.shortcut.REGISTERED_KEYS.EXPAND_COLLAPSE_WEBEX &&
            modifierKeys === SERVICE.shortcut.MODIFIERS.CTRL_SHIFT &&
            !STORE.app.webexShowModal) {
            SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.WEBEX_APP_OPENED_BY_SHORTCUT_KEY);
            handleWebexModal();
        }
        else if (key === SERVICE.shortcut.REGISTERED_KEYS.MINIMIZE_RESTORE_WEBEX &&
            modifierKeys === SERVICE.shortcut.MODIFIERS.CTRL_SHIFT) {
            handleWebexMinimizeRestore();
            if (SERVICE.webex) {
                SERVICE.webex.getUserStatusForWebex((_b = (_a = SERVICE.conf) === null || _a === void 0 ? void 0 : _a.profile) === null || _b === void 0 ? void 0 : _b.agentId);
            }
        }
    });
    if (stopListen) {
        return stopListen;
    }
    else {
        return () => logger.info("No event listener to stop.");
    }
};
export const toggleVoiceInteractionPanel = () => {
    STORE.app.updateCollapseVoiceIntPane(!STORE.app.collapseVoiceIntPane);
};
// dispatch auto answer event.
export const handleAutoAnswer = (data) => {
    const isAutoAnswerEnabled = data.interaction.participants[data.agentId].autoAnswerEnabled;
    /* all agent initiated webRtc outdial calls will be auto answered. */
    const isOutdial = data.interaction.contactDirection.type === OUTBOUND && data.interaction.outboundType === OUTBOUND_TYPE.OUTDIAL;
    const isAgentInitiated = STORE.agent.agentId === data.interaction.owner &&
        !data.interaction.callProcessingDetails.BLIND_TRANSFER_IN_PROGRESS;
    /* need to handle outdial call transfer/consult scenario where second agent should not be allowed default Auto answer*/
    if (isWebCall(data.interaction) && (isAutoAnswerEnabled || (isOutdial && isAgentInitiated))) {
        logger.info("[WebRtc]: Dispatching auto answer for interactionId ", data.interactionId);
        window.dispatchEvent(new CustomEvent(WEB_CALLING_EVENTS.AX_WEB_CALL_AUTO_ANSWER, { bubbles: true, composed: true }));
    }
    else if (isDigitalOutbound(data.interaction) && STORE.agent.agentId === data.interaction.owner) {
        logger.info("[Digital]: Dispatching auto answer for interactionId ", data.interactionId);
        window.dispatchEvent(new CustomEvent(DIGITAL_OUTBOUND_EVENTS.AX_DIGITAL_OUTBOUND_AUTO_ANSWER, { bubbles: true, composed: true }));
    }
};
export const getFCValue = (data) => {
    var _a;
    return STORE.app.featureFlags.isDesktopCpdViewEnabled
        ? data.interaction.callProcessingDetails[fcDesktopView]
        : data.interaction.callAssociatedData
            ? (_a = data.interaction.callAssociatedData[FC_DESKTOP_VIEW]) === null || _a === void 0 ? void 0 : _a.value
            : undefined;
};
export const getAgentCallMonitoringStateOnBargeIn = (agentCallMonitoringStateDetails) => {
    var _a, _b, _c, _d;
    const bargedSupervisorId = agentCallMonitoringStateDetails.supervisorId;
    return {
        monitoringType: agentCallMonitoringStateDetails.monitoringState
            ? (_a = agentCallMonitoringStateDetails.monitoringState) === null || _a === void 0 ? void 0 : _a.type
            : agentCallMonitoringStateDetails.monitoringType,
        supervisorId: bargedSupervisorId,
        supervisorName: agentCallMonitoringStateDetails.supervisorName,
        interactionId: agentCallMonitoringStateDetails.interactionId,
        agentId: agentCallMonitoringStateDetails.agentId,
        eventTime: (_d = (_c = (_b = agentCallMonitoringStateDetails.interaction) === null || _b === void 0 ? void 0 : _b.participants[bargedSupervisorId]) === null || _c === void 0 ? void 0 : _c.monitoringDetails) === null || _d === void 0 ? void 0 : _d.startTimestamp
    };
};
export const getBargedInSupId = (participants) => {
    return Object.keys(participants).find((participant) => {
        var _a, _b;
        return ((_a = participants[participant].pType) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === SUPERVISOR &&
            ((_b = participants[participant].monitoringDetails) === null || _b === void 0 ? void 0 : _b.type) === BARGE_IN_MONITORING_STATE;
    });
};
export const computeAgentCallMonitoringStateDetails = (interactionDetails) => {
    var _a, _b;
    const participants = interactionDetails.participants;
    if (participants) {
        const bargedSupervisorId = getBargedInSupId(participants);
        return bargedSupervisorId
            ? {
                supervisorName: participants[bargedSupervisorId].name,
                supervisorId: participants[bargedSupervisorId].id,
                monitoringType: (_a = participants[bargedSupervisorId].monitoringDetails) === null || _a === void 0 ? void 0 : _a.type,
                agentId: interactionDetails.owner,
                interactionId: interactionDetails.interactionId,
                eventTime: (_b = participants[bargedSupervisorId].monitoringDetails) === null || _b === void 0 ? void 0 : _b.startTimestamp
            }
            : undefined;
    }
    return undefined;
};
export const dispatchCustomEvent = (comp, eventName, value) => {
    comp.dispatchEvent(new CustomEvent(eventName, { bubbles: true, composed: true, detail: value }));
};
const formatTimeDifference = (timeDifference) => {
    const secondsDifference = Math.floor(timeDifference / 1000);
    const hours = Math.floor(secondsDifference / 3600);
    const remainingSecsAfterHours = secondsDifference % 3600;
    const minutes = Math.floor(remainingSecsAfterHours / 60);
    const seconds = remainingSecsAfterHours % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
};
export const logNtpTime = () => {
    SERVICE.abs
        .fetchNtpTime({})
        .then((res) => {
        logger.info(`[NTP] NTP time is ${res}`);
        const ntpTime = new Date(res);
        const systemTime = new Date();
        const timeDifference = Math.abs(systemTime.getTime() - ntpTime.getTime());
        const threshold = 1000;
        if (timeDifference <= threshold) {
            logger.info("System time is in sync with the NTP server.");
        }
        else {
            // convert timeDifference to hh:mm:ss format
            logger.warn(`System time is not in sync with the NTP server. Time difference is ${formatTimeDifference(timeDifference)}. Server time is ${ntpTime}.`);
        }
    })
        .catch((err) => {
        logger.info(`[NTP] Error while fetching NTP time ${err}`);
    });
};
export function getRoleNameForTelemetry(roleName) {
    return roleName === SYSTEM_ROLES.AGENT_AND_SUPERVISOR ? formRoleNameForTelemetry(roleName) : roleName;
}
export function handleTelemetryOnPresenceChange() {
    var _a, _b, _c, _d;
    let CollaborationTool = "";
    if (((_a = STORE.app.microsoftOrgConfig) === null || _a === void 0 ? void 0 : _a.active) && STORE.app.microsoftOrgConfig.stateSynchronization) {
        CollaborationTool = COLLAB_TOOL.TEAMS;
    }
    else if ((_b = STORE.app.webexOrgConfig) === null || _b === void 0 ? void 0 : _b.stateSynchronization) {
        CollaborationTool = COLLAB_TOOL.WEBEX;
    }
    (_c = SERVICE.telemetry) === null || _c === void 0 ? void 0 : _c.track(SERVICE.telemetry.MIX_EVENT.STATE_CHANGE_ON_PRESENCE_SYNC, {
        UserRole: getRoleNameForTelemetry(STORE.agent.userSelectedRole || ((_d = STORE.agent.userRole) === null || _d === void 0 ? void 0 : _d.join("_")[0])),
        CollaborationTool
    });
}
export function updateOutdialAniList(e) {
    var _a;
    if ((_a = e.detail) === null || _a === void 0 ? void 0 : _a.data) {
        STORE.agent.updateoutdialAniList(e.detail.data);
    }
}
export const getQuerySelectorTillRouterView = () => {
    const agentxApp = document.querySelector("agentx-app");
    if (agentxApp && agentxApp.shadowRoot) {
        const mdTheme = agentxApp.shadowRoot.querySelector("md-theme");
        if (mdTheme) {
            const routerView = mdTheme.querySelector("router-view");
            if (routerView && routerView.shadowRoot) {
                return routerView.shadowRoot;
            }
        }
    }
    return null;
};
/**
 * Toggles the display of a specified element based on the `isMaximized` parameter.
 *
 * @param {boolean} isMaximized - If true, the element's display style is set to "block", otherwise it's set to "none".
 * @param {string} selector - The CSS selector of the element to toggle.
 * @param {boolean} [isAdditonalShadowRoot=false] - If true, the function will look for the element inside the shadow root of a "uuip-dynamic-widget" inside "#panel-two".
 * @param {boolean} [isMdTab=false] - If true and `isAdditonalShadowRoot`
 * is also true, the function will look for the element inside the shadow root of "md-tabs" inside the shadow root of a "uuip-dynamic-widget" inside "#panel-two".
 * @returns {boolean} - Returns true if the element was found and its display was toggled, otherwise returns false.
 */
export const toggleDisplayOnMaximize = (isMaximized, selector, isAdditonalShadowRoot = false, isMdTab = false) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const routerView = getQuerySelectorTillRouterView();
    if (routerView) {
        let element;
        if (isAdditonalShadowRoot) {
            if (isMdTab) {
                element = (_e = (_d = (_c = (_b = (_a = routerView
                    .querySelector("#panel-two")) === null || _a === void 0 ? void 0 : _a.querySelector("uuip-dynamic-widget")) === null || _b === void 0 ? void 0 : _b.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector("md-tabs")) === null || _d === void 0 ? void 0 : _d.shadowRoot) === null || _e === void 0 ? void 0 : _e.querySelector(`#${selector}`);
            }
            else {
                element = (_h = (_g = (_f = routerView
                    .querySelector("#panel-two")) === null || _f === void 0 ? void 0 : _f.querySelector("uuip-dynamic-widget")) === null || _g === void 0 ? void 0 : _g.shadowRoot) === null || _h === void 0 ? void 0 : _h.querySelector(`#${selector}`);
            }
        }
        else {
            element = routerView.querySelector(`#${selector}`);
        }
        if (element) {
            if (!isMaximized) {
                element.style.display = "none";
            }
            else {
                element.style.display = "block";
            }
            return true;
        }
    }
    return false;
};
export const isEmailOrChat = (store) => {
    return (store === null || store === void 0 ? void 0 : store.mediaType) === MediaType.Email || (store === null || store === void 0 ? void 0 : store.mediaType) === MediaType.Chat;
};
