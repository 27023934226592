import { STORE } from "@/store";
import { Router } from "@uuip/unified-ui-platform";
import { css } from "lit-element";
import { html } from "lit-html";
import style from "./NavBlock.scss";
const NavBlock = () => {
    return new Router.RouterView.Block({
        template: html `
      <!-- NAV -->
      <agentx-wc-navigation
        @item-selected=${(e) => {
            // Updating Store route name
            STORE.routing.navigateNav(e.detail.navigateTo);
        }}
        logo=${STORE.app.logo}
        navtitle="${STORE.app.title}"
        .selectedNavigateTo="${STORE.routing.forNavRouteName}"
        .items="${STORE.layout.navigation}"
        .popoverCount="${STORE.agentContact.newPopoverCount}"
        .agentDetails=${STORE.agent.getAgentData}
        .darkTheme="${STORE.app.darkMode}"
        .userRole="${STORE.agent.userRole}"
        ?momentumStyle=${STORE.app.featureFlags.isMomentumV2Enabled}
        @navbar-toggled=${(e) => {
            STORE.common.updateIsNavBarOpen(e.detail.showNavList);
        }}
      ></agentx-wc-navigation>
    `,
        styles: [
            style,
            css `
        agentx-wc-navigation {
          grid-area: nav; /*grid*/
        }
      `
        ]
    });
};
export default NavBlock;
