var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import { t } from "@/mixins/i18nMixin";
import { createLogger } from "@/sdk";
import { STORE } from "@/store";
import "@agentx/agentx-mfe-wc-based";
import { SERVICE } from "@agentx/agentx-services";
import "@uuip/unified-ui-platform";
import "@uuip/uuip-advance-layout";
import { customElement, html, internalProperty, LitElement, property, query } from "lit-element";
import { nothing } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { reaction } from "mobx";
import { setTimeout } from "timers";
import { attachDynamicLayoutListeners, removeDynamicLayoutListeners } from "../utils/DynamicLayoutListener";
import { UTILS_DYNAMIC } from "../utils/UtilsDynamic";
import style from "./DynamicArea.scss";
import "./MoreActionsWrapper";
import "./PageTitle";
import "./TimezoneLabel";
const logger = createLogger("DynamicArea");
const MIN_CONTAINER_HEIGHT_FOR_ADVANCE_LAYOUT = 100;
const SUCCESS_FLASH_TIMEOUT = 1000;
const HEADER_HEIGHT = 62;
const SAVE_LAYOUT_EVENT = "uuip-layout-save-edit";
const RESET_LAYOUT_EVENT = "uuip-layout-reset-edit";
const CANCEL_EDIT_EVENT = "uuip-layout-cancel-edit";
let DynamicArea = class DynamicArea extends LitElement {
    constructor() {
        super(...arguments);
        this.height = "100%";
        this.isStationLoginSuccess = false;
        this.isEdit = false;
        this.isSuccess = false;
        this.containerHeight = 0;
        this.containerWidth = 0;
        this.resizeObserver = null;
        this.visibilityCheckDebug = (tag, upd) => {
            if (tag === "VISIBILITY_TEST") {
                return {
                    visible: STORE.dynamic.testBool,
                    dispose: reaction(() => STORE.dynamic.testBool, (x) => upd(x))
                };
            }
            return { visible: true };
        };
    }
    save() {
        var _a, _b, _c;
        const isAdvancedLayout = (_a = this.area) === null || _a === void 0 ? void 0 : _a.useFlexLayout;
        if (isAdvancedLayout) {
            const customSaveEvent = new CustomEvent(SAVE_LAYOUT_EVENT);
            (_c = (_b = this.adw) === null || _b === void 0 ? void 0 : _b.shadowRoot) === null || _c === void 0 ? void 0 : _c.childNodes[0].dispatchEvent(customSaveEvent);
        }
        else {
            this.dw.save();
        }
        this.isEdit = false;
        this.showSuccessIcon();
        SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.LAYOUT_SETTINGS_SAVE);
    }
    reset() {
        var _a, _b, _c;
        const isAdvancedLayout = (_a = this.area) === null || _a === void 0 ? void 0 : _a.useFlexLayout;
        if (isAdvancedLayout) {
            const customResetEvent = new CustomEvent(RESET_LAYOUT_EVENT);
            (_c = (_b = this.adw) === null || _b === void 0 ? void 0 : _b.shadowRoot) === null || _c === void 0 ? void 0 : _c.childNodes[0].dispatchEvent(customResetEvent);
        }
        else {
            this.dw.reset();
        }
        this.isEdit = false;
        this.showSuccessIcon();
        STORE.session.errorModal.updateModalStatus(false);
        SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.LAYOUT_SETTINGS_RESET);
    }
    cancel() {
        var _a, _b, _c;
        const isAdvancedLayout = (_a = this.area) === null || _a === void 0 ? void 0 : _a.useFlexLayout;
        if (isAdvancedLayout) {
            const customCancelEvent = new CustomEvent(CANCEL_EDIT_EVENT);
            (_c = (_b = this.adw) === null || _b === void 0 ? void 0 : _b.shadowRoot) === null || _c === void 0 ? void 0 : _c.childNodes[0].dispatchEvent(customCancelEvent);
        }
        else {
            this.dw.cancel();
        }
        this.isEdit = false;
    }
    edit() {
        this.isEdit = true;
        SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.LAYOUT_SETTINGS_EDIT);
    }
    showSuccessIcon() {
        this.isSuccess = true;
        setTimeout(() => {
            var _a, _b;
            this.isSuccess = false;
            (_b = (_a = this.editLayoutTrigger.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector("button")) === null || _b === void 0 ? void 0 : _b.focus();
        }, SUCCESS_FLASH_TIMEOUT);
    }
    showResetConfirmation() {
        STORE.session.errorModal.setErrorDetails({
            detail: {
                modalType: "dialog",
                header: t("app:desktopLayout:resetConfirmHeader"),
                message: t("app:desktopLayout:resetConfirmMessage"),
                buttons: [
                    {
                        type: "button",
                        label: t("app:common:cancelButton"),
                        color: "var(--md-background-color--gray-05)",
                        onClick: () => {
                            STORE.session.errorModal.updateModalStatus(false);
                        }
                    },
                    {
                        type: "button",
                        label: t("app:common:okButton"),
                        onClick: () => {
                            STORE.session.errorModal.updateModalStatus(false);
                            this.reset();
                        }
                    }
                ]
            }
        });
        STORE.session.errorModal.updateModalStatus(true);
    }
    static get styles() {
        return style;
    }
    get editMenuContent() {
        return html `
      <md-tooltip message="${t("app:desktopLayout:saveLayout")}">
        <md-button ariaLabel="${t("app:desktopLayout:saveLayout")}" circle variant="white" @button-click=${this.save}>
          <md-icon name="check-bold" iconSet="momentumDesign" size="16"></md-icon>
        </md-button>
      </md-tooltip>
      <md-tooltip message="${t("app:desktopLayout:resetLayout")}">
        <md-button
          ariaLabel="${t("app:desktopLayout:resetLayout")}"
          circle
          variant="white"
          @button-click=${this.showResetConfirmation}
        >
          <md-icon name="reset-bold" iconSet="momentumDesign" size="16"></md-icon>
        </md-button>
      </md-tooltip>
    `;
    }
    get editFloatingMenu() {
        return html `
      <div
        class="${classMap({
            "layout-edit": true,
            "layout-edit--editing": this.isEdit
        })}"
      >
        <md-tooltip
          slot="menu-trigger"
          placement="left"
          message="${this.isEdit ? t("app:desktopLayout:cancelEdit") : t("app:desktopLayout:editLayoutTooltip")}"
        >
          <md-button
            @click=${!this.isEdit ? this.edit : this.cancel}
            circle
            ariaLabel=${this.isEdit ? t("app:desktopLayout:cancelEdit") : t("app:desktopLayout:editLayoutTooltip")}
            variant=${this.isSuccess ? "green" : "inverted-white"}
            class="layout-edit--trigger"
          >
            ${this.isSuccess
            ? html ` <md-icon name="check-bold" iconSet="momentumDesign" size="16"></md-icon> `
            : !this.isEdit
                ? html ` <md-icon name="settings-bold" iconSet="momentumDesign" size="16"></md-icon> `
                : html ` <md-icon name="cancel-bold" iconSet="momentumDesign" size="16"></md-icon> `}
          </md-button>
        </md-tooltip>
        ${this.isEdit ? html ` <div class="layout-edit--flyout">${this.editMenuContent}</div> ` : nothing}
      </div>
    `;
    }
    render() {
        return html `
      <div
        class="ual-container ${classMap({
            "layout--not-editing": !this.isEdit,
            "layout--editing": this.isEdit && !this.area.useFlexLayout,
            "layout--editing-advance": this.isEdit && this.area.useFlexLayout
        })}"
      >
        <span class="layout--editing-state">Edit Mode</span>
        <!-- TODO: fix for height needs to come from CAX-567 -->
        ${this.area && this.area.pageHeader
            ? html `
              <div class="page-title-container">
                <uuip-dynamic-widget .options=${this.area.pageHeader}></uuip-dynamic-widget>
              </div>
            `
            : nothing}
        ${this.area && this.area.useFlexLayout
            ? html `
              <uuip-advance-layouts
                id="${Date.now()}"
                .page=${this.area}
                maximizeAreaName="${`app-maximize-area`}"
                ?isEditable=${this.isEdit}
                .dataProvider=${UTILS_DYNAMIC.storeDataProvider}
                .visibilityCheck=${this.visibilityCheckDebug}
                maximizeAriaLabel=${t("app:maximizeAreaBlock.maximize")}
                minimizeAriaLabel=${t("app:maximizeAreaBlock.minimize")}
                @widgetError=${(e) => logger.error("event=dynamicWidgetsRender | Error while rendering uuip-dynamic-widgets: ", e.detail)}
                @widgetStatus=${(e) => logger.info("uuip-dynamic-widgets widget status", e.detail)}
              >
              </uuip-advance-layouts>
            `
            : html `
              <uuip-dynamic-widgets
                .page=${this.area}
                width="100%"
                height=${this.height}
                ?edit=${this.isEdit}
                .dataProvider=${UTILS_DYNAMIC.storeDataProvider}
                .visibilityCheck=${this.visibilityCheckDebug}
                containerHeight=${this.containerHeight}
                @error=${(e) => logger.error("event=dynamicWidgetsRender | Error while rendering uuip-dynamic-widgets: ", e.detail)}
                @widgetStatus=${(e) => logger.info("uuip-dynamic-widgets widget status", e.detail)}
              >
                <!-- For debug of "config_sample.json" - page-dynamic-widgets-debug  -->
                <div slot="SLOT_ONE_TEST">SLOT ONE WORKS</div>
                <div slot="SLOT_TWO_TEST">SLOT TWO WORKS</div>
                <!-- For debug of "config_sample.json" - END DEBUG -->
              </uuip-dynamic-widgets>
            `}
      </div>
      ${STORE.dynamic.isDragDrop ? this.editFloatingMenu : nothing}
    `;
    }
    // For debug of "config_sample.json" - page-dynamic-widgets-debug
    // --------------------------------------------------------------
    firstUpdated(props) {
        super.firstUpdated(props);
        if (window.ResizeObserver) {
            this.resizeObserver = new window.ResizeObserver((entries) => {
                for (const entry of entries) {
                    if (entry.contentBoxSize) {
                        this.containerWidth = Math.floor(entry.contentRect.width);
                        if (this.area && this.area.pageHeader) {
                            this.containerHeight = Math.max(Math.floor(entry.contentRect.height) - HEADER_HEIGHT, MIN_CONTAINER_HEIGHT_FOR_ADVANCE_LAYOUT);
                        }
                        else {
                            this.containerHeight = Math.max(Math.floor(entry.contentRect.height), MIN_CONTAINER_HEIGHT_FOR_ADVANCE_LAYOUT);
                        }
                    }
                }
            });
            this.resizeObserver.observe(this.layoutContainer);
        }
    }
    updated(props) {
        super.updated(props);
        const cs = getComputedStyle(this.layoutContainer);
        if (this.area && this.area.pageHeader) {
            this.containerHeight = Math.max(this.layoutContainer.offsetHeight + parseFloat(cs.marginTop) + parseFloat(cs.marginBottom) - HEADER_HEIGHT, MIN_CONTAINER_HEIGHT_FOR_ADVANCE_LAYOUT);
        }
        else {
            this.containerHeight = Math.max(this.layoutContainer.offsetHeight + parseFloat(cs.marginTop) + parseFloat(cs.marginBottom), MIN_CONTAINER_HEIGHT_FOR_ADVANCE_LAYOUT);
        }
        if (props.has("area")) {
            this.requestUpdate();
        }
    }
    connectedCallback() {
        const _super = Object.create(null, {
            connectedCallback: { get: () => super.connectedCallback }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.connectedCallback.call(this);
            // wait for dw
            yield new Promise((r) => setTimeout(r, 1000));
            this.dw && this.dw.addEventListener("UUIPCompEvt.click", this.handleEventFromDynamicWidget);
            attachDynamicLayoutListeners();
        });
    }
    disconnectedCallback() {
        const _super = Object.create(null, {
            disconnectedCallback: { get: () => super.disconnectedCallback }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.disconnectedCallback.call(this);
            this.dw && this.dw.removeEventListener("UUIPCompEvt.click", this.handleEventFromDynamicWidget);
            this.resizeObserver && this.resizeObserver.unobserve(this.layoutContainer);
            removeDynamicLayoutListeners();
        });
    }
    handleEventFromDynamicWidget() {
        STORE.dynamic.updTest();
    }
};
__decorate([
    property({ attribute: false })
], DynamicArea.prototype, "area", void 0);
__decorate([
    property({ type: String })
], DynamicArea.prototype, "height", void 0);
__decorate([
    property({ type: Boolean })
], DynamicArea.prototype, "isStationLoginSuccess", void 0);
__decorate([
    internalProperty()
], DynamicArea.prototype, "isEdit", void 0);
__decorate([
    internalProperty()
], DynamicArea.prototype, "isSuccess", void 0);
__decorate([
    internalProperty()
], DynamicArea.prototype, "containerHeight", void 0);
__decorate([
    internalProperty()
], DynamicArea.prototype, "containerWidth", void 0);
__decorate([
    query("uuip-dynamic-widgets")
], DynamicArea.prototype, "dw", void 0);
__decorate([
    query(".layout-edit--trigger")
], DynamicArea.prototype, "editLayoutTrigger", void 0);
__decorate([
    query(".ual-container")
], DynamicArea.prototype, "layoutContainer", void 0);
__decorate([
    query("uuip-advance-layouts")
], DynamicArea.prototype, "adw", void 0);
DynamicArea = __decorate([
    customElement("dynamic-area")
], DynamicArea);
export { DynamicArea };
